import { Link, Outlet } from "react-router-dom";
import logo from '../assets/logo.png';
import portada from '../assets/portada.png';
import { ToastContainer } from "react-toastify";

const AutenticacionPage = () => {

    return ( 
        <div className="auth-container">
            <div className="auth-form-container img-background" style={{ backgroundImage:  `url(${portada})`}}>
            <div className="overlay"></div> 
            <Link to="/" className="brand-link auth-title d-flex align-items-center">
            <img
              alt="Logo CAT"
              src={logo}
              width="80"
              height="70"
              className="d-inline-block align-top"
            />
            <span className="brand-text">Sistema CAT</span>
          </Link>   
          <Outlet/>
           <ToastContainer />
        </div>
        </div>
       )
}
export default AutenticacionPage;