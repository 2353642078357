import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Table } from 'react-bootstrap';
import Swal from 'sweetalert2';
import { fetchAfiliados } from '../../reducers/afiliado_slice';
import { fetchValoradoDets } from '../../reducers/valoradoDet_slice';
import { addValoradoVen } from '../../reducers/valoradoVen_slice';
import { formatCurrency, numeroALetras } from '../../util/valueCalculator';
import { fetchRecibos } from '../../reducers/recibo_slice';
import Button from 'react-bootstrap/Button';
import moment from 'moment-timezone';
import ImprimirRecibo from '../recibo/ImprimirRecibo';

const ValoradoVenForm = ({ handleClose }) => {
    const dispatch = useDispatch();
    const valoradoDets = useSelector(state => state.valoradoDet.valoradoDets.filter(v => v.estado === 'VIGENTE') || []);
    const afiliados = useSelector(state => state.afiliado.afiliados || []);
    const valorados = useSelector(state => state.valorado.valorados || []);

    const [afiliadoId, setAfiliadoId] = useState('');
    const [afiliadoInfo, setAfiliadoInfo] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredAfiliados, setFilteredAfiliados] = useState([]);
    const [showDropdown, setShowDropdown] = useState(false);
    const [fecha] = useState(moment().tz("America/La_Paz").format('YYYY-MM-DD'));
    const [detalles, setDetalles] = useState([{ id_vd: '', cantidad: 1, pu: 0, monto: 0, descuento: 0, montopagado: 0, inicial: 0, final_ven: 0, observacion: '', codigo_cuenta: '' }]);
    const recibos = useSelector(state => state.recibo.recibos || []);

    const [isVentaRegistrada, setIsVentaRegistrada] = useState(false);

    const xcaja = 1;
    const xidusuario = 1;
    const xcuentacaja = "1111";

    useEffect(() => {
        dispatch(fetchValoradoDets());
        dispatch(fetchAfiliados());
        dispatch(fetchRecibos());
    }, [dispatch]);

    // Función para verificar si un valorado ya está seleccionado en otra fila
    const verificarValoradoDuplicado = (id_vd, currentIndex) => {
        return detalles.some((detalle, index) => detalle.id_vd === id_vd && index !== currentIndex);
    };

    const handleSearchChange = (e) => {
        const term = e.target.value;
        setSearchTerm(term);
        if (term.length > 0) {
            const filtered = afiliados.filter(afiliado => {
                const { persona } = afiliado;
                const fullName = `${persona.ci} ${persona.apellido1} ${persona.apellido2} ${persona.nombre}`.toLowerCase();
                return fullName.includes(term.toLowerCase());
            });
            setFilteredAfiliados(filtered);
            setShowDropdown(true);
        } else {
            setFilteredAfiliados([]);
            setShowDropdown(false);
        }
    };

    const handleAfiliadoSelect = (afiliado) => {
        const { persona } = afiliado;
        setAfiliadoId(afiliado.id);
        setAfiliadoInfo(afiliado);
        setShowDropdown(false);
        setSearchTerm(`${persona.apellido1} ${persona.apellido2} ${persona.nombre}`);
    };

    const handleDetailChange = (index, field, value) => {
        const updatedDetails = [...detalles];

        if (field === 'cantidad') {
            value = Math.max(1, value); // No permitir cantidades menores a 1
        }

        // Verificar si el valorado ya ha sido seleccionado en otra fila
        if (field === 'id_vd' && verificarValoradoDuplicado(value, index)) {
            Swal.fire('Error', 'Este valorado ya ha sido seleccionado.', 'error');
            return;
        }

        updatedDetails[index][field] = value;

        if (field === 'id_vd' || field === 'cantidad' || field === 'descuento') {
            const valoradoDet = valoradoDets.find(v => v.id === parseInt(updatedDetails[index].id_vd));
            const valorado = valorados.find(v => v.id === valoradoDet.id_valorado);
            if (valoradoDet) {
                const inicial = valoradoDet.ultimo_ven > 0 ? valoradoDet.ultimo_ven + 1 : valoradoDet.primero;
                const stockDisponible = valoradoDet.ultimo - valoradoDet.ultimo_ven; // Calcular el stock
                const cantidad = Math.min(updatedDetails[index].cantidad, stockDisponible); // Limitar cantidad al stock disponible

                updatedDetails[index].cantidad = cantidad;
                updatedDetails[index].pu = valoradoDet.precioventa;
                updatedDetails[index].inicial = inicial;
                updatedDetails[index].final_ven = inicial + cantidad - 1; // Actualizar final basado en la cantidad
                updatedDetails[index].nombreValorado = valorado ? valorado.descripcion : 'Nombre no disponible';
                updatedDetails[index].monto = cantidad * updatedDetails[index].pu; // Calcular monto basado en la cantidad
                updatedDetails[index].codigo_cuenta = valoradoDet.codigo_cuenta;

                // Calcular monto pagado = (cantidad * pu) - descuento
                updatedDetails[index].montopagado = updatedDetails[index].monto - updatedDetails[index].descuento;
            }
        }

        setDetalles(updatedDetails);
    };

    const generarNroRecibo = () => {
        const year = moment().tz("America/La_Paz").format('YYYY');
        const prefix = `I${year}`;
        const lastRecibo = recibos
            .filter(rec => rec.nroRecibo.startsWith(prefix))
            .sort((a, b) => parseInt(b.nroRecibo.slice(6)) - parseInt(a.nroRecibo.slice(6)))[0];

        const nextNumero = lastRecibo ? parseInt(lastRecibo.nroRecibo.slice(6)) + 1 : 1;
        return `${prefix}${nextNumero.toString().padStart(5, '0')}`;
    };

    const addDetail = () => {
        const lastDetail = detalles[detalles.length - 1];
        if (!lastDetail.id_vd) {
            Swal.fire('Error', 'Debe seleccionar un valorado en el último detalle antes de agregar uno nuevo.', 'error');
            return;
        }
        if (lastDetail.id_vd && lastDetail.cantidad > 0 && lastDetail.monto > 0) {
            setDetalles([...detalles, { id_vd: '', cantidad: 1, pu: 0, monto: 0, descuento: 0, montopagado: 0, inicial: 0, final_ven: 0, observacion: '' }]);
        } else {
            Swal.fire('Error', 'Debe completar todos los campos antes de agregar un nuevo detalle.', 'error');
        }
    };

    const removeDetail = (index) => {
        if (detalles.length === 1) return;
        const updatedDetails = detalles.filter((_, i) => i !== index);
        setDetalles(updatedDetails);
    };

    const handleSubmit = async () => {
        if (!afiliadoInfo) {
            Swal.fire('Error', 'Debe seleccionar a un afiliado...', 'error');
            return;
        }
        for (const detalle of detalles) {
            if (!detalle.id_vd) {
                Swal.fire('Error', 'Debe seleccionar un valorado en todos los detalles.', 'error');
                return;
            }
            if (detalle.descuento > 0 && !detalle.observacion) {
                Swal.fire('Error', 'Si hay un descuento, debe agregar una observación.', 'error');
                return;
            }
        }

        const nroRecibo = generarNroRecibo();

        const recibo = {
            nroRecibo,
            tipo: 'INGRESO',
            fecha,
            monto: detalles.reduce((sum, d) => sum + d.montopagado, 0),
            beneficiario: `${afiliadoInfo.persona.apellido1} ${afiliadoInfo.persona.apellido2}, ${afiliadoInfo.persona.nombre} Nro. Reg.: ${afiliadoInfo.num_reg}`,
            ci_persona: afiliadoInfo.persona.ci,
            concepto: 'VENTA DE VALORADOS',
            id_caja: xcaja,
            id_usuario: xidusuario
        };

        const reciboDetalles = detalles.map((detalle) => ({
            id_caja: xcaja,
            nroRecibo,
            tipo: 'INGRESO',
            fecha,
            pu: detalle.pu,
            concepto: `${detalle.nombreValorado} del ${detalle.inicial} al ${detalle.final_ven} ${detalle.observacion} `,
            cantidad: detalle.cantidad,
            monto: detalle.monto,
            pagado: detalle.montopagado,
            descuento: detalle.descuento,
            codigo_cuentaC: xcuentacaja,
            codigo_cuentaM: detalle.codigo_cuenta
        }));

        const valoradoVenDetalles = detalles.map((detalle) => ({
            id_vd: detalle.id_vd,
            cantidad: detalle.cantidad,
            pu: detalle.pu,
            monto: detalle.monto,
            descuento: detalle.descuento,
            montopagado: detalle.montopagado,
            inicial: detalle.inicial,
            final_ven: detalle.final_ven,
            observacion: detalle.observacion,
            id_afiliado: afiliadoId,
            fecha
        }));

        Swal.fire({
            title: '¿Estás seguro?',
            text: 'Este proceso es irreversible, ¿deseas continuar?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Sí, continuar',
            cancelButtonText: 'No, cancelar',
            reverseButtons: true
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    await dispatch(addValoradoVen({ recibo, reciboDetalles, valoradoVenDetalles })).unwrap();
                    Swal.fire('Éxito', 'Venta registrada correctamente', 'success');

                    const totalLiteral = numeroALetras(recibo.monto);
                    const reciboData = {
                        recibo,
                        reciboDetalles,
                        totalLiteral
                    };
                    handleClose(reciboData);
                } catch (error) {
                    Swal.fire('Error', 'No se pudo registrar la venta', 'error');
                }
            }
        });
    };

    return (
        <div style={{ width: '100%', margin: '0 auto' }}>
            <Form.Group>
                <Form.Label>Buscar Afiliado</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Buscar por C.I., registro o nombre"
                    value={searchTerm}
                    onChange={handleSearchChange}
                />
                {showDropdown && (
                    <div className="dropdown-menu show" style={{ width: '100%', maxHeight: '200px', overflowY: 'auto' }}>
                        {filteredAfiliados.map(afiliado => {
                            const persona = afiliado.persona;
                            return (
                                <div
                                    key={afiliado.id}
                                    className="dropdown-item"
                                    onClick={() => handleAfiliadoSelect(afiliado)}
                                >
                                    {`${persona.apellido1} ${persona.apellido2}, ${persona.nombre} - CI: ${persona.ci}`}
                                </div>
                            );
                        })}
                    </div>
                )}
            </Form.Group>

            {afiliadoInfo && (
                <div>
                    <p><strong>C.I.:</strong> {afiliadoInfo.persona.ci}</p>
                    <p><strong>Número de Registro:</strong> {afiliadoInfo.num_reg}</p>
                </div>
            )}

            <Form.Group className="d-flex align-items-center">
                <Form.Label className="mr-2" style={{ fontWeight: 'bold' }}>Fecha:</Form.Label>
                <Form.Control type="date" value={fecha} readOnly style={{ width: '20%' }} />
            </Form.Group>

            <h5>Detalle de la Venta</h5>
            <Table bordered style={{ width: '100%' }}>
                <thead>
                    <tr>
                        <th style={{ width: '14%' }}>Valorado</th>
                        <th style={{ width: '5%' }}>Cantidad</th>
                        <th style={{ width: '8%' }}>P.U.</th>
                        <th style={{ width: '10%' }}>Monto a Cobrar</th>
                        <th style={{ width: '10%' }}>Descuento</th>
                        <th style={{ width: '10%' }}>Monto a Pagar</th>
                        <th style={{ width: '7%' }}>Inicial</th>
                        <th style={{ width: '7%' }}>Final</th>
                        <th style={{ width: '21%' }}>Observación</th>
                        <th style={{ width: '8%' }}>Acciones</th>
                    </tr>
                </thead>
                <tbody>
                    {detalles.map((detalle, index) => (
                        <tr key={index}>
                            <td>
                                <Form.Control
                                    as="select"
                                    value={detalle.id_vd}
                                    disabled={index !== detalles.length - 1 || isVentaRegistrada}
                                    onChange={(e) => handleDetailChange(index, 'id_vd', e.target.value)}
                                >
                                    <option value="">Seleccione un Valorado</option>
                                    {valoradoDets.map((valoradoDet) => {
                                        const valorado = valorados.find(v => v.id === valoradoDet.id_valorado);
                                        const isDisabled = verificarValoradoDuplicado(valoradoDet.id, index); // Verificar si ya ha sido seleccionado
                                        return (
                                            <option key={valoradoDet.id} value={valoradoDet.id} disabled={isDisabled}>
                                                {valorado ? `${valorado.descripcion}` : 'Nombre no disponible'}
                                            </option>
                                        );
                                    })}
                                </Form.Control>
                            </td>
                            <td>
                                <Form.Control
                                    type="number"
                                    value={detalle.cantidad}
                                    min="1"
                                    disabled={isVentaRegistrada}
                                    onChange={(e) => handleDetailChange(index, 'cantidad', e.target.value)}
                                />
                            </td>
                            <td>
                                <Form.Control
                                    type="text"
                                    value={detalle.pu}
                                    readOnly
                                />
                            </td>
                            <td>
                                <Form.Control
                                    type="text"
                                    value={detalle.monto}
                                    readOnly
                                />
                            </td>
                            <td>
                                <Form.Control
                                    type="number"
                                    value={detalle.descuento}
                                    min="0"
                                    disabled={isVentaRegistrada}
                                    onChange={(e) => handleDetailChange(index, 'descuento', e.target.value)}
                                />
                            </td>
                            <td>
                                <Form.Control
                                    type="text"
                                    value={detalle.montopagado}
                                    readOnly
                                />
                            </td>
                            <td>
                                <Form.Control
                                    type="text"
                                    value={detalle.inicial}
                                    readOnly
                                />
                            </td>
                            <td>
                                <Form.Control
                                    type="text"
                                    value={detalle.final_ven}
                                    readOnly
                                />
                            </td>
                            <td>
                                <Form.Control
                                    type="text"
                                    value={detalle.observacion}
                                    disabled={isVentaRegistrada}
                                    onChange={(e) => handleDetailChange(index, 'observacion', e.target.value)}
                                />
                            </td>
                            <td>
                                {detalles.length > 1 && !isVentaRegistrada && (
                                    <button className="icon-button" onClick={() => removeDetail(index)}>
                                        <i className="bi bi-trash"></i>
                                    </button>
                                )}
                                {index === detalles.length - 1 && !isVentaRegistrada && (
                                    <button className="icon-button" onClick={addDetail}>
                                        <i className="bi bi-plus-circle"></i>
                                    </button>
                                )}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>

            <div className="form-actions">
                {!isVentaRegistrada && (
                    <Button onClick={handleSubmit}>Registrar Venta</Button>
                )}
            </div>
        </div>
    );
};

export default ValoradoVenForm;
