import { sendLogin, sendRegister} from '../services/authService';
import { sesionActions } from '../reducers/session_slice';
import { toast } from 'react-toastify';

export const login = (user) => async dispatch => {
  
    try {
      const data = await sendLogin(user);
      const { token, message, sessionData } = data;
     const expiration = new Date();
        expiration.setHours(expiration.getHours() + 1);
        localStorage.setItem('token', token);
        localStorage.setItem('expiration', expiration.toISOString());
       
        dispatch(sesionActions.login(sessionData));
       
        return {status: 200, message:message};

    } catch (error) {
      
      if (error.message === "Network Error") {
        return {status: 500, message: "Error al conectar con el servidor"};
      } return {status: 400, message: error.response.data};
    }
  };
  
export const register = (user) => async dispatch => {
    try {
      const data = await sendRegister(user);
    
      return {status: 200, message:data.message};
    } catch (error) {
      if(error.response)
     { 
      
      return {status: error.response.status, messages:error.response.data.messages};}
      else if (error.message) {
       
        return {status: 500, message: "Error al conectar con el servidor"};
      }
    }
  };
  