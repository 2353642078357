import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import apiService from "../services/apiService";

// Crear una instancia del servicio para la entidad "zonas"
const zonaService = apiService('/zonas');

// Thunks para las operaciones CRUD
export const fetchZonas = createAsyncThunk('zona/fetchZonas', async () => {
  const response = await zonaService.getAll();
  return response || [];
});

export const fetchZonaById = createAsyncThunk('zona/fetchZonaById', async (id) => {
  const response = await zonaService.getById(id);
  return response;
});

export const addZona = createAsyncThunk('zona/addZona', async (zona, { rejectWithValue }) => {
  try {
    const response = await zonaService.create(zona);
    return response;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const modifyZona = createAsyncThunk('zona/modifyZona', async ({ id, zona }, { rejectWithValue }) => {
  try {
    const response = await zonaService.update(id, zona);
    return response;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const removeZona = createAsyncThunk('zona/removeZona', async (id, { rejectWithValue }) => {
  try {
    await zonaService.remove(id);
    return id;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

// Estado inicial del slice
const initialState = {
  zonas: [],
  current: null,
  status: 'idle',
  error: null,
};

// Definición del slice
const zonaSlice = createSlice({
  name: 'zona',
  initialState,
  reducers: {
    setCurrentZona(state, action) {
      state.current = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchZonas.fulfilled, (state, action) => {
        state.zonas = action.payload;
      })
      .addCase(fetchZonas.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchZonas.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(fetchZonaById.fulfilled, (state, action) => {
        state.current = action.payload;
      })
      .addCase(addZona.fulfilled, (state, action) => {
        state.zonas.push(action.payload);
      })
      .addCase(addZona.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(addZona.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload.error || action.error.message;
      })
      .addCase(modifyZona.fulfilled, (state, action) => {
        const index = state.zonas.findIndex(zona => zona.id === action.payload.id);
        if (index !== -1) {
          state.zonas[index] = action.payload;
        }
      })
      .addCase(modifyZona.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(modifyZona.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload.error || action.error.message;
      })
      .addCase(removeZona.fulfilled, (state, action) => {
        state.zonas = state.zonas.filter(zona => zona.id !== action.payload);
      })
      .addCase(removeZona.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(removeZona.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload.error || action.error.message;
      });
  }
});

export const { setCurrentZona } = zonaSlice.actions;

export default zonaSlice.reducer;
