import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addPropietario, modifyPropietario, fetchPropietarios } from '../../reducers/propietario_slice';
import { fetchInmuebles } from '../../reducers/inmueble_slice';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Swal from 'sweetalert2';
import { Alert, Col, Row } from 'react-bootstrap';
import ActionButton from '../UI/Buttons/ActionButton';
import ListInmuebles from './ListInmuebles';

const initialState = {
    id_inmueble:"",
    inmuebleSeleccionado:null

}

const SelectInmueble = ({onSelect, inmuebleSelected, labelText, excludeList=null,simpleInmueble=null}) => {
    const dispatch = useDispatch();
    const [datos, setDatos] = useState(initialState);

    useEffect(() => {
        dispatch(fetchInmuebles());
    }, [dispatch]);
    
    useEffect(() => {
        if(inmuebleSelected){
          setDatos({ ...datos, id_inmueble: inmuebleSelected.id, inmuebleSeleccionado: inmuebleSelected});
        }else{ setDatos(initialState)}
      }, [inmuebleSelected]);


      const handleSelectInmueble = (inmueble) => {
        const inmuebleId=inmueble? inmueble.id : ""
        setDatos({ ...datos, id_inmueble: inmuebleId, inmuebleSeleccionado:inmueble });
        onSelect('inmueble',inmueble)
    };
    return (
        <>
                    <Row xs={1} className="mt-1">
                       <><Col className='col-form'>
                            <Form.Label>{labelText}
                                </Form.Label>
                                {datos.inmuebleSeleccionado ? (
                            <div className="selected-container p-3 border rounded shadow-sm">
                            <div>
                                <strong>Cód. Catastral:  {`${datos.inmuebleSeleccionado.cod_catastral}`}</strong> <br />
                                <span>Zona: {datos.inmuebleSeleccionado.zona?.nombre}, {datos.inmuebleSeleccionado.zona.lugar.nombre}</span> 
                            </div>
                            <Button variant="link" onClick={() => handleSelectInmueble(null)}>
                                Cambiar inmueble
                            </Button>
                            </div>
                        ) : (
                            <>
                            <div className="alert alert-info p-2">
                            <p>No se ha seleccionado un inmueble aún.</p>
                            </div>
                            {/* Aquí puedes renderizar la tabla de personas */}
                            <ListInmuebles onSelectInmueble={handleSelectInmueble}/>
                    
                            </>
                        )}
                        </Col>
                        </>
                       
                    </Row>
        </>
    );
};

export default SelectInmueble;
