import React, { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchProyectos, removeProyecto } from '../../reducers/proyecto_slice';
import FormProyecto from './FormProyecto';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import CustomPrintModal from '../UI/CustomPrintModal';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import * as XLSX from 'xlsx';
import ActionButton from '../UI/Buttons/ActionButton';
import { formatNumber, fullNamePersona } from '../../util/customFormatter';
import CustomPrintModal2 from '../UI/CustomPrintModal2';
import CustomPrintModalH from '../UI/CustomPrintModalH';

const ListProyectos = () => {
  const dispatch = useDispatch();
  const proyectos = useSelector(state => state.proyecto.proyectos || [] );
  const [showModal, setShowModal] = useState(false);
  const [currentProyecto, setCurrentProyecto] = useState(null);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [printContent, setPrintContent] = useState({ head: [], body: [] });
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(fetchProyectos());
  }, [dispatch]);

  const handleDelete = id => {
    Swal.fire({
      title: '¿Estás seguro?',
      text: "¡No podrás revertir esto!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Sí, eliminarlo!'
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(removeProyecto(id)).unwrap().then(() => {
          Swal.fire('¡Eliminado!', 'El proyecto de construcción ha sido eliminado.', 'success');
        }).catch((error) => {
          Swal.fire('Error', error.error || 'Ocurrió un error al eliminar el proyecto de construcción', 'error');
        });
      }
    });
  };

  const handleSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    } else if (sortConfig.key === key && sortConfig.direction === 'desc') {
      direction = null;
    }
    setSortConfig({ key, direction });
  };

  const handleEdit = proyecto => {
    setCurrentProyecto(proyecto);
    setShowModal(true);
  };

  const handleCreate = () => {
    setCurrentProyecto(null);
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
    setCurrentProyecto(null);
  };

  const handleBack = () => {
    navigate('/admin');
  };

  const handleExport = () => {
    const worksheet = XLSX.utils.json_to_sheet(sortedProyectos);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Proyectos");
    XLSX.writeFile(workbook, "Proyectos.xlsx");
  };

  const sortedProyectos = useMemo(() => {
    let sortedData = [...proyectos];
    if (sortConfig.key) {
      sortedData.sort((a, b) => {
        let aValue, bValue;
        if (sortConfig.key === 'tipo.descripcion') {
          aValue = a.tipo?.descripcion || '';
          bValue = b.tipo?.descripcion || '';
        } else if (sortConfig.key === 'area.nombre') {
          aValue = a.area?.nombre || '';
          bValue = b.area?.nombre || '';
        } else {
          aValue = a[sortConfig.key];
          bValue = b[sortConfig.key];
        }
        
        if (aValue < bValue) {
          return sortConfig.direction === 'asc' ? -1 : 1;
        }
        if (aValue > bValue) {
          return sortConfig.direction === 'asc' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortedData;
  }, [proyectos, sortConfig]);

  const handleShowPreview = () => {
    setPrintContent(getPreviewContent());
    setShowPreviewModal(true);
  };

  const handleClosePreview = () => {
    setShowPreviewModal(false);
  };

  const getPreviewContent = () => {
    const content = {
      head: [['N°','Núm. Visado','Proyectista','Inmueble','Propietario','Descripción','Tipo de Proyecto','Superficie', 'Honorarios', 'Comisión C.A.T.', 'Estado del proyecto']],
      body: sortedProyectos.map((proyecto, index) => [
        index+1,
        proyecto.num_visado,
        fullNamePersona(proyecto.proyectista.persona),
        `${proyecto.inmueble.zona.nombre} ${proyecto.inmueble.cod_catastral}`,
        fullNamePersona(proyecto.propietario),
        proyecto.descripcion || 'N/A',
        proyecto.tipo.nombre,
        formatNumber(proyecto.superficie_total),
        formatNumber(proyecto.honorarios_total),
        formatNumber(proyecto.comision_cat),
        proyecto.estado.toUpperCase(),
      ])
    };
    return content;
  };

  return (
    <div className="list-container list1">
      <h1 className='table-title'>Lista de Proyectos</h1>
      <div className="list-content">
        <div className='list-controls'>
          <div className="sort-controls col-md-6">
           
          </div>
          <div className="col-md-6">
            <ActionButton onClick={handleCreate} outline icon='plus' floatEnd>
              Nuevo Proyecto de Construcción
            </ActionButton>
          </div>
        </div>
        <div className="list-container-header">
          <div className="left-buttons">
            <button title="Volver" onClick={handleBack} className="icon-button btn-transparent"><i className="bi bi-arrow-left"></i></button>
          </div>
          <div className="right-buttons">
            <button title="Imprimir" onClick={handleShowPreview} className="icon-button btn-transparent"><i className="bi bi-printer"></i></button>
            <button title="Exportar a excel" onDoubleClick={handleExport} className="icon-button btn-transparent"><i className="bi bi-file-spreadsheet"></i></button>
          </div>
        </div>
        {proyectos.length === 0 ? (
          <p>No hay proyectos de construcción disponibles.</p>
        ) : (
          <div className='table-container'>
            <Table bordered hover size='sm'>
              <thead className='text-center align-middle'>
                <tr>
                  <th className='col-index'>N°</th>
                  <th onClick={() => handleSort('tipo.descripcion')}>
                    Número Visado
                    {sortConfig.key === 'tipo.descripcion' && (
                        sortConfig.direction === 'asc' ? <i className="bi bi-arrow-up"></i> : <i className="bi bi-arrow-down"></i>
                    )}
                  </th>
                  <th onClick={() => handleSort('tipo.descripcion')}>
                    Proyectista
                    {sortConfig.key === 'tipo.descripcion' && (
                        sortConfig.direction === 'asc' ? <i className="bi bi-arrow-up"></i> : <i className="bi bi-arrow-down"></i>
                    )}
                  </th>
                  <th onClick={() => handleSort('tipo.descripcion')}>
                    Inmueble
                    {sortConfig.key === 'tipo.descripcion' && (
                        sortConfig.direction === 'asc' ? <i className="bi bi-arrow-up"></i> : <i className="bi bi-arrow-down"></i>
                    )}
                  </th>
                  <th onClick={() => handleSort('area.nombre')}>
                    Propietario
                    {sortConfig.key === 'area.nombre' && (
                        sortConfig.direction === 'asc' ? <i className="bi bi-arrow-up"></i> : <i className="bi bi-arrow-down"></i>
                    )}
                  </th>
                  <th onClick={() => handleSort('descripcion')}>
                    Descripción del proyecto
                    {sortConfig.key === 'descripcion' && (
                        sortConfig.direction === 'asc' ? <i className="bi bi-arrow-up"></i> : <i className="bi bi-arrow-down"></i>
                    )}
                  </th>
                  <th onClick={() => handleSort('area.nombre')}>
                    Tipo de proyecto
                    {sortConfig.key === 'area.nombre' && (
                        sortConfig.direction === 'asc' ? <i className="bi bi-arrow-up"></i> : <i className="bi bi-arrow-down"></i>
                    )}
                  </th>
                  <th onClick={() => handleSort('descripcion')}>
                    Superficie (m2)
                    {sortConfig.key === 'descripcion' && (
                        sortConfig.direction === 'asc' ? <i className="bi bi-arrow-up"></i> : <i className="bi bi-arrow-down"></i>
                    )}
                  </th>
                  <th onClick={() => handleSort('descripcion')}>
                    Honorarios (Bs)
                    {sortConfig.key === 'descripcion' && (
                        sortConfig.direction === 'asc' ? <i className="bi bi-arrow-up"></i> : <i className="bi bi-arrow-down"></i>
                    )}
                  </th>
                  <th onClick={() => handleSort('descripcion')}>
                    Comisión C.A.T. (Bs)
                    {sortConfig.key === 'descripcion' && (
                        sortConfig.direction === 'asc' ? <i className="bi bi-arrow-up"></i> : <i className="bi bi-arrow-down"></i>
                    )}
                  </th>
                  <th onClick={() => handleSort('descripcion')}>
                    Estado
                    {sortConfig.key === 'descripcion' && (
                        sortConfig.direction === 'asc' ? <i className="bi bi-arrow-up"></i> : <i className="bi bi-arrow-down"></i>
                    )}
                  </th>
                  <th className='col-simple-actions'>Acciones</th>
                </tr>
              </thead>
              <tbody>
                {sortedProyectos.map((proyecto, index) => (
                  <tr key={proyecto.id}>
                    <td>{index + 1}</td>
                    <td>{proyecto.num_visado || ""}</td>
                    <td>{fullNamePersona(proyecto.proyectista.persona)}</td>
                    <td>{`${proyecto.inmueble.zona.nombre} ${proyecto.inmueble.cod_catastral}`}</td>
                    <td>{fullNamePersona(proyecto.propietario)}</td>
                    <td>{proyecto.descripcion}</td>
                    <td>{proyecto.tipo.nombre}</td>
                    <td>{formatNumber(proyecto.superficie_total)}</td>
                    <td>{formatNumber(proyecto.honorarios_total)}</td>
                    <td>{formatNumber(proyecto.comision_cat)}</td>
                    <td>{proyecto.estado.toUpperCase()}</td>
                    <td>
                      <button className="icon-button btn-secondary-outline" title="Editar" onClick={() => handleEdit(proyecto)}>
                        <i className="bi bi-pencil"></i>
                      </button>
                      <button className="icon-button btn-secondary-outline" title="Eliminar" onClick={() => handleDelete(proyecto.id)}>
                        <i className="bi bi-trash"></i>
                       </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        )}
        <FormProyecto currentProyecto={currentProyecto} showModal={showModal} handleClose={handleClose} />
        <CustomPrintModalH
          show={showPreviewModal}
          handleClose={handleClosePreview}
          title1="LISTA DE PROYECTOS DE CONSTRUCCIÓN"
          title2=""
          content={printContent}
          copia={false}
        />
      </div>
    </div>
  );
};

export default ListProyectos;
