import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import apiService from "../services/apiService";

const valoradoService = apiService('/valorados');

export const fetchValorados = createAsyncThunk('valorado/fetchValorados', async () => {
    const response = await valoradoService.getAll();
    return response || [];
});

export const addValorado = createAsyncThunk('valorado/addValorado', async (valorado, { rejectWithValue }) => {
    try {
        const response = await valoradoService.create(valorado);
        return response;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});

export const modifyValorado = createAsyncThunk('valorado/modifyValorado', async ({ id, valorado }, { rejectWithValue }) => {
    try {
        const response = await valoradoService.update(id, valorado);
        return response;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});

export const removeValorado = createAsyncThunk('valorado/removeValorado', async (id, { rejectWithValue }) => {
    try {
        await valoradoService.remove(id);
        return id;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});

const initialState = {
    valorados: [],
    current: null,
    statusSend: 'idle',
    statusGet: 'idle',
    error: null,
};

const valoradoSlice = createSlice({
    name: 'valorado',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchValorados.fulfilled, (state, action) => {
                state.valorados = action.payload;
                state.statusGet = 'idle';
            })
            .addCase(addValorado.fulfilled, (state, action) => {
                state.valorados.push(action.payload);
                state.statusSend = 'idle';
            })
            .addCase(modifyValorado.fulfilled, (state, action) => {
                const index = state.valorados.findIndex(v => v.id === action.payload.id);
                if (index !== -1) {
                    state.valorados[index] = action.payload;
                }
                state.statusSend = 'idle';
            })
            .addCase(removeValorado.fulfilled, (state, action) => {
                state.valorados = state.valorados.filter(v => v.id !== action.payload);
                state.statusSend = 'idle';
            });
    }
});

export default valoradoSlice.reducer;
