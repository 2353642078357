import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import apiService from "../services/apiService";

// Crear una instancia del servicio para la entidad "situaciones"
const situacionService = apiService('/situaciones');

// Thunks para las operaciones CRUD
export const fetchSituaciones = createAsyncThunk('situacion/fetchSituaciones', async () => {
  const response = await situacionService.getAll();
  return response || [];
});

export const fetchSituacionById = createAsyncThunk('situacion/fetchSituacionById', async (id) => {
  const response = await situacionService.getById(id);
  return response;
});

export const addSituacion = createAsyncThunk('situacion/addSituacion', async (situacion, { rejectWithValue }) => {
  try {
    const response = await situacionService.create(situacion);
    return response;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const modifySituacion = createAsyncThunk('situacion/modifySituacion', async ({ id, situacion }, { rejectWithValue }) => {
  try {
    const response = await situacionService.update(id, situacion);
    return response;
  }  catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const removeSituacion = createAsyncThunk('situacion/removeSituacion', async (id, { rejectWithValue }) => {
  try {
    await situacionService.remove(id);
    return id;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

// Estado inicial del slice
const initialState = {
  situaciones: [],
  current: null,
  statusSend: 'idle',
  statusGet:'idle',
  error: {
    status: null,
    messages:[]
  },
};

// Definición del slice
const situacionSlice = createSlice({
  name: 'situacion',
  initialState,
  reducers: {
    setCurrentSituacion(state, action) {
      state.current = action.payload;
    },
    resetStatusSend(state) {
        state.statusSend = "idle";
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSituaciones.fulfilled, (state, action) => {
        state.situaciones = action.payload;
        state.statusGet = 'idle';
      })
      .addCase(fetchSituaciones.pending, (state) => {
        state.statusGet = 'loading';
      })
      .addCase(fetchSituaciones.rejected, (state, action) => {
        state.statusGet = 'failed';
        state.error = action.error.message;
      })
      .addCase(fetchSituacionById.fulfilled, (state, action) => {
        state.current = action.payload;
        state.statusGet = 'idle';
      })
      .addCase(addSituacion.fulfilled, (state, action) => {
        state.situaciones.push(action.payload);
        state.statusSend = 'idle';
      })
      .addCase(addSituacion.pending, (state) => {
        state.statusSend = 'sending';
      })
      .addCase(addSituacion.rejected, (state, action) => {
        state.statusSend = 'failed';
        state.error = action.payload || action.error.message;
      })
      .addCase(modifySituacion.fulfilled, (state, action) => {
        const index = state.situaciones.findIndex(situacion => situacion.id === action.payload.id);
        if (index !== -1) {
          state.situaciones[index] = action.payload;
        }
        state.statusSend = 'idle';
      })
      .addCase(modifySituacion.pending, (state, action) => {
        state.statusSend = 'sending';
      })
      .addCase(modifySituacion.rejected, (state, action) => {
        state.statusSend = 'failed';
        state.error = action.payload.error || action.error.message;
      })
      .addCase(removeSituacion.fulfilled, (state, action) => {
        state.situaciones = state.situaciones.filter(situacion => situacion.id !== action.payload);
        state.statusSend = 'idle';
      })
      .addCase(removeSituacion.pending, (state) => {
        state.statusSend = 'sending';
      })
      .addCase(removeSituacion.rejected, (state, action) => {
        state.statusSend = 'failed';
        state.error = action.payload || action.error.message;
      });
  }
});

export const { setCurrentSituacion, resetStatusSend } = situacionSlice.actions;

export default situacionSlice.reducer;
