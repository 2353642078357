import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Table } from 'react-bootstrap';
import Swal from 'sweetalert2';
import { fetchRecibos, addRecibo } from '../../reducers/recibo_slice';
import { fetchIngresosVarios } from '../../reducers/ingresosVarios_slice'; // Importamos los ingresos varios
import Button from 'react-bootstrap/Button';
import moment from 'moment-timezone';


const ReciboForm = ({ handleClose }) => {
    const dispatch = useDispatch();
    const ingresosVarios = useSelector(state => state.ingresosVarios.ingresosVarios || []);
    
    const [fecha] = useState(moment().tz("America/La_Paz").format('YYYY-MM-DD'));
    const [detalles, setDetalles] = useState([{ cantidad: 1, concepto: '', pu: 0, monto: 0, descuento: 0, pagado: 0 }]);
    const [recibo, setRecibo] = useState({ beneficiario: '', concepto: '', monto: 0 });
    const recibos = useSelector(state => state.recibo.recibos || []);
    const xcaja=1
    const xidusuario=1
    const xcuentaC='111'

    useEffect(() => {
        dispatch(fetchIngresosVarios());
    }, [dispatch]);

    // Calcular monto y pagado cuando cambian los valores de cantidad, PU o descuento
    const handleDetailChange = (index, field, value) => {
        const updatedDetails = [...detalles];
        updatedDetails[index][field] = value;

        if (field === 'cantidad' || field === 'pu' || field === 'descuento') {
            const cantidad = parseFloat(updatedDetails[index].cantidad) || 0;
            const pu = parseFloat(updatedDetails[index].pu) || 0;
            const descuento = parseFloat(updatedDetails[index].descuento) || 0;

            updatedDetails[index].monto = cantidad * pu;
            updatedDetails[index].pagado = updatedDetails[index].monto - descuento;
        }

        setDetalles(updatedDetails);
    };

    const generarNroRecibo = () => {
        const year = moment().tz("America/La_Paz").format('YYYY');
        const prefix = `I${year}`;
        const lastRecibo = recibos
            .filter(rec => rec.nroRecibo.startsWith(prefix))
            .sort((a, b) => parseInt(b.nroRecibo.slice(6)) - parseInt(a.nroRecibo.slice(6)))[0];

        const nextNumero = lastRecibo ? parseInt(lastRecibo.nroRecibo.slice(6)) + 1 : 1;
        return `${prefix}${nextNumero.toString().padStart(5, '0')}`;
    };

    const addDetail = () => {
        const lastDetail = detalles[detalles.length - 1];
        if (!lastDetail.concepto || lastDetail.pu <= 0 || lastDetail.cantidad <= 0) {
            Swal.fire('Error', 'Debe completar todos los campos antes de agregar un nuevo detalle.', 'error');
            return;
        }
        setDetalles([...detalles, { cantidad: 1, concepto: '', pu: 0, monto: 0, descuento: 0, pagado: 0,codigo_cuenta:'' }]);
    };

    const removeDetail = (index) => {
        if (detalles.length === 1) return;
        const updatedDetails = detalles.filter((_, i) => i !== index);
        setDetalles(updatedDetails);
    };

    const totalPagado = detalles.reduce((sum, detalle) => sum + detalle.pagado, 0);

    const handleSubmit = async () => {
        if (!recibo.beneficiario || !recibo.concepto) {
            Swal.fire('Error', 'Debe completar los datos del recibo.', 'error');
            return;
        }

        for (const detalle of detalles) {
            if (!detalle.concepto || detalle.pu <= 0 || detalle.cantidad <= 0) {
                Swal.fire('Error', 'Debe completar todos los detalles correctamente.', 'error');
                return;
            }
        }

        const nroRecibo = generarNroRecibo();

        const xrecibo = {
            nroRecibo,
            tipo: 'INGRESO',
            fecha,
            monto: detalles.reduce((sum, d) => sum + d.pagado, 0),
            beneficiario:recibo.beneficiario,
            ci_persona: '',
            concepto: recibo.concepto,
            id_caja: xcaja,
            id_usuario: xidusuario
        };

        const reciboDetalles = detalles.map((detalle) => ({
            id_caja: xcaja,
            nroRecibo,
            tipo: 'INGRESO',
            fecha,
            pu: detalle.pu,
            concepto: detalle.concepto,
            cantidad: detalle.cantidad,
            monto: detalle.monto,
            pagado: detalle.pagado,
            descuento: detalle.descuento,
            codigo_cuentaC: xcuentaC,
            codigo_cuentaM: detalle.codigo_cuenta

        }));
       try {
                // Intentar crear el recibo y recibir la respuesta que incluye el recibo creado
            const createdRecibo = await dispatch(addRecibo({ recibo: { ...xrecibo, monto: totalPagado }, reciboDetalles })).unwrap();
                
                Swal.fire('Éxito', 'Recibo registrado correctamente', 'success');
                
                // Enviar el id del recibo creado al cerrador/llamador
                handleClose(createdRecibo.id);
                
            } catch (error) {
                Swal.fire('Error', 'No se pudo registrar el recibo', 'error');
            }
                };

    return (
        <div style={{ width: '100%', margin: '0 auto' }}>
            <Form.Group>
                <Form.Label>Fecha</Form.Label>
                <Form.Control type="text" value={fecha} readOnly />
            </Form.Group>

            <Form.Group>
                <Form.Label>Beneficiario</Form.Label>
                <Form.Control
                    type="text"
                    value={recibo.beneficiario}
                    onChange={(e) => setRecibo({ ...recibo, beneficiario: e.target.value })}
                />
            </Form.Group>

            <Form.Group>
                <Form.Label>Concepto</Form.Label>
                <Form.Control
                    type="text"
                    value={recibo.concepto}
                    onChange={(e) => setRecibo({ ...recibo, concepto: e.target.value })}
                />
            </Form.Group>

            <h5>Detalles del Recibo</h5>
            <Table bordered>
                <thead>
                    <tr>
                        <th>Cantidad</th>
                        <th>Concepto</th>
                        <th>PU</th>
                        <th>Monto</th>
                        <th>Descuento</th>
                        <th>Pagado</th>
                        <th>Acciones</th>
                    </tr>
                </thead>
                <tbody>
                    {detalles.map((detalle, index) => (
                        <tr key={index}>
                            <td>
                                <Form.Control
                                    type="number"
                                    value={detalle.cantidad}
                                    min="1"
                                    onChange={(e) => handleDetailChange(index, 'cantidad', e.target.value)}
                                />
                            </td>
                            <td>
                            <Form.Control
                                as="select"
                                value={detalle.concepto}
                                onChange={(e) => {
                                    const selectedIngreso = ingresosVarios.find(ingreso => ingreso.descripcion === e.target.value);
                                    if (selectedIngreso) {
                                        handleDetailChange(index, 'concepto', selectedIngreso.descripcion); // Guardar la descripción
                                        handleDetailChange(index, 'codigo_cuenta', selectedIngreso.codigo_cuenta); // Guardar el codigo_cuenta
                                    }
                                }}
                            >
                                <option value="">Seleccione Otros Ingresos</option>
                                {ingresosVarios.map((ingreso) => (
                                    <option key={ingreso.id} value={ingreso.descripcion}>
                                        {ingreso.descripcion}
                                    </option>
                                ))}
                            </Form.Control>
                            </td>
                            <td>
                                <Form.Control
                                    type="number"
                                    value={detalle.pu}
                                    onChange={(e) => handleDetailChange(index, 'pu', e.target.value)}
                                />
                            </td>
                            <td>
                                <Form.Control type="text" value={detalle.monto.toFixed(2)} readOnly />
                            </td>
                            <td>
                                <Form.Control
                                    type="number"
                                    value={detalle.descuento}
                                    onChange={(e) => handleDetailChange(index, 'descuento', e.target.value)}
                                />
                            </td>
                            <td>
                                <Form.Control type="text" value={detalle.pagado.toFixed(2)} readOnly />
                            </td>
                            <td>
                                {detalles.length > 1 && (
                                    <button onClick={() => removeDetail(index)}>Eliminar</button>
                                )}
                                {index === detalles.length - 1 && (
                                    <button onClick={addDetail}>Añadir</button>
                                )}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>

            <h5>Total: {totalPagado.toFixed(2)} Bs.</h5>

            <Button onClick={handleSubmit}>Registrar Recibo</Button>
        </div>
    );
};

export default ReciboForm;
