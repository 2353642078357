import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import apiService from "../services/apiService";

// Crear una instancia del servicio para la entidad "requisitos"
const requisitoService = apiService('/requisitos');

// Thunks para las operaciones CRUD
export const fetchRequisitos = createAsyncThunk('requisito/fetchRequisitos', async () => {
  const response = await requisitoService.getAll();
  return response || [];
});

export const fetchRequisitoById = createAsyncThunk('requisito/fetchRequisitoById', async (id) => {
  const response = await requisitoService.getById(id);
  return response;
});

export const addRequisito = createAsyncThunk('requisito/addRequisito', async (requisito, { rejectWithValue }) => {
  try {
    const response = await requisitoService.create(requisito);
    return response;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const modifyRequisito = createAsyncThunk('requisito/modifyRequisito', async ({ id, requisito }, { rejectWithValue }) => {
  try {
    const response = await requisitoService.update(id, requisito);
    return response;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const removeRequisito = createAsyncThunk('requisito/removeRequisito', async (id, { rejectWithValue }) => {
  try {
    await requisitoService.remove(id);
    return id;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

// Estado inicial del slice
const initialState = {
  requisitos: [],
  current: null,
  status: 'idle',
  error: null,
  statusSend: 'idle', // Añadido para manejar el estado de envío
};

// Definición del slice
const requisitoSlice = createSlice({
  name: 'requisito',
  initialState,
  reducers: {
    setCurrentRequisito(state, action) {
      state.current = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchRequisitos.fulfilled, (state, action) => {
        state.requisitos = action.payload;
        state.status = 'succeeded';
      })
      .addCase(fetchRequisitos.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchRequisitos.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(fetchRequisitoById.fulfilled, (state, action) => {
        state.current = action.payload;
      })
      .addCase(addRequisito.fulfilled, (state, action) => {
        state.requisitos.push(action.payload);
        state.statusSend = 'succeeded';
      })
      .addCase(addRequisito.pending, (state) => {
        state.statusSend = 'sending';
      })
      .addCase(addRequisito.rejected, (state, action) => {
        state.statusSend = 'failed';
        state.error = action.payload.error || action.error.message;
      })
      .addCase(modifyRequisito.fulfilled, (state, action) => {
        const index = state.requisitos.findIndex(requisito => requisito.id === action.payload.id);
        if (index !== -1) {
          state.requisitos[index] = action.payload;
        }
        state.statusSend = 'succeeded';
      })
      .addCase(modifyRequisito.pending, (state) => {
        state.statusSend = 'sending';
      })
      .addCase(modifyRequisito.rejected, (state, action) => {
        state.statusSend = 'failed';
        state.error = action.payload.error || action.error.message;
      })
      .addCase(removeRequisito.fulfilled, (state, action) => {
        state.requisitos = state.requisitos.filter(requisito => requisito.id !== action.payload);
      })
      .addCase(removeRequisito.pending, (state) => {
        state.statusSend = 'sending';
      })
      .addCase(removeRequisito.rejected, (state, action) => {
        state.statusSend = 'failed';
        state.error = action.payload.error || action.error.message;
      });
  }
});

export const { setCurrentRequisito } = requisitoSlice.actions;

export default requisitoSlice.reducer;
