import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import apiService from "../services/apiService"; // Importa el servicio actualizado

// Crear una instancia del servicio para la entidad "categorias"
const categoriaService = apiService('/categorias');

// Thunks para las operaciones CRUD
export const fetchCategorias = createAsyncThunk('categoria/fetchCategorias', async () => {
  const response = await categoriaService.getAll();
  return response || [];
});
export const fetchCategoriasConIndices = createAsyncThunk('categoria/fetchCategoriasConIndices', async () => {
  const response = await categoriaService.getAllAndSomeAssociation("indices");
  return response || [];
});
export const fetchCategoriaById = createAsyncThunk('categoria/fetchCategoriaById', async (id) => {
  const response = await categoriaService.getById(id);
  return response;
});

export const addCategoria = createAsyncThunk('categoria/addCategoria', async (categoria, { rejectWithValue }) => {
  try {
    const response = await categoriaService.create(categoria);
    return response;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const modifyCategoria = createAsyncThunk('categoria/modifyCategoria', async ({ id, categoria }, { rejectWithValue }) => {
  try {
    const response = await categoriaService.update(id, categoria);
    return response;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const removeCategoria = createAsyncThunk('categoria/removeCategoria', async (id, { rejectWithValue }) => {
  try {
    await categoriaService.remove(id);
    return id;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

// Estado inicial del slice
const initialState = {
  categorias: [],
  current: null,
  statusSend: 'idle',
  statusGet:'idle',
  error: {
    status: null,
    messages:[]
  },
};

// Definición del slice
const categoriaSlice = createSlice({
  name: 'categoria',
  initialState,
  reducers: {
    setCurrentCategoria(state, action) {
      state.current = action.payload;
    },
    resetStatusSend(state) {
        state.statusSend = "idle";
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCategorias.fulfilled, (state, action) => {
        state.categorias = action.payload;
        state.statusGet = 'idle';
      })
      .addCase(fetchCategorias.pending, (state) => {
        state.statusGet = 'loading';
      })
      .addCase(fetchCategorias.rejected, (state, action) => {
        state.statusGet = 'failed';
        state.error = action.error.message;
      })
      .addCase(fetchCategoriasConIndices.fulfilled, (state, action) => {
        state.categorias = action.payload;
        state.statusGet = 'idle';
      })
      .addCase(fetchCategoriasConIndices.pending, (state) => {
        state.statusGet = 'loading';
      })
      .addCase(fetchCategoriasConIndices.rejected, (state, action) => {
        state.statusGet = 'failed';
        state.error = action.error.message;
      })
      .addCase(fetchCategoriaById.fulfilled, (state, action) => {
        state.current = action.payload;
        state.statusGet = 'idle';
      })
      .addCase(addCategoria.fulfilled, (state, action) => {
        state.categorias.push(action.payload);
        state.statusSend = 'idle';
      })
      .addCase(addCategoria.pending, (state) => {
        state.statusSend = 'sending';
      })
      .addCase(addCategoria.rejected, (state, action) => {
        state.statusSend = 'failed';
        state.error = action.payload || action.error.message;
      })
      .addCase(modifyCategoria.fulfilled, (state, action) => {
        const index = state.categorias.findIndex(categoria => categoria.id === action.payload.id);
        if (index !== -1) {
          state.categorias[index] = action.payload;
        }
        state.statusSend = 'idle';
      })
      .addCase(modifyCategoria.pending, (state, action) => {
        state.statusSend = 'sending';
      })
      .addCase(modifyCategoria.rejected, (state, action) => {
        state.statusSend = 'failed';
        state.error = action.payload.error || action.error.message;
      })
      .addCase(removeCategoria.fulfilled, (state, action) => {
        state.categorias = state.categorias.filter(categoria => categoria.id !== action.payload);
        state.statusSend = 'idle';
      })
      .addCase(removeCategoria.pending, (state) => {
        state.statusSend = 'sending';
      })
      .addCase(removeCategoria.rejected, (state, action) => {
        state.statusSend = 'failed';
        state.error = action.payload || action.error.message;
      });
  }
});

export const { setCurrentCategoria, resetStatusSend } = categoriaSlice.actions;

export default categoriaSlice.reducer;
