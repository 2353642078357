import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import apiService from "../services/apiService"; // Importa el servicio actualizado

// Crear una instancia del servicio para la entidad "tipologias"
const tipologiaService = apiService('/tipologias');

// Thunks para las operaciones CRUD
export const fetchTipologias = createAsyncThunk('tipologia/fetchTipologias', async () => {
  const response = await tipologiaService.getAll();
  return response || [];
});

export const fetchTipologiaById = createAsyncThunk('tipologia/fetchTipologiaById', async (id) => {
  const response = await tipologiaService.getById(id);
  return response;
});

export const addTipologia = createAsyncThunk('tipologia/addTipologia', async (tipologia, { rejectWithValue }) => {
  try {
    const response = await tipologiaService.create(tipologia);
    return response;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const modifyTipologia = createAsyncThunk('tipologia/modifyTipologia', async ({ id, tipologia }, { rejectWithValue }) => {
  try {
    const response = await tipologiaService.update(id, tipologia);
    return response;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const removeTipologia = createAsyncThunk('tipologia/removeTipologia', async (id, { rejectWithValue }) => {
  try {
    await tipologiaService.remove(id);
    return id;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

// Estado inicial del slice
const initialState = {
  tipologias: [],
  current: null,
  statusSend: 'idle',
  statusGet:'idle',
  error: {
    status: null,
    messages:[]
  },
};

// Definición del slice
const tipologiaSlice = createSlice({
  name: 'tipologia',
  initialState,
  reducers: {
    setCurrentTipologia(state, action) {
      state.current = action.payload;
    },
    resetStatusSend(state) {
        state.statusSend = "idle";
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTipologias.fulfilled, (state, action) => {
        state.tipologias = action.payload;
        state.statusGet = 'idle';
      })
      .addCase(fetchTipologias.pending, (state) => {
        state.statusGet = 'loading';
      })
      .addCase(fetchTipologias.rejected, (state, action) => {
        state.statusGet = 'failed';
        state.error = action.error.message;
      })
      .addCase(fetchTipologiaById.fulfilled, (state, action) => {
        state.current = action.payload;
        state.statusGet = 'idle';
      })
      .addCase(addTipologia.fulfilled, (state, action) => {
        state.statusSend = 'idle';
      })
      .addCase(addTipologia.pending, (state) => {
        state.statusSend = 'sending';
      })
      .addCase(addTipologia.rejected, (state, action) => {
        state.statusSend = 'failed';
        state.error = action.payload || action.error.message;
      })
      .addCase(modifyTipologia.fulfilled, (state, action) => {
        const index = state.tipologias.findIndex(tipologia => tipologia.id === action.payload.id);
        if (index !== -1) {
          state.tipologias[index] = action.payload;
        }
        state.statusSend = 'idle';
      })
      .addCase(modifyTipologia.pending, (state, action) => {
        state.statusSend = 'sending';
      })
      .addCase(modifyTipologia.rejected, (state, action) => {
        state.statusSend = 'failed';
        state.error = action.payload.error || action.error.message;
      })
      .addCase(removeTipologia.fulfilled, (state, action) => {
        state.tipologias = state.tipologias.filter(tipologia => tipologia.id !== action.payload);
        state.statusSend = 'idle';
      })
      .addCase(removeTipologia.pending, (state) => {
        state.statusSend = 'sending';
      })
      .addCase(removeTipologia.rejected, (state, action) => {
        state.statusSend = 'failed';
        state.error = action.payload || action.error.message;
      });
  }
});

export const { setCurrentTipologia, resetStatusSend } = tipologiaSlice.actions;

export default tipologiaSlice.reducer;
