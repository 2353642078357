import React, { useEffect, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { jsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable';
import cabeceraCud from '../../assets/cabeceraCat.png';
let titulo =""
    
const CustomPrintModalH = ({ show, handleClose, title1, title2, content }) => {
    const [pdfUrl, setPdfUrl] = useState(null);

    useEffect(() => {
        if (show) {
            generatePdf();
        }
    }, [show, content]);

    const generatePdf = () => {
        const doc = new jsPDF('landscape', 'pt', 'letter'); // Establecer tamaño de hoja a carta en orientación horizontal

        const marginLeftRight = 40; // Márgenes izquierdo y derecho
        const marginTop = 40; // Margen superior
        const marginBottom = 50; // Ajustar el margen inferior para bajar el pie de página
        const headerHeight = 80; // Altura de la cabecera

        const addHeader = (doc, pageNumber) => {
            doc.addImage(cabeceraCud, 'PNG', marginLeftRight, marginTop, doc.internal.pageSize.getWidth() - (marginLeftRight * 2), headerHeight);
            doc.setFontSize(12);
            doc.text(titulo, doc.internal.pageSize.getWidth() / 2, marginTop + headerHeight + 10, { align: 'center' });
            doc.text(title1, doc.internal.pageSize.getWidth() / 2, marginTop + headerHeight + 25, { align: 'center' });
            if (title2 !== "") {
                doc.text(title2, doc.internal.pageSize.getWidth() / 2, marginTop + headerHeight + 40, { align: 'center' });
            }
            doc.setFontSize(10);
            if(pageNumber > 0) {
                doc.text(`Página ${pageNumber}`, doc.internal.pageSize.getWidth() - marginLeftRight, marginTop + headerHeight + 15, { align: 'right' });
            }
        };

        const addFooter = (doc) => {
            const pageHeight = doc.internal.pageSize.getHeight();
            const pageWidth = doc.internal.pageSize.getWidth();

            doc.setDrawColor(0, 0, 0); // Verde más oscuro
           //doc.setLineWidth(1);
            //doc.line(marginLeftRight, pageHeight - marginBottom - 10, pageWidth - marginLeftRight, pageHeight - marginBottom - 10);

            doc.setFontSize(12);
            doc.setTextColor(0, 0, 0); // negro más oscuro
            doc.setFont("times", "normal");
            doc.setFontSize(8);
            doc.text(' Calle La Madrid # 615 casi Esq. Junín - Celular.: (591-4)6640066 - cat.tarija@gmail.com', pageWidth / 2, pageHeight - marginBottom + 20, { align: 'center' });
            doc.text('Tarija - Bolivia', pageWidth / 2, pageHeight - marginBottom + 30, { align: 'center' });
            doc.setFontSize(8);
            doc.text(`Impreso el: ${new Date().toLocaleString()}`, pageWidth - marginLeftRight, pageHeight - marginBottom + 40, { align: 'right' });
        };
        // Inicializa el número de página
        let pageNumber = 0;

        if (typeof content === 'string') {
            addHeader(doc, pageNumber);
            doc.text(content, marginLeftRight, marginTop + headerHeight + 40);
            addFooter(doc);
        } else {
            addHeader(doc, pageNumber);
            autoTable(doc, {
                head: content.head,
                body: content.body,
                startY: marginTop + headerHeight + 50,
                margin: { top: marginTop + headerHeight + 50, left: marginLeftRight, right: marginLeftRight, bottom: marginBottom },
                didDrawPage: function (data) {
                    pageNumber++;
                    addHeader(doc, pageNumber);
                    addFooter(doc);
                },
                styles: { 
                    fontSize:9,
                    lineColor: [0, 0, 0], // Color de borde (negro)
                    lineWidth: 0.5, // Grosor del borde más delgado
                },
                headStyles: {
                    fillColor: [255, 255, 255], // Fondo blanco para el encabezado
                    textColor: [0, 0, 0], // Texto negro para el encabezado
                    lineWidth: 0.5, // Grosor del borde del encabezado más delgado
                    lineColor: [0, 0, 0], // Color de borde del encabezado
                },
                bodyStyles: {
                    fillColor: [255, 255, 255], // Fondo blanco para el cuerpo
                    textColor: [0, 0, 0], // Texto negro para el cuerpo
                    lineWidth: 0.5, // Grosor del borde del cuerpo más delgado
                    lineColor: [0, 0, 0], // Color de borde del cuerpo
                },
                pageBreak: 'auto',
            });
        }

        const pdfBlob = doc.output('blob');
        const pdfUrl = URL.createObjectURL(pdfBlob);
        setPdfUrl(pdfUrl);
    };

    return (
        <Modal show={show} onHide={handleClose} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>{title1}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {pdfUrl && (
                    <iframe src={pdfUrl} width="100%" height="500px" title="LISTADO GENERAL"></iframe>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Cerrar
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default CustomPrintModalH;
