import { redirect } from "react-router-dom";

export function getTokenDuration() {
    const storedExpirationDate = localStorage.getItem('expiration');
    const expirationDate = new Date(storedExpirationDate);
    const now = new Date();
    const duration = expirationDate.getTime() - now.getTime();
    return duration;
}
export function getAuthToken() {
    const token = localStorage.getItem('token');
    const tokenDuration = getTokenDuration();
    
    if(!token) {
        return null;
    }

    if(tokenDuration < 0 ) {
        return 'EXPIRED';
    }
    return token;
}

export function tokenLoader() {
    return getAuthToken();
}

export function checkAuthLoader() {
    const token = getAuthToken();

    if(!token) {
        return redirect('/login')
    }
    return null;
}
export function checkAdminSistemaLoader() {
    const rolActivo = localStorage.getItem('rolActivo');

    checkAuthLoader();

    if(rolActivo!=="admin"){
        return redirect('/login')
    }
    return null;
}
