import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import apiService from "../services/apiService";

// Crear una instancia del servicio para la entidad "unidades"
const honorariosUnidadService = apiService('/honorarios-unidad');

// Thunks para las operaciones CRUD
export const fetchHonorariosUnidadRepetida = createAsyncThunk('unidad/fetchHonorariosUnidadRepetida', async () => {
  const response = await honorariosUnidadService.getAll();
  return response || [];
});


// Estado inicial del slice
const initialState = {
  honorariosUnidadRepetida: [],
  status: 'idle',
  error: null,
};

// Definición del slice
const unidadSlice = createSlice({
  name: 'unidad',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchHonorariosUnidadRepetida.fulfilled, (state, action) => {
        state.honorariosUnidadRepetida = action.payload;
      })
      .addCase(fetchHonorariosUnidadRepetida.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchHonorariosUnidadRepetida.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
  }
});

export default unidadSlice.reducer;
