import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import apiService from '../services/apiService';

// Crear una instancia del servicio para la entidad "banco"
const bancoService = apiService('/bancos');

// Thunk para obtener todos los bancos
export const fetchBancos = createAsyncThunk('banco/fetchBancos', async () => {
  const response = await bancoService.getAll();
  return response;
});

// Thunk para agregar un nuevo banco
export const addBanco = createAsyncThunk('banco/addBanco', async (newBanco) => {
  const response = await bancoService.create(newBanco);
  return response;
});

// Thunk para modificar un banco
export const modifyBanco = createAsyncThunk('banco/modifyBanco', async ({ id, banco }) => {
  const response = await bancoService.update(id, banco);
  return response;
});

// Thunk para eliminar un banco
export const deleteBanco = createAsyncThunk('banco/deleteBanco', async (id) => {
  await bancoService.delete(id);
  return id;
});

// Slice de banco
const bancoSlice = createSlice({
  name: 'banco',
  initialState: {
    bancos: [],
    currentBanco: null,
    status: 'idle',
    error: null,
  },
  reducers: {
    setCurrentBanco(state, action) {
      state.currentBanco = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchBancos.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchBancos.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.bancos = action.payload;
      })
      .addCase(fetchBancos.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(addBanco.fulfilled, (state, action) => {
        state.bancos.push(action.payload);
      })
      .addCase(modifyBanco.fulfilled, (state, action) => {
        const index = state.bancos.findIndex(banco => banco.id === action.payload.id);
        state.bancos[index] = action.payload;
      })
      .addCase(deleteBanco.fulfilled, (state, action) => {
        state.bancos = state.bancos.filter(banco => banco.id !== action.payload);
      });
  }
});

export const { setCurrentBanco } = bancoSlice.actions;

export default bancoSlice.reducer;
