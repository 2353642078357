import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import apiService from "../services/apiService";

// Crear una instancia del servicio para la entidad "plan de cuentas"
const planCuentasService = apiService('/planes-cuentas');

// Thunks para las operaciones CRUD
export const fetchPlanCuentas = createAsyncThunk('planCuentas/fetchPlanCuentas', async () => {
  const response = await planCuentasService.getAll();
  //console.log("slice plancuentas=",response)
  return response || [];
});

export const addPlanCuenta = createAsyncThunk('planCuentas/addPlanCuenta', async (cuenta, { rejectWithValue }) => {
  try {
    const response = await planCuentasService.create(cuenta);
    return response;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const modifyPlanCuenta = createAsyncThunk('planCuentas/modifyPlanCuenta', async ({ id, cuenta }, { rejectWithValue }) => {
  try {
    const response = await planCuentasService.update(id, cuenta);
    return response;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const removePlanCuenta = createAsyncThunk('planCuentas/removePlanCuenta', async (id, { rejectWithValue }) => {
  try {
    await planCuentasService.remove(id);
    return id;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

// Estado inicial del slice
const initialState = {
  planCuentas: [],
  statusSend: 'idle',
  statusGet: 'idle',
  error: {
    status: null,
    messages: []
  },
};

// Definición del slice
const planCuentasSlice = createSlice({
  name: 'planCuentas',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPlanCuentas.fulfilled, (state, action) => {
        state.planCuentas = action.payload;
        state.statusGet = 'idle';
      })
      .addCase(fetchPlanCuentas.pending, (state) => {
        state.statusGet = 'loading';
      })
      .addCase(fetchPlanCuentas.rejected, (state, action) => {
        state.statusGet = 'failed';
        state.error = action.error.message;
      })
      .addCase(addPlanCuenta.fulfilled, (state, action) => {
        state.planCuentas.push(action.payload);
        state.statusSend = 'idle';
      })
      .addCase(modifyPlanCuenta.fulfilled, (state, action) => {
        const index = state.planCuentas.findIndex(cuenta => cuenta.id === action.payload.id);
        if (index !== -1) {
          state.planCuentas[index] = action.payload;
        }
        state.statusSend = 'idle';
      })
      .addCase(removePlanCuenta.fulfilled, (state, action) => {
        state.planCuentas = state.planCuentas.filter(cuenta => cuenta.id !== action.payload);
        state.statusSend = 'idle';
      });
  }
});

export default planCuentasSlice.reducer;
