import React, { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchInmuebles, inmuebleActions, removeInmueble } from '../../reducers/inmueble_slice';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import CustomPrintModal from '../UI/CustomPrintModal';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import * as XLSX from 'xlsx';
import ActionButton from '../UI/Buttons/ActionButton';
import FormInmueble from './FormInmueble';
import { InputGroup } from 'react-bootstrap';

const ListInmuebles = ({onSelectInmueble=null, handleDelete}) => {
  const dispatch = useDispatch();
  const inmuebles = useSelector(state => state.inmueble.inmuebles || []);
  const sortedFilteredInmuebles = useSelector(state => state.inmueble.sortedFilteredList || inmuebles);
  const [sortConfig, setSortConfig] = useState({ key1: null, key2: null, key3: null,  direction: 'asc' });
  const [filters, setFilters] = useState({ 
    searchTerm:''
  })
  const [showModalInmueble, setShowModalInmueble] = useState(false);
  useEffect(()=>{
    let filteredList = filters.searchTerm !== ""? [...inmuebles].filter(inmu => inmu.cod_catastral.includes(filters.searchTerm)) 
              : [...inmuebles];

              let sortedData = [...filteredList]
              if (sortConfig.key2) {
                sortedData.sort((a, b) => {
                let aValue, bValue;
                    // Comparar subpropiedades
                    if (sortConfig.key1 !== "") {
                      
                      if (sortConfig.key3 !== "") {
                        aValue = a[sortConfig.key1][sortConfig.key2][sortConfig.key3];
                        bValue = b[sortConfig.key1][sortConfig.key2][sortConfig.key3];
                      }else{
                        aValue = a[sortConfig.key1][sortConfig.key2];
                        bValue = b[sortConfig.key1][sortConfig.key2];
                      }
                    } 
                    // Comparar otros valores
                    else {
                        aValue = a[sortConfig.key2];
                        bValue = b[sortConfig.key2];
                    }
                    // Manejar valores nulos para que vayan al final
                    if (aValue === null || aValue === undefined || Number.isNaN(aValue)) return 1;
                    if (bValue === null ||  bValue === undefined || Number.isNaN(bValue)) return -1;
            
                    // Comparar valores no nulos
                    if (aValue < bValue) {
                        return sortConfig.direction === 'asc' ? -1 : 1;
                    }
                    if (aValue > bValue) {
                        return sortConfig.direction === 'asc' ? 1 : -1;
                    }
                    return 0;
              });
            }
      dispatch(inmuebleActions.setSortedFilteredList(sortedData))

},[inmuebles, sortConfig, filters])

  const handleFilters = (filter, value) => {
    setFilters((prev) => ({ ...prev, [filter]: value }));
};

  const handleSort = (key1, key2, key3) => {
    if (sortConfig.direction === 'desc') {
      setSortConfig({ key3:null, key1:null, key2: null, direction: 'asc' });
      return;
    }
    let direction = 'asc';
    if (sortConfig.key3 === key3 && sortConfig.key1 === key1 && sortConfig.key2 === key2 && sortConfig.direction === 'asc') {
      direction = 'desc';
    } else if (sortConfig.key3 === key3 && sortConfig.key1 === key1 && sortConfig.key2 === key2 && sortConfig.direction === 'desc') {
      direction = null; // Si ya estaba descendente, lo dejamos sin ordenar
    }
    setSortConfig({key3, key1, key2, direction });
  };
  const handleEdit = inmueble => {
    dispatch(inmuebleActions.setCurrentInmueble(inmueble))
    setShowModalInmueble(true);
  };

  const handleCreate = () => {
    dispatch(inmuebleActions.setCurrentInmueble(null))
    setShowModalInmueble(true);
  };

  const handleClose = () => {
    setShowModalInmueble(false);
    dispatch(inmuebleActions.setCurrentInmueble(null))
  };
  const handleSelect = (inmueble) => {
    if(onSelectInmueble){
      onSelectInmueble(inmueble)
    }
  };


  return (
   <>    <div className="list-container-header">
   <div className="left-buttons">
   <InputGroup size="sm">
        <InputGroup.Text>
        <i className="bi bi-search"></i>
        </InputGroup.Text>
        <Form.Control
          type="text"
          placeholder="Código catastral"
          value={filters.searchTerm}
          onChange={(e) => handleFilters("searchTerm", e.target.value)}
        />
      </InputGroup>
       </div>
       <div className="right-buttons">
         <ActionButton onClick={handleCreate} outline icon='plus' floatEnd>
           Nuevo Inmueble
         </ActionButton>
       </div>
     </div>
        {sortedFilteredInmuebles.length === 0 ? (
          <p>No hay inmuebles disponibles.</p>
        ) : (
          <div className='table-container'>
           
            <Table bordered hover size='sm'>
              <thead className='text-center align-middle'>
                <tr>
                  <th className='col-index'>N°</th>
                  <th onClick={() => handleSort('','cod_catastral','')}>
                    Código catastral
                    {sortConfig.key2 === 'cod_catastral' && (
                        sortConfig.direction === 'asc' ? <i className="bi bi-arrow-up"></i> : <i className="bi bi-arrow-down"></i>
                    )}
                  </th>
                  <th onClick={() => handleSort('zona','nombre','')}>
                    Zona
                    {sortConfig.key1 === 'zona' && sortConfig.key2 === 'nombre' && (
                        sortConfig.direction === 'asc' ? <i className="bi bi-arrow-up"></i> : <i className="bi bi-arrow-down"></i>
                    )}
                  </th>
                  <th onClick={() => handleSort('zona','lugar','nombre')}>
                    Lugar
                    {sortConfig.key2 === 'lugar' && (
                        sortConfig.direction === 'asc' ? <i className="bi bi-arrow-up"></i> : <i className="bi bi-arrow-down"></i>
                    )}
                  </th>
                  {!onSelectInmueble && <th className='col-simple-actions'>Acciones</th>}
                </tr>
              </thead>
              <tbody>
                {sortedFilteredInmuebles.map((inmueble, index) => (
                  <tr key={inmueble.id} onClick={() => onSelectInmueble(inmueble)}>
                    <td>{index + 1}</td>
                    <td>{inmueble.cod_catastral}</td>
                    <td>{inmueble.zona.nombre}</td>
                    <td>{inmueble.zona.lugar.nombre}</td>
                   {!onSelectInmueble && <td  onClick={(e) => { e.stopPropagation(); }}>
                      <button className="icon-button btn-secondary-outline" title="Editar" onClick={() => handleEdit(inmueble)}>
                        <i className="bi bi-pencil"></i>
                      </button>
                      <button className="icon-button btn-secondary-outline" title="Proyectos" onClick={() => handleEdit(inmueble)}>
                      <i className="bi bi-list-ol"></i>
                      </button>
                      <button className="icon-button btn-secondary-outline" title="Eliminar" onClick={() => handleDelete(inmueble.id)}>
                        <i className="bi bi-trash"></i>
                      </button>
                    </td>}
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        )}
            <FormInmueble showModal={showModalInmueble} handleClose={handleClose} onSelectInmueble={onSelectInmueble}/>
    </>
  );
};

export default ListInmuebles;
