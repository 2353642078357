import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addPropietario, modifyPropietario, fetchPropietarios } from '../../reducers/propietario_slice';
import { fetchPersonas } from '../../reducers/persona_slice';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Swal from 'sweetalert2';
import { Alert, Col, Row } from 'react-bootstrap';
import ActionButton from '../UI/Buttons/ActionButton';
import PersonaForm from '../persona/PersonaForm';
import { fullNamePersona } from '../../util/customFormatter';
import TablePersona from '../persona/TablePersona';

const initialState = {
    id_persona: "",
    aliasSituacion:"preinscrito",
    observacion: "",
}

const FormPropietario = ({ currentPropietario, handleClose=null, buttonText=null, resetFormDetalles=null}) => {
    const dispatch = useDispatch();
    const [datos, setDatos] = useState(initialState);
    const [error, setError] = useState('');
    const [isModified, setIsModified] = useState(false);
    const persona = useSelector(state => state.persona.current);
    const [showModalPersona, setShowModalPersona] = useState(false);
   
    useEffect(() => {
        if (currentPropietario) {
            setDatos({
                observacion:"",
                aliasSituacion:currentPropietario.situacion.alias,
            });
            setIsModified(false);
        } else {
            resetForm();
       }
    }, [currentPropietario, dispatch]);

    useEffect(() => {
           if(persona){ setDatos({
                id_persona: persona.id,
                aliasSituacion:"preinscrito",
                observacion: "",
            });}
    }, [persona]);

    const handleSubmit = e => {
        e.preventDefault();

        if (isModified) {
            setError('');

            if (currentPropietario) {
               dispatch(modifyPropietario({id:currentPropietario.id, situacion:datos.aliasSituacion, comentario:"Cambio de situación", observacion:datos.observacion}))
            } else {
                dispatch(addPropietario(datos)).then(response => {
                    if (response.error || response.payload.error) {
                        Swal.fire({
                            icon: 'error',
                            title: 'Error',
                            text: response.payload.error || response.error.message || 'Error al crear el propietario.',
                        });
                    } else {
                        if(handleClose) handleClose();
                        dispatch(fetchPropietarios());
                    }
                });
            }
        } else {
            if(handleClose) handleClose();
        }
    };

    const resetForm = () => {
        setDatos(initialState);
        if(resetFormDetalles) resetFormDetalles();
        setError('');
        setIsModified(false);
    };

  const handleSelectPersona = (persona) => {
    setDatos({ ...datos, id_persona: persona.id, personaSeleccionada:persona });
};

    return (
        <>
                {error && (
                    <Alert variant="danger">
                        {error}
                    </Alert>
                )}
                    <Row xs={1} className="mt-3">
                       {!currentPropietario && 
                       <><Col className='col-form'>
                            <Form.Label>Propietario
                                </Form.Label>
                    {datos.personaSeleccionada ? (
                        <div className="selected-container p-3 border rounded shadow-sm">
                        <div>
                            <strong>{`${datos.personaSeleccionada.nombre} ${datos.personaSeleccionada.apellido1} ${datos.personaSeleccionada.apellido2}`}</strong> <br />
                            <span>C.I.: {datos.personaSeleccionada.ci}</span> 
                        </div>
                        <Button variant="link" onClick={() => setDatos({ ...datos, id_persona: '',personaSeleccionada:null })}>
                            Cambiar persona
                        </Button>
                        </div>
                    ) : (
                        <>
                        <div className="alert alert-info p-2">
                        <p>No se ha seleccionado una persona aún.</p>
                        </div>
                        {/* Aquí puedes renderizar la tabla de personas */}
                        <TablePersona onSelectPersona={handleSelectPersona} simplePersona={true}/>
                        </>
                    )}
                           
                        </Col>
                        </>
                       }
                       
                    </Row>
        </>
    );
};

export default FormPropietario;
