import React, { useState, useEffect, useMemo, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addProyecto, modifyProyecto, fetchProyectos } from '../../reducers/proyecto_slice';
import { fetchAreasProyecto } from '../../reducers/areaProyecto_slice';
import { fetchTiposProyecto } from '../../reducers/tipoProyecto_slice';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Swal from 'sweetalert2';
import { Alert,  Table, Card, Col, Row, Tab, Tabs, ListGroup, Image } from 'react-bootstrap';
import ActionButton from '../UI/Buttons/ActionButton';
import { fetchTipologias } from "../../reducers/tipologia_slice";
import { fetchCategoriasConIndices } from "../../reducers/categoria_slice";
import { formatearDecimal, formatNumber, formatoOracion, fullNamePersona } from "../../util/customFormatter";
import { fetchHonorariosUnidadRepetida } from "../../reducers/unidad_slice";
import SelectAfiliado from '../afiliado/SelectAfiliado';
import SelectInmueble from '../inmueble/SelectInmueble';
import SelectPersona from '../persona/SelectPersona';
import { fetchPersonas } from '../../reducers/persona_slice';
import { fetchCuotasByAfiliado, setCuotas } from '../../reducers/cuota_slice';
import { useInputFile } from '../../hooks/useInputFile';
import imgPlaceholder from '../../assets/plano_placeholder2.png';
import { getUploadUrl } from '../../util/valueCalculator';
const initialState = {
    id_proyectista: "",
    id_propietario: "",
    id_inmueble: "",
    id_gerente:"",
    id_tipo_proyecto: "",
    descripcion:"",
    honorarios_total:0,
    comision_cat:0,
    superficie_total:"",
    estado:"borrador",
    unidades:[],
    valorados:[],
    proyectista:null,
    inmueble:null,
    propietario:null
}

const verificacionesInitialForm = {
   proyectistaAportes:null
}

const initialUnidad = {
    id_categoria: "",
    id_area:"",
    id_tipologia: "",
    tipologia:{},
    descripcion: "",
    superficie: "",
    descripcion: "",
    honorarios:"0",
    indice_calculo:"0",
    num_unidades: "1",
}

const initialErrors = {
    id_tipo_proyecto: false,
    descripcion: false,
    proyectista: false,
    inmueble: false,
    propietario: false,
  }
const initialFormDetalles = {title:"Nuevo Proyecto",
    headerColor:"#6CB1C2", 
    buttonText:"Crear" }

const porcentaje_comision=0.06;
const FormProyecto = ({ currentProyecto, showModal, handleClose }) => {


    const dispatch = useDispatch();
    const areaStatus = useSelector(state => state.areaProyecto.statusGet);
    const tipoStatus = useSelector(state => state.tipoProyecto.statusGet);
    const requestStatus = useSelector(state => state.proyectoConstruccion?.statusSend);
    const errorInRequest = useSelector(state => state.proyectoConstruccion?.error);
    
    const [datos, setDatos] = useState(initialState);
    const {
        file: fotoPlano,
        handleFileChange: handleFotoChange,
        resetInputFile: resetFoto,
        isValid: isFotoValid
    } = useInputFile("image");

    const [fotoPreview, setFotoPreview] = useState(imgPlaceholder);
    const [errors, setErrors] = useState(initialErrors);
    const [verificaciones, setVerificaciones] = useState(verificacionesInitialForm);
    const [error, setError] = useState('');
    const [isModified, setIsModified] = useState(false);
    const [key, setKey] = useState('proyectista');
    const tipologias = useSelector(state => state.tipologia.tipologias || []);
    const categorias = useSelector(state => state.categoria.categorias || []);
    const honorariosPorUnidadRepetida = useSelector(state => state.unidad.honorariosUnidadRepetida || []);
    const tiposProyecto = useSelector(state => state.tipoProyecto.tipos || []);
    const { rolActivo, nombre } = useSelector(state => state.sesion);

    const [unidadSelected, setUnidadSelected] = useState(initialUnidad);
    const [errorUnidadForm, setErrorUnidadForm] = useState("");
    const [editIndex, setEditIndex] = useState(null); // Para manejar la edición
    const [tipoChanged, setTipoChanged] = useState(null);
    const [formDetalles, setFormDetalles] = useState(initialFormDetalles);
    
    const cuotas = useSelector(state => state.cuota.cuotas);
    console.log("datos", datos)
    const cuotasNoPagadas = useMemo(() => 
      { 
        const cuotasFiltradas =  cuotas.filter(cuota => cuota.estado === 'no_pagada')
        if(datos.proyectista) {
            if(cuotasFiltradas.length === 0) setVerificaciones((prev)=> ({...prev, proyectistaAportes:'sin_pendientes'})) 
            else setVerificaciones((prev)=> ({...prev, proyectistaAportes:'con_pendientes'})) 
        }
        return cuotasFiltradas
        }, 
      [cuotas]
    );
console.log("cuotasNoPagadas",cuotasNoPagadas)
    useEffect(() => {
        dispatch(fetchTipologias());
        dispatch(fetchPersonas());
        dispatch(fetchTiposProyecto());
        dispatch(fetchCategoriasConIndices());
        dispatch(fetchHonorariosUnidadRepetida());
      }, [dispatch]);

    useEffect(() => {
        if(showModal){
        if (areaStatus === 'idle') {
            dispatch(fetchAreasProyecto());
        }
        if (tipoStatus === 'idle') {
            dispatch(fetchTiposProyecto());
        }
        if(currentProyecto){
            const {unidades, valorados, ...proyecto} = currentProyecto
            const unidadesLista = unidades.map(unidad => {
                const tipologia = tipologias.find(tip => tip.id === unidad.id_tipologia)
                
                return {...unidad, tipologia:tipologia, id_categoria:tipologia.id_categoria,
                        id_area:tipologia.id_area
                        }
            })
            const valoradosLista = valorados.map(val => {
                const {ValoradoProyecto, descripcion, id } = val
                return {descripcion:descripcion,
                        id_valorado:id, 
                        id_proyecto:ValoradoProyecto.id_proyecto, 
                        nrovalorado:ValoradoProyecto.nrovalorado,
                        activo:true
                        }
            })
            setFormDetalles({title:"Editar proyecto",headerColor:"#748b39", buttonText:"Actualizar" })
            setDatos({...proyecto, 
                        unidades:unidadesLista,
                        valorados:valoradosLista
            })
            console.log("currentProyecto",currentProyecto)
            if (currentProyecto.foto_plano) {
                setFotoPreview(getUploadUrl(currentProyecto.foto_plano));
            } else {
                setFotoPreview(imgPlaceholder);
                resetFoto()
            }
        } else {
            resetForm();
        }}
    }, [ dispatch, currentProyecto, showModal]);

    const verificarDatosBasicos = () => {
        const observaciones=[]
        if(!datos.id_tipo_proyecto) observaciones.push("Debe completar los campos requeridos.")
    
        }

    const handleSubmit = (estado) => {
        const valoradosLlenados=datos.valorados.every(valorado => valorado.nrovalorado !== "")

        let nuevosErrores = {
            id_tipo_proyecto: datos.id_tipo_proyecto === '',
            descripcion: datos.descripcion === '',
            proyectista: datos.id_proyectista === "",
            inmueble: datos.id_inmueble === "",
            propietario: datos.id_propietario === "",
            valorados:!valoradosLlenados,
            recalcularHonorarios: tipoChanged
        };

        if(estado === "revision") {
            nuevosErrores = {...nuevosErrores, 
                    proyectistaAportes: verificaciones.proyectistaAportes === "con_pendientes",
                    unidades: datos.unidades.length === 0
                }
        }
        if(estado === "visado") {/*..verificaciones para guardar como aprobado*/}

        setErrors(nuevosErrores);
        
        console.log("antes de enviar", nuevosErrores, isModified)
        // Si todos los campos están completos, enviar el formulario
        if(isModified || estado != datos.estado || fotoPlano)
        {if (!Object.values(nuevosErrores).includes(true)) {
            setError('');
            const {unidades, propietario, proyectista, 
                inmueble, tipo, createdAt, 
                updatedAt, ...proyectoData} = datos
            const unidadesData = unidades.map(unidad=>{
                const {tipologia, id_categoria, id_area, ...unidadData} = unidad
                return unidadData
            })
            const formData = new FormData();
            for (const key in proyectoData) {
                console.log("key "+key, proyectoData[key])
                if (typeof proyectoData[key] === 'object' && proyectoData[key] !== null) {
                    // Convertir arrays u objetos en JSON si es necesario
                    formData.append(key, JSON.stringify(proyectoData[key]));
                } else {
                    formData.append(key, proyectoData[key]);
                }
                    console.log("formData "+key,formData)
            }
            if (fotoPlano) {
                formData.append("foto_plano", fotoPlano);
            }
         
          // Agregar datos específicos si es necesario
            if (Array.isArray(unidadesData)) {
                formData.append("unidades", JSON.stringify(unidadesData));
            }
            // formData.set("descripcion", datos.descripcion.toUpperCase())
           // Verificar el contenido de formData
            for (const [key, value] of formData.entries()) {
                console.log(key, value);
            }
        if (currentProyecto) {
                dispatch(modifyProyecto({ id: currentProyecto.id, proyecto: formData })).then(response => {
                    if (response.error || response.payload.error) {
                        Swal.fire({
                            icon: 'error',
                            title: 'Error',
                            text: response.payload.error || response.error.message || 'Error al actualizar el proyecto de construcción.',
                        });
                    } else {
                        handleClose();
                        dispatch(fetchProyectos());
                    }
                });
            } else {
                dispatch(addProyecto(formData)).then(response => {
                    if (response.error || response.payload.error) {
                        Swal.fire({
                            icon: 'error',
                            title: 'Error',
                            text: response.payload.error || response.error.message || 'Error al crear el proyecto de construcción.',
                        });
                    } else {
                        handleClose();
                        dispatch(fetchProyectos());
                    }
                });
            }
        } }else {
            handleClose();
        }
    };

    const resetForm = () => {
        setDatos(initialState);
        setUnidadSelected(initialUnidad);
        setError('');
        setIsModified(false);
        setFormDetalles(initialFormDetalles);
        setVerificaciones(verificacionesInitialForm)
        setErrors(initialErrors)
        setFotoPreview(imgPlaceholder);
        resetFoto()
        
    };

      const handleInputChange = (name, value) => {
        if (name === "id_tipo_proyecto" && value !== datos.id_tipo_proyecto) {
            const tipoProyecto = tiposProyecto.find(tp => `${tp.id}` === `${value}`);
            const nuevosValorados = tipoProyecto && tipoProyecto.valorados.length > 0 ? tipoProyecto.valorados : [];
            setDatos((prevDatos) => {
                const valoradosActuales = prevDatos.valorados;
                // Mapear sobre los nuevos valorados, copiando los datos de los existentes si hay coincidencias de id
                const valoradosToAdd = nuevosValorados.filter(nuevoValorado => {
                 
                    const valoradoExistente = valoradosActuales.find(val => val.id_valorado === nuevoValorado.id);
                    return !valoradoExistente
                }).map(newVal => ({id_valorado:newVal.id, descripcion:newVal.descripcion,
                                    id_proyecto:datos.id, nrovalorado:"", activo:true}));
                const valoradosUpdated = valoradosActuales.map(valoradoActual => {
                    const existeEnNuevos = nuevosValorados.some(nuevoValorado => nuevoValorado.id === valoradoActual.id_valorado);
                     return existeEnNuevos ?  { ...valoradoActual, activo: true } : { ...valoradoActual, activo: false };
                });
                return { ...prevDatos, valorados: [...valoradosUpdated, ...valoradosToAdd], [name]: value, tipo:tipoProyecto };
            });
        
            if (datos.unidades.length > 0) setTipoChanged(true);
        }else{
            setDatos((prevDatos) => ({ ...prevDatos, [name]: value }));
        }
        setIsModified(true);
        setErrors({ ...errors, [name]: value === '' });
    };
    
    const handleChangeValorado = (index, value) => {
        setDatos(prevDatos => {
            const valorados = prevDatos.valorados.map((val) =>
                val.id_valorado === index ? { ...val, nrovalorado: value } : val
            );
            return { ...prevDatos, valorados }; // Actualiza el estado con la nueva copia de 'valorados'
        });
        setIsModified(true);
    };
    const handleSelect = (name, object) => {
        const objetoId=object? object.id : ""
        if(name === 'proyectista') {
            if(objetoId !== ""){
            dispatch(fetchCuotasByAfiliado(objetoId));
            }else{
                setVerificaciones((prev)=> ({...prev, proyectistaAportes:null})) 
            }
           }
        setIsModified(true);
        if(object) setErrors((prev)=>({...prev, [name]:false}));
        setDatos((prevDatos) => ({ ...prevDatos, [`id_${name}`]: objetoId, [name]:object }));
    };

    const handleInputChangeUnidad = (name, value ) => {

        if(name === "id_tipologia" && (unidadSelected.id_categoria === "" || unidadSelected.id_area === "")) {

            const selectedTipologia = tipologias.find(tip => `${tip.id}` === `${value}`);
            setUnidadSelected((prevDatos) => ({ ...prevDatos, [name]: value,
                                                id_categoria: selectedTipologia.categoria.id,
                                                id_area: selectedTipologia.area.id,
             }))
        }
        else if(name === "id_categoria" && value === "") {
            setUnidadSelected((prevDatos) => ({ ...prevDatos, [name]: value,
                                                id_tipologia: "",
                                                id_area: "",
             }))
        }
        else if(name === "id_area" && value === "") {
            setUnidadSelected((prevDatos) => ({ ...prevDatos, [name]: value,
                                                id_tipologia: ""
             }))
        }
        else{ setUnidadSelected((prevDatos) => ({ ...prevDatos, [name]: value }));}
    };

    const esUnidadValida = () => {
       if(unidadSelected.id_tipologia === "") return "Debe seleccionar una tipología.";
       if(unidadSelected.descripcion === "") return "Debe indicar una descripción para la unidad.";
       if(parseInt(unidadSelected.num_unidades) <= 0) return "La cantidad de unidades debe ser un número mayor a 0.";
       if(parseInt(unidadSelected.superficie) <= 0 || unidadSelected.superficie === "") return "La superficie debe ser mayor a 0.";
       return "";
    };

    const handleAddUnidad = () => {
        const mensajeError = esUnidadValida();
        if(mensajeError !== "") setErrorUnidadForm(mensajeError)
       else { 
        const selectedTipologia = tipologias.find(tip => `${tip.id}` === `${unidadSelected.id_tipologia}`);
        const selectedCategoria = categorias.find(cat => `${cat.id}` === `${selectedTipologia.id_categoria}`);
        const indiceCalculo = obtenerIndiceCalculo(unidadSelected.superficie, selectedCategoria);
      
        if(!indiceCalculo) setErrorUnidadForm("Ingrese una superficie válida.")
        {setErrorUnidadForm("")
        setErrors({ ...errors, unidades: false });
        const honorariosUnidad = calculoHonorariosUnidad(indiceCalculo, selectedCategoria, unidadSelected.num_unidades,unidadSelected.superficie);
        const honorariosTotal = datos.honorarios_total+honorariosUnidad
        const superficieTotal = (parseFloat(datos.superficie_total)  || 0) + (parseFloat(unidadSelected.superficie)*unidadSelected.num_unidades)
        const comision = redondearComision(honorariosTotal*porcentaje_comision)
        setDatos((prevDatos)=>{
            const nuevaListaUnidades = [...prevDatos.unidades, {...unidadSelected, indice_calculo:indiceCalculo, honorarios:honorariosUnidad,tipologia:selectedTipologia}]
            return({...prevDatos, honorarios_total:honorariosTotal,
                                    comision_cat:comision,
                                    superficie_total:superficieTotal,
                                    unidades:nuevaListaUnidades
        })})
        
        setIsModified(true);
        setUnidadSelected(prevState => ({ ...prevState, descripcion: "", num_unidades: "1"})); // Mantener valores
        }
   } };

   const obtenerIndiceCalculo  = (superficie, categoria) => {
     const indices = categoria.indicesCalculo

    const indiceEncontrado = indices.find(indice => {
        // Verificar si la superficie está dentro del rango del índice
        return superficie >= indice.superficie_min &&
               (indice.superficie_max === null || superficie <= indice.superficie_max);
    });

    // Devolver el índice de cálculo encontrado o un valor por defecto si no lo encuentra
    return indiceEncontrado ? indiceEncontrado.indice_calculo : null;
};
const recalcularHonorariosTotal = () => {
    let total = 0;

    const unidadesRecalculadas = datos.unidades.map(unidad => {
        const honorariosUnidad = calculoHonorariosUnidad(
            unidad.indice_calculo,
            unidad.tipologia.categoria,
            unidad.num_unidades,
            unidad.superficie
        );
        total += honorariosUnidad;

        return {
            ...unidad, 
            honorarios: honorariosUnidad
        };
    });

    // Luego actualiza 'datos' basándose en 'total'
    setDatos(prevDatos => {
        return {
            ...prevDatos,
            honorarios_total: total,
            comision_cat: redondearComision(total * porcentaje_comision),
            unidades:unidadesRecalculadas
        };
    });
    setErrors({ ...errors, recalcularHonorarios: false });
    setTipoChanged(null)
};
const calculoHonorariosUnidad  = (indiceCalculo, categoria, unidades_repetidas, superficie ) => {
    let total = 0;
    
    const tipoProyecto = datos.id_tipo_proyecto ? tiposProyecto.find(t=> `${t.id}` === `${datos.id_tipo_proyecto}`):null
    const extra = tipoProyecto? categoria.costo_mes * tipoProyecto.adicion_costo_mes : 0
    for (let u = 1; u <= unidades_repetidas; u++) {
        const honorario = honorariosPorUnidadRepetida.find(h=> h.num_unidad_desde <=u && h.num_unidad_hasta >=u)
        console.log(`Costo mensual: ${categoria.costo_mes}, Extra: ${extra}`); // Verifica si los valores son correctos
        const subtotal = (superficie * indiceCalculo * (parseFloat(categoria.costo_mes) + parseFloat(extra)))* (honorario.porcentaje / 100);
        total += subtotal; // Sumar el subtotal al total
        console.log("Unidad "+u+": subtotal: ",subtotal)
        console.log("Datos de calculo ",superficie,indiceCalculo,parseFloat(categoria.costo_mes) + parseFloat(extra),honorario.porcentaje / 100)
    }
    return total
    }

    const handleEditUnidad = (index) => {
        setUnidadSelected(datos.unidades[index]);
        setEditIndex(index);
    };

    const handleSaveEdit = () => {
        const mensajeError = esUnidadValida();
        if(mensajeError !== "") setErrorUnidadForm(mensajeError)
       else { 
        const selectedTipologia = tipologias.find(tip => `${tip.id}` === `${unidadSelected.id_tipologia}`);
        const selectedCategoria = categorias.find(cat => `${cat.id}` === `${selectedTipologia.id_categoria}`);
        const indiceCalculo = obtenerIndiceCalculo(unidadSelected.superficie, selectedCategoria);
     
        if(!indiceCalculo) setErrorUnidadForm("Ingrese una superficie válida.")
        {
        const honorariosUnidad = calculoHonorariosUnidad(indiceCalculo, selectedCategoria, unidadSelected.num_unidades,unidadSelected.superficie);
        setIsModified(true);
        setDatos((prevDatos)=>{
            const updatedUnidades = [...prevDatos.unidades]; // Hacer una copia del array
            const prevUnidad = updatedUnidades[editIndex]
            updatedUnidades[editIndex] = { ...unidadSelected, indice_calculo:indiceCalculo, honorarios:honorariosUnidad,tipologia: selectedTipologia }; // Modificar el elemento específico
            const honorariosTotal = prevDatos.honorarios_total - prevUnidad.honorarios + honorariosUnidad
            const superficieTotal = parseFloat(prevDatos.superficie_total) - (parseFloat(prevUnidad.superficie)*prevUnidad.num_unidades) + (parseFloat(unidadSelected.superficie)*unidadSelected.num_unidades)
            return({...prevDatos, honorarios_total:honorariosTotal,
                                    comision_cat:redondearComision(honorariosTotal*porcentaje_comision),
                                    superficie_total:superficieTotal,
                                    unidades:updatedUnidades
        })})
        setUnidadSelected(prevState => ({ ...prevState, descripcion: "", num_unidades: "1"})); // Mantener valores
        setEditIndex(null);} // Limpiar la edición    
        setErrorUnidadForm("")
    }     
  
    };
    const redondearComision = (comision) => {
        return Math.round(comision * 2) / 2;
    };
    const handleDeleteUnidad = (index) => {
        setDatos((prevDatos) => {
            const updatedUnidades = prevDatos.unidades.filter((_, i) => i !== index); // Eliminar la unidad en el índice específico
            
            // Obtener los valores de la unidad que se eliminará
            const unidadEliminada = prevDatos.unidades[index];
            const honorariosEliminados = unidadEliminada.honorarios || 0;
            const superficieEliminada = unidadEliminada.superficie || 0;
            
            // Actualizar el total de honorarios y superficie
            const honorariosTotal = prevDatos.honorarios_total - honorariosEliminados;
            const superficieTotal = parseFloat(prevDatos.superficie_total) - (parseFloat(superficieEliminada)*unidadEliminada.num_unidades);
    
            return {
                ...prevDatos,
                honorarios_total: honorariosTotal,
                comision_cat: redondearComision(honorariosTotal * porcentaje_comision),
                superficie_total: superficieTotal,
                unidades: updatedUnidades
            };
        });
    };

    const handleFormat = e => {
        const { name, value } = e.target;
        const valueFormatted = name === 'superficie' || name === 'indice_calculo' ? formatearDecimal(value) : value 
        setUnidadSelected((prevDatos) => ({ ...prevDatos, 
            [name]: valueFormatted
        }));
    };

    const areasFiltered = tipologias.filter(tipologia => `${tipologia.id_categoria}` === `${unidadSelected.id_categoria}`) // Filtra por categoría
                    .map(tipologia => tipologia.area); // Extrae el rubro

    // Si necesitas eliminar rubros duplicadas
    const uniqueAreas = areasFiltered.filter((area, index, self) =>
                    index === self.findIndex(a => a.id === area.id) // Compara el 'id' de cada rubro
                );
      
    const tipologiasFiltered = tipologias.filter(t=> (unidadSelected.id_categoria? `${t.id_categoria}`=== `${unidadSelected.id_categoria}` : true) &&
                                                    (unidadSelected.id_area? `${t.id_area}`=== `${unidadSelected.id_area}` : true))
    const listoParaRevision = datos.id_proyectista && verificaciones.proyectistaAportes === "sin_pendientes" 
                                && datos.id_inmueble && datos.id_propietario
    const erroreDatosProyecto = errors.id_tipo_proyecto || errors.descripcion || errors.unidades || errors.recalcularHonorarios
                                
    return (
        <Modal show={showModal} onHide={handleClose} backdrop="static" keyboard={false} size="lg">
            <Modal.Header closeButton style={{ backgroundColor: formDetalles.headerColor, color: 'white' }}>
                <Modal.Title>{formDetalles.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {error && (
                    <Alert variant="danger">
                        {error}
                    </Alert>
                )}
                <Form className='normal-form'>
                <Tabs
                    id="controlled-tab-example"
                    activeKey={key}
                    onSelect={(k) => setKey(k)}
                    className="mb-3"
                    justify
                    >
                    <Tab 
                        eventKey="proyectista" 
                        title={
                            <>
                             {errors.proyectista || errors.proyectistaAportes ? (
                                    <span style={{ color: '#dc3444', marginLeft: '5px' }}>   Proyectista  <i className="bi bi-exclamation-circle"></i></span> 
                                ):(
                                    <>Proyectista</>
                                )}
                            </>
                        }
                    >
                        <SelectAfiliado onSelect={handleSelect} afiliado={datos.proyectista} labelText='Proyectista'/>
                       {verificaciones.proyectistaAportes && 
                       <>
                       {verificaciones.proyectistaAportes === "sin_pendientes"? <div className="alert alert-success" role="alert">
                        El afiliado está al día con sus aportes
                        </div>
                        : <>
                          <div className="alert alert-danger" role="alert">
                        El afiliado tiene aportes pendientes
                        </div>
                        <div className="mt-3">
                            <Form.Label>Cuotas Pendientes</Form.Label>
                            <Table bordered hover size="sm">
                                <thead style={{ backgroundColor: '#b2aea3' }}>
                                <tr>
                                    <th>Cuota</th>
                                    <th>Monto CAT</th>
                                    <th>Monto CAB</th>
                                    <th>Total</th>
                                    <th>Estado</th>
                                </tr>
                                </thead>
                                <tbody>
                                {cuotas.length === 0 ? (
                                    <tr>
                                    <td colSpan="6">No hay cuotas pendientes</td>
                                    </tr>
                                ) : (
                                    cuotas.map((cuota, index) => (
                                    <tr key={cuota.id}>
                                        <td>{cuota.cuota}</td>
                                        <td >
                                        {parseFloat(cuota.monto_cat || 0).toFixed(2)} Bs.
                                        </td>
                                        <td>
                                        {parseFloat(cuota.monto_cab || 0).toFixed(2)} Bs.
                                        </td>
                                        <td >
                                        {(parseFloat(cuota.monto_cat || 0) + parseFloat(cuota.monto_cab || 0)).toFixed(2)} Bs.
                                        </td>
                                        <td>{cuota.estado === 'pagada' ? 'Pagada' : 'No Pagada'}</td>
                                    </tr>
                                    ))
                                )}
                                </tbody>
                            </Table>
                            </div>
                        </>}
                       </>
                        }
                    </Tab>
                    <Tab 
                        eventKey="inmueble" 
                        title={
                            <>
                            {(errors.inmueble || errors.propietario ) ? (
                                    <span style={{ color: '#dc3444', marginLeft: '5px' }}>   Inmueble  <i className="bi bi-exclamation-circle"></i></span> 
                                ):(
                                    <>Inmueble</>
                                )}
                               
                            </>
                        }
                    >
                        <SelectInmueble labelText="Inmueble" onSelect={handleSelect} 
                                        inmuebleSelected={datos.inmueble} 
                                       />
                        <SelectPersona labelText="Propietario" onSelect={handleSelect} 
                                        personaSelected={datos.propietario}
                                     />

                    </Tab>
                    <Tab eventKey="datos_proyecto" title={
                            <>
                             {erroreDatosProyecto ? (
                                    <span style={{ color: '#dc3444', marginLeft: '5px' }}>   Proyecto  <i className="bi bi-exclamation-circle"></i></span> 
                                ):(
                                    <>Proyecto</>
                                )}
                            </>
                        }>
                    <>  
                        <Form.Label>DATOS DEL PROYECTO</Form.Label>
                        <Row xs={1}>
                            <Col md={6} className='col-form'>
                                <Form.Label>Tipo de Proyecto</Form.Label>
                                <Form.Control
                                    as="select"
                                    name="id_tipo_proyecto"
                                    value={datos.id_tipo_proyecto}
                                    onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                                    isInvalid={errors.id_tipo_proyecto}
                                    required
                                >
                                    <option value="" disabled>Selecciona un tipo de proyecto</option>
                                    {tiposProyecto.map(tipo => (
                                    <option key={tipo.id} value={tipo.id}>{tipo.nombre}</option>
                                    ))}
                                </Form.Control>
                                    <Form.Control.Feedback type='invalid'>
                                        Por favor selecciona un tipo de proyecto.
                                    </Form.Control.Feedback>
                                {tipoChanged && <button 
                                        className="button-mine btn-light-blue-outline mt-2" 
                                        onClick={recalcularHonorariosTotal} 
                                        type="button"
                                    >
                                        Recalcular honorarios
                                    </button>}
                                    {errors.recalcularHonorarios && <div className="text-center text-danger">
                                        Debe recalcular los honorarios para guardar el proyecto
                                </div>}

                            </Col>
                            <Col md={6} className="mb-3">
                                <Form.Group controlId="descripcion">
                                    <Form.Label>Descripción</Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        name="descripcion"
                                        placeholder="Descripción del proyecto"
                                        value={datos.descripcion}
                                        onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                                        required
                                        isInvalid={errors.descripcion}
                                        rows={4} // Optional: sets the height of the textarea
                                    />
                                <Form.Control.Feedback type="invalid">
                                    Por favor ingresa una descripción del proyecto.
                                </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col className="mb-3">
                            <Card className="shadow-sm">
                                <Card.Body>
                                    <Row className="mb-2">
                                    <Col><strong>Superficie total:</strong></Col>
                                    <Col className="text-end">{formatNumber(datos.superficie_total)} m2</Col>
                                    </Row>
                                    <Row className="mb-2">
                                    <Col><strong>Total Honorarios:</strong></Col>
                                    <Col className="text-end">{formatNumber(datos.honorarios_total)} Bs</Col>
                                    </Row>
                                    <Row>
                                    <Col><strong>Comisión C.A.T.:</strong></Col>
                                    <Col className="text-end">{formatNumber(datos.comision_cat)} Bs</Col>
                                    </Row>
                                </Card.Body>
                                </Card>
                            </Col>
                            </Row>
                          
                        
                        <Form.Label>UNIDADES</Form.Label>
                        {errorUnidadForm !== "" && <p className="text-danger">{errorUnidadForm}</p>}
                        <Row 
                            xs={1} 
                            md={3} 
                            className={`p-3 border m-1 rounded bg-light ${errors.unidades ? 'border-danger' : ''}`}
                        >
                            <Col className="mb-3 soft-labels">
                                <Form.Group controlId="categoria">
                                    <Form.Label>Categoría</Form.Label>
                                    <Form.Control
                                        as="select"
                                        name="id_categoria"
                                        value={unidadSelected.id_categoria}
                                        onChange={(e)=>handleInputChangeUnidad(e.target.name,e.target.value)}
                                    >
                                        <option value="">Selecciona una categoría</option>
                                        {categorias.map(cate => (
                                            <option key={cate.id} value={cate.id}>{cate.nombre}</option>
                                        ))}
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                            <Col className="mb-3 soft-labels">
                                <Form.Group controlId="rubro">
                                    <Form.Label>Rubro</Form.Label>
                                    <Form.Control
                                        as="select"
                                        name="id_area"
                                        value={unidadSelected.id_area}
                                        onChange={(e)=>handleInputChangeUnidad(e.target.name,e.target.value)}
                                        
                                    >
                                        <option value="">Selecciona un rubro</option>
                                        {uniqueAreas.map(area => (
                                            <option key={area.id} value={area.id}>{area.nombre}</option>
                                        ))}
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                            <Col className="mb-3 soft-labels">
                                <Form.Group controlId="tipologia">
                                    <Form.Label>Tipología</Form.Label>
                                    <Form.Control
                                        as="select"
                                        name="id_tipologia"
                                        value={unidadSelected.id_tipologia}
                                        onChange={(e)=>handleInputChangeUnidad(e.target.name,e.target.value)}
                                        
                                    >
                                        <option value="">Selecciona una tipología</option>
                                        {tipologiasFiltered.map(tipolo => (
                                            <option key={tipolo.id} value={tipolo.id}>{tipolo.descripcion}</option>
                                        ))}
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                            <Col className="mb-3 soft-labels">
                                <Form.Group controlId="descripcion">
                                    <Form.Label>Descripción de la unidad</Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        name="descripcion"
                                        placeholder="Descripción de la unidad"
                                        value={unidadSelected.descripcion}
                                        onChange={(e) => handleInputChangeUnidad(e.target.name, e.target.value)}
                                        rows={4} // Optional: sets the number of visible lines in the textarea
                                    />
                                </Form.Group>
                            </Col>
                            <Col className="mb-3 soft-labels">
                                <Form.Group controlId="num_unidades">
                                    <Form.Label>Cantidad de Unidades</Form.Label>
                                    <Form.Control
                                        type="number"
                                        step="1"
                                        placeholder="0"
                                        name="num_unidades"
                                        value={unidadSelected.num_unidades}
                                        onChange={(e)=>handleInputChangeUnidad(e.target.name,e.target.value)}
                                    />
                                </Form.Group>
                            </Col>
                            <Col className="mb-3 soft-labels">
                                <Form.Group controlId="superficie">
                                    <Form.Label>Superficie (m2)</Form.Label>
                                    <Form.Control
                                        type="number"
                                        step="0.01"
                                        placeholder="0,00"
                                        name="superficie"
                                        value={unidadSelected.superficie}
                                        onChange={(e)=>handleInputChangeUnidad(e.target.name,e.target.value)}
                                        onBlur={handleFormat}
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={12} className="d-flex justify-content-end mt-3">
                                {editIndex === null ? (
                                    // Botón para agregar una nueva unidad
                                    <button 
                                        className="button-mine btn-mine-primary-outline" 
                                        onClick={handleAddUnidad} 
                                        type="button"
                                    >
                                        <i className="bi bi-plus-circle"></i> Agregar Unidad
                                    </button>
                                ) : (
                                    // Botón para guardar la edición
                                    <button 
                                        className="button-mine btn-light-blue-outline" 
                                        onClick={handleSaveEdit} 
                                        type="button"
                                    >
                                        <i className="bi bi-save"></i> Guardar Edición
                                    </button>
                                )}
                            </Col>
                        </Row>

                        {/* Tabla para mostrar las unidades registradas */}
                        <div className='table-container'>
                        <Table 
                            striped 
                            bordered 
                            hover 
                            className={`mt-4`}
                        >
                            <thead className='text-center align-middle'>
                                <tr>
                                    <th style={{width:"23%"}}>Tipología</th>
                                    <th>Descripción</th>
                                    <th>Cantidad</th>
                                    <th>Superficie/unidad (m2)</th>
                                    <th>Índice de cálculo</th>
                                    <th>Subtotal Honorarios (Bs)</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                            {Array.isArray(datos.unidades) && !errors.unidades?
                            (datos.unidades.map((unidad, index) => {
                                console.log("")
                                const backColor = `${index}` === `${editIndex}`? "#c4e1f5":null
                                return(
                                <tr key={index} style={{backgroundColor:backColor}}>
                                    <td style={{backgroundColor:backColor}}>{`${unidad.tipologia?.categoria.nombre} CATEGORÍA / ${unidad.tipologia?.area.nombre} / ${unidad.tipologia?.descripcion}`}</td>
                                    <td style={{backgroundColor:backColor}}>{unidad.descripcion}</td>
                                    <td style={{backgroundColor:backColor}}>{unidad.num_unidades}</td>
                                    <td style={{backgroundColor:backColor}}>{formatNumber(unidad.superficie)}</td>
                                    <td style={{backgroundColor:backColor}}>{formatNumber(unidad.indice_calculo)}</td>
                                    <td style={{backgroundColor:backColor}}>{formatNumber(unidad.honorarios)}</td>
                                    <td style={{backgroundColor:backColor}}>
                                    {!backColor && <>
                                        <button 
                                            type="button"
                                            onClick={() => handleEditUnidad(index)}
                                            className="icon-button btn-transparent"
                                            aria-label="Editar unidad">
                                                <i className="bi bi-pencil"></i>
                                        </button>
                                        <button 
                                            type="button"
                                            onClick={() => handleDeleteUnidad(index)}
                                            className="icon-button btn-transparent"
                                            aria-label="Eliminar unidad">
                                                <i className="bi bi-trash"></i>
                                        </button>
                                    </>}
                                    
                                    </td>
                                </tr>
                            )})):(
                                <tr>
                                <td colSpan="7" className="text-center text-danger">
                                    No hay unidades registradas.
                                </td>
                            </tr>
                            )}

                            </tbody>
                        </Table>
                        {errors.unidades && (
                            <p className="text-danger mt-2">
                                Debe registrar al menos una unidad antes de enviar a revision el proyecto.
                            </p>
                        )}
                        </div>
                    </>
                    </Tab>
                    <Tab 
                        eventKey="Documentos" 
                        title={
                            <>
                            {(errors.documentos || errors.valorados ) ? (
                                    <span style={{ color: '#dc3444', marginLeft: '5px' }}>   Respaldos  <i className="bi bi-exclamation-circle"></i></span> 
                                ):(
                                    <>Respaldos</>
                                )}
                               
                            </>
                        }
                    >
                           <Row xs={1} md={1} lg={2}>
             
             <Col className='col-form'>
                 <Form.Label>Plano</Form.Label>
                 <Form.Control
                     type="file"
                     name="foto_plano"
                     isInvalid={!isFotoValid}
                     onChange={(e) => handleFotoChange(e.target.files[0])}
                     disabled={false}
                 />
                  <Form.Control.Feedback type="invalid">
                     Por favor selecciona una imagen válida.
                  </Form.Control.Feedback>
                  </Col>
                  <Col className='col-form'>
                 <div className="d-flex justify-content-center mb-4">
                     <div className="text-center">
                         {fotoPreview && (
                             <div>
                                 <div>Foto del plano</div>
                                 <Image 
                                     src={!currentProyecto && fotoPlano && isFotoValid ? URL.createObjectURL(fotoPlano) : fotoPreview} 
                                     alt="Foto previa" 
                                     className="img-fluid mb-2 mt-3"
                                     style={{ maxHeight: '200px', objectFit: 'cover' }}
                                 />
                             </div>
                         )}
                     </div>
                     <div className="text-center">
                         {(currentProyecto && fotoPlano && isFotoValid) && (
                             <>
                                 <div>Nueva foto</div>
                                 <Image
                                     className="img-fluid mb-2 mt-3"
                                     style={{ maxHeight: '200px', objectFit: 'cover' }}
                                     src={URL.createObjectURL(fotoPlano)}
                                 />
                             </>
                         )}
                     </div>
                 </div>
                 </Col>
                  </Row>
                     <Row className={`${errors.valorados ? 'border-danger' : ''}`}>
                           
                     <Form.Label>CONTABILIDAD</Form.Label>
                           <Col className="mb-3">
                                <Form.Group>
                                <Form.Label>Recibo</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder={`Número de recibo`}
                                        value={datos.id_recibo}
                                        onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                                        required
                                    />
                                </Form.Group>
                                </Col>
                        </Row>
                        
                     <Form.Label>VALORADOS</Form.Label>
                     <Row className={`${errors.valorados ? 'border-danger' : ''}`}>
                            {datos.valorados?.length>0 ? 
                            <>
                            {datos.valorados.filter(val=>val.activo===true).map((val,index)=> (
                                <Col className="mb-3" key={`valorado_${index}`}>
                                <Form.Group>
                                    <Form.Label>{formatoOracion(val.descripcion)}</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder={`Número de ${val.descripcion.toLowerCase()}`}
                                        value={val.nrovalorado}
                                        onChange={(e)=>handleChangeValorado(val.id_valorado,e.target.value)}
                                        required
                                    />
                                </Form.Group>
                                </Col>
                            ))}
                            {errors.valorados && <div className="text-center text-danger">
                                    Todos los valorados utilizados en este proyecto deben estar registrados para mandar a revisión.
                            </div>}
                            </>: (
                                <div>
                                Debe seleccionar el tipo de proyecto para registrar los valorados correspondientes
                        </div>
                            )
                        }
                        </Row>
                    </Tab>
                    <Tab eventKey="revision" title="Vista general">
                    <h4 className="text-center">Vista del Proyecto</h4>
                    <Row className="mb-4">
                        <Col>
                        <Card className="border-0 shadow-sm">
                            <Card.Body className="p-2" style={{ fontSize: '0.9rem' }}>
                                <Card.Title className="mb-2" style={{ fontSize: '1rem' }}>Detalles del Proyecto</Card.Title>
                                <ListGroup variant="flush">
                                    <ListGroup.Item className="p-1 d-flex">
                                        <div style={{ minWidth: '145px', fontWeight: 'bold' }}>Núm. de Visado:</div>
                                        <div>{datos.num_visado}</div>
                                    </ListGroup.Item>
                                    <ListGroup.Item className="p-1 d-flex">
                                        <div style={{ minWidth: '145px', fontWeight: 'bold' }}>Estado:</div>
                                        <div>{datos.estado.toUpperCase()}</div>
                                    </ListGroup.Item>
                                    <ListGroup.Item className="p-1 d-flex">
                                        <div style={{ minWidth: '145px', fontWeight: 'bold' }}>Proyectista:</div>
                                        <div>{datos.proyectista && (`${fullNamePersona(datos.proyectista.persona)} C.I.:${datos.proyectista.persona.ci}`)}</div>
                                    </ListGroup.Item>
                                    <ListGroup.Item className="p-1 d-flex">
                                        <div style={{ minWidth: '145px', fontWeight: 'bold' }}>Propietario:</div>
                                        <div>{datos.propietario && (`${fullNamePersona(datos.propietario)} C.I.:${datos.propietario.ci}`)}</div>
                                    </ListGroup.Item>
                                    <ListGroup.Item className="p-1 d-flex">
                                        <div style={{ minWidth: '145px', fontWeight: 'bold' }}>Cod Catastral:</div>
                                        <div>{datos.inmueble?.cod_catastral}</div>
                                    </ListGroup.Item>
                                    <ListGroup.Item className="p-1 d-flex">
                                        <div style={{ minWidth: '145px', fontWeight: 'bold' }}>Zona:</div>
                                        <div>{datos.inmueble?.zona?.nombre}</div>
                                    </ListGroup.Item>
                                    <ListGroup.Item className="p-1 d-flex">
                                        <div style={{ minWidth: '145px', fontWeight: 'bold' }}>Tipo de proyecto:</div>
                                        <div>{datos.tipo?.nombre}</div>
                                    </ListGroup.Item>
                                    <ListGroup.Item className="p-1 d-flex">
                                        <div style={{ minWidth: '145px', fontWeight: 'bold' }}>Descripción:</div>
                                        <div>{datos.descripcion}</div>
                                    </ListGroup.Item>
                                    <ListGroup.Item className="p-1 d-flex">
                                        <div style={{ minWidth: '145px', fontWeight: 'bold' }}>Superficie total:</div>
                                        <div>{formatNumber(datos.superficie_total)} m²</div>
                                    </ListGroup.Item>
                                    <ListGroup.Item className="p-1 d-flex">
                                        <div style={{ minWidth: '145px', fontWeight: 'bold' }}>Honorarios total:</div>
                                        <div>{formatNumber(datos.honorarios_total)} Bs.</div>
                                    </ListGroup.Item>
                                    <ListGroup.Item className="p-1 d-flex">
                                        <div style={{ minWidth: '145px', fontWeight: 'bold' }}>Comisión C.A.T.:</div>
                                        <div>{formatNumber(datos.comision_cat)} Bs.</div>
                                    </ListGroup.Item>
                                </ListGroup>
                            </Card.Body>
                        </Card>

                        </Col>
                    </Row>
                    <Row className="mb-4">
                        <Col>
                        <Card className="border-0 shadow-sm">
                        <Card.Body className="p-2">
                            <Card.Title className="mb-2" style={{ fontSize: '1rem' }}>Unidades</Card.Title>
                            {datos.unidades.length > 0 ? (
                                <Table responsive bordered hover size="sm" className="mt-2" style={{ fontSize: '0.85rem' }}>
                                 <thead className='text-center align-middle'>
                                        <tr>
                                            <th>N°</th>
                                            <th>Tipología</th>
                                            <th>Descripción</th>
                                            <th>Cantidad</th>
                                            <th>Superficie/unidad (m2)</th>
                                            <th>Honorarios Subtotal (Bs)</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {datos.unidades.map((unidad,index) => (
                                            <tr key={unidad.id}>
                                                <td>{index+1}</td>
                                                <td>{`${unidad.tipologia?.categoria.nombre} CATEGORÍA / ${unidad.tipologia?.area.nombre} / ${unidad.tipologia?.descripcion}`}</td>
                                                <td>{unidad.descripcion}</td>
                                                <td>{unidad.num_unidades}</td>
                                                <td>{formatNumber(unidad.superficie)}</td>
                                                <td>{formatNumber(unidad.honorarios)}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            ) : (
                                <p style={{ fontSize: '0.9rem', color: '#6c757d' }}>No hay unidades asociadas al proyecto.</p>
                            )}

                        </Card.Body>
                    </Card>

                        </Col>
                    </Row>  
                    <Row className="mb-4">
                        <Col>
                        <Card className="border-0 shadow-sm">
                        <Card.Body className="p-2">
                            <Card.Title className="mb-2" style={{ fontSize: '1rem' }}>Información Adicional</Card.Title>
                            <ListGroup variant="flush">
                            <ListGroup.Item className="p-1">
                                    <strong>Usuario Creador:</strong> {nombre}
                                </ListGroup.Item>
                                <ListGroup.Item className="p-1">
                                    <strong>Gerente Aprobador:</strong> {datos.gerenteAprobador?.nombre}
                                </ListGroup.Item>
                               
                            </ListGroup>
                        </Card.Body>
                    </Card>

                        </Col>
                    </Row>

                 
                                    </Tab>
                    </Tabs>
                    <div className="d-flex justify-content-end mt-5">
                        <button className="button-mine btn-cancel-outline" type="button" onClick={resetForm}>
                            Limpiar
                        </button>
                        <button className="button-mine btn-cancel-outline" type="button" onClick={handleClose}>
                            Cancelar
                        </button>
                        <button className="button-mine btn-light-blue-outline" type="button" onClick={() => handleSubmit("borrador")} disabled={!isModified}>
                            Pre-Guardar
                        </button>
                        <button className="button-mine btn-mine-primary-outline" type="button" onClick={() => handleSubmit("revision")} disabled={!isModified}>
                            Guardar para Revisión
                        </button>
                        {rolActivo === "gerente" && <button className="button-mine btn-mine-primary-outline" type="button" onClick={() => handleSubmit("visado")} disabled={!isModified}>
                            Guardar Proyecto Aprobado
                        </button>}
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default FormProyecto;
