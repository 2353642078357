import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addIngresoVarios, modifyIngresoVarios, fetchIngresosVarios } from '../../reducers/ingresosVarios_slice';
import { fetchPlanCuentas } from '../../reducers/planCuentas_slice';  // Para obtener las cuentas del plan de cuentas
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Swal from 'sweetalert2';

const initialState = {
    descripcion: "",
    codigo_cuenta: ""
};

const IngresosVariosForm = ({ currentIngresoVario, showModal, handleClose }) => {
    const [datos, setDatos] = useState(initialState);
    const [isModified, setIsModified] = useState(false);

    const dispatch = useDispatch();
    const cuentas = useSelector(state => state.planCuentas.planCuentas || []);  // Usamos las cuentas del plan de cuentas
    const requestStatus = useSelector(state => state.ingresosVarios.statusSend);

    useEffect(() => {
        if (currentIngresoVario) {
            setDatos(currentIngresoVario);
            setIsModified(false);
        } else {
            resetForm();
        }
    }, [currentIngresoVario]);

    // Obtener cuentas de tipo DETALLE cuando el componente se monte
    useEffect(() => {
        dispatch(fetchPlanCuentas());  // Cargar las cuentas al montar el componente
    }, [dispatch]);

    const handleSubmit = e => {
        e.preventDefault();

        if (isModified) {
            if (currentIngresoVario) {
                dispatch(modifyIngresoVarios({ id: currentIngresoVario.id, ingresoVarios: datos })).then(response => {
                    if (response.error || response.payload.error) {
                        Swal.fire({
                            icon: 'error',
                            title: 'Error',
                            text: 'Error al actualizar el ingreso vario.',
                        });
                    } else {
                        handleClose();
                        resetForm();
                        dispatch(fetchIngresosVarios());
                    }
                });
            } else {
                dispatch(addIngresoVarios(datos)).then(response => {
                    if (response.error || response.payload.error) {
                        Swal.fire({
                            icon: 'error',
                            title: 'Error',
                            text: 'Error al crear el ingreso vario.',
                        });
                    } else {
                        handleClose();
                        resetForm();
                        dispatch(fetchIngresosVarios());
                    }
                });
            }
        } else {
            handleClose();
            resetForm();
        }
    };

    const resetForm = () => {
        setDatos(initialState);
        setIsModified(false);
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setDatos(prevDatos => ({ ...prevDatos, [name]: value }));
        setIsModified(true);
    };

    return (
        <Modal show={showModal} onHide={() => { handleClose(); resetForm(); }} backdrop="static" keyboard={false} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>{currentIngresoVario ? "Editar Ingreso Vario" : "Nuevo Ingreso Vario"}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmit}>
                    <Form.Group>
                        <Form.Label>Descripción</Form.Label>
                        <Form.Control
                            type="text"
                            name="descripcion"
                            value={datos.descripcion}
                            onChange={handleInputChange}
                            required
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Código Cuenta</Form.Label>
                        <Form.Control
                            as="select"
                            name="codigo_cuenta"
                            value={datos.codigo_cuenta}
                            onChange={handleInputChange}
                            required
                        >
                            <option value="">Seleccione una Cuenta</option>
                            {cuentas
                                .filter(cuenta => cuenta.tipo_cuenta === 'DETALLE')  // Filtrar solo cuentas de tipo DETALLE
                                .map(cuenta => (
                                    <option key={cuenta.id} value={cuenta.codigo_cuenta}>
                                        {cuenta.codigo_cuenta} - {cuenta.nombre_cuenta}
                                    </option>
                                ))}
                        </Form.Control>
                    </Form.Group>
                    <div className="form-actions">
                        <Button variant="secondary" onClick={() => { handleClose(); resetForm(); }}>
                            Cancelar
                        </Button>
                        <Button variant="primary" type="submit" disabled={requestStatus === 'loading'}>
                            {currentIngresoVario ? 'Actualizar' : 'Crear'}
                        </Button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default IngresosVariosForm;
