import React, { useEffect, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { jsPDF } from 'jspdf';

const EpsonPrintModal = ({ show, handleClose, content }) => {
    const [pdfUrl, setPdfUrl] = useState(null);

    useEffect(() => {
        if (show) {
            generatePdf();
        }
    }, [show, content]);

    const generatePdf = () => {
        // Establecer tamaño personalizado para la impresora EPSON TM-U220 (58mm de ancho aprox)
        const doc = new jsPDF('p', 'mm', [80, 200]); // Ancho de 80mm y largo ajustable

        const marginLeftRight = 5; // Márgenes pequeños
        const marginTop = 5; // Margen superior
        const lineHeight = 4; // Altura de línea
        let currentHeight = marginTop; // Controlar la altura actual

        // Cabecera
        doc.setFontSize(10);
        doc.text("CAT Colegio de Arquitectos Tarija", doc.internal.pageSize.getWidth() / 2, currentHeight, { align: 'center' });
        currentHeight += lineHeight;
        doc.text("=================================", doc.internal.pageSize.getWidth() / 2, currentHeight, { align: 'center' });
        currentHeight += lineHeight * 2; // Saltar dos líneas

        // Detalle (contenido dinámico)
        doc.setFontSize(8);
        content.detalle.forEach((linea) => {
            doc.text(linea, marginLeftRight, currentHeight);
            currentHeight += lineHeight;
        });

        // Pie
        currentHeight += lineHeight; // Agregar espacio antes del pie
        doc.text("__________________________________________", doc.internal.pageSize.getWidth() / 2, currentHeight, { align: 'center' });
        doc.setFontSize(7);
        currentHeight += lineHeight;
        doc.text("Calle La Madrid # 615 casi Esq. Junín", doc.internal.pageSize.getWidth() / 2, currentHeight, { align: 'center' });
        currentHeight += lineHeight/2;
        doc.text(`Impreso el: ${new Date().toLocaleString()}`, doc.internal.pageSize.getWidth() / 2, currentHeight,{ align: 'center' });

        // Generar el PDF como blob y obtener URL
        const pdfBlob = doc.output('blob');
        const pdfUrl = URL.createObjectURL(pdfBlob);
        setPdfUrl(pdfUrl);
    };

    return (
        <Modal show={show} onHide={handleClose} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Proforma</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {pdfUrl && (
                    <iframe src={pdfUrl} width="100%" height="500px" title="Proforma"></iframe>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Cerrar
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default EpsonPrintModal;
