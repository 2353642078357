// src/components/Footer.js
import React from 'react';

import '../../styles/Footer.scss';
const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-content">
          <p>&copy; 2024 Colegio de Arquitectos de Tarija. Todos los derechos reservados.</p>
          <div className="footer-links">
            <a href="/privacy-policy">Política de Privacidad</a>
            <a href="/terms-of-service">Términos de Servicio</a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;