import React, { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { fetchAfiliados } from '../../reducers/afiliado_slice';
import Table from 'react-bootstrap/Table';
import { fetchCuotas } from '../../reducers/cuota_slice';
import PagoCuotasModal from './PagoCuotasModal';
import CustomPrintModal from '../UI/CustomPrintModal';
import VistaPagoCuota from './VistaPagoCuota';
import * as XLSX from 'xlsx';

const ListaAfiliados = () => {
  const { codigoFuncionalidad } = useParams();
  console.log("funcionalidad=", codigoFuncionalidad);
  const dispatch = useDispatch();

  const afiliadoState = useSelector(state => state.afiliado || { afiliados: [] });
  const afiliados = afiliadoState.afiliados || [];
  const cuotas = useSelector(state => state.cuota.cuotas || []);

  const [showModal, setShowModal] = useState(false);
  const [currentAfiliado, setCurrentAfiliado] = useState(null);
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [printContent, setPrintContent] = useState({ head: [], body: [] });
  const [searchTerm, setSearchTerm] = useState('');
  const [refreshFlag, setRefreshFlag] = useState(false);
  const [showVistaPagoCuota, setShowVistaPagoCuota] = useState(false);
  const [depositoId, setDepositoId] = useState(null);
  const [numCuotasFilter, setNumCuotasFilter] = useState('Todos');
  const [filterType, setFilterType] = useState('<='); 

  useEffect(() => {
    dispatch(fetchAfiliados());
    dispatch(fetchCuotas());
  }, [dispatch]);

  useEffect(() => {
    if (refreshFlag) {
      dispatch(fetchAfiliados());
      dispatch(fetchCuotas());
      setRefreshFlag(false);
    }
  }, [dispatch, refreshFlag]);

  const filteredAfiliados = useMemo(() => {
    return afiliados.filter(afiliado => {
      const cuotasNoPagadas = cuotas.filter(cuota => cuota.id_afiliado === afiliado.id && cuota.estado === 'no_pagada').length;
      if (numCuotasFilter === 'Todos') return true;
      if (filterType === '<=') return cuotasNoPagadas <= parseInt(numCuotasFilter);
      return cuotasNoPagadas === parseInt(numCuotasFilter);
    });
  }, [afiliados, cuotas, numCuotasFilter, filterType]);

  const handlePagoCuotas = afiliado => {
    setCurrentAfiliado(afiliado);
    setShowModal(true);
  };

  const handleImprimirEstadoCuenta = afiliado => {
    alert(`Imprimiendo estado de cuenta para: ${afiliado.persona.nombre} ${afiliado.persona.apellido1}`);
  };

  const handleClose = () => {
    setShowModal(false);
    setCurrentAfiliado(null);
    setRefreshFlag(true);
  };

  const openVistaPagoCuotaModal = (idDeposito, idAfiliado) => {
    setDepositoId(idDeposito);
    setCurrentAfiliado(afiliados.find(af => af.id === idAfiliado));
    setShowVistaPagoCuota(true);
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleNumCuotasChange = (event) => {
    setNumCuotasFilter(event.target.value);
  };

  const handleFilterTypeChange = (event) => {
    setFilterType(event.target.value);
  };

  const sortedAfiliados = useMemo(() => {
    return filteredAfiliados.filter(afiliado => {
      const searchText = `${afiliado.persona.apellido1} ${afiliado.persona.apellido2} ${afiliado.persona.nombre} ${afiliado.persona.ci} ${afiliado.situacion.nombre || 'N/A'}`.toLowerCase();
      return searchText.includes(searchTerm.toLowerCase());
    });
  }, [filteredAfiliados, searchTerm]);

  const getPreviewContent = () => {
    const content = {
      head: [['N°', 'N° Registro', 'Ap. Paterno', 'Ap. Materno', 'Nombre', 'C.I.', 'Situación', 'Cuotas No Pagadas']],
      body: sortedAfiliados.map((afiliado, index) => {
        const cuotasNoPagadas = cuotas.filter(cuota => cuota.id_afiliado === afiliado.id && cuota.estado === 'no_pagada').length;
        return [
          index + 1,
          afiliado.num_reg,
          afiliado.persona.apellido1,
          afiliado.persona.apellido2,
          afiliado.persona.nombre,
          afiliado.persona.ci,
          afiliado.situacion.nombre || 'N/A',
          cuotasNoPagadas
        ];
      })
    };
    return content;
  };

  const handleExport = () => {
    const exportData = sortedAfiliados.map((afiliado, index) => {
      const cuotasNoPagadas = cuotas.filter(cuota => cuota.id_afiliado === afiliado.id && cuota.estado === 'no_pagada').length;
      return {
        'N°': index + 1,
        'N° Registro': afiliado.num_reg,
        'Ap. Paterno': afiliado.persona.apellido1,
        'Ap. Materno': afiliado.persona.apellido2,
        'Nombre': afiliado.persona.nombre,
        'C.I.': afiliado.persona.ci,
        'Situación': afiliado.situacion.nombre || 'N/A',
        'Cuotas No Pagadas': cuotasNoPagadas
      };
    });

    const worksheet = XLSX.utils.json_to_sheet(exportData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Afiliados");
    XLSX.writeFile(workbook, "afiliados.xlsx");
  };

  return (
    <div className="list-container list1">
      <h1 className='table-title'>LISTA DE AFILIADOS</h1>
      <div className="list-content">
        <div className='list-controls'>
          <div className="col-md-3 mt-2">
            <input
              type="text"
              placeholder="Nombre del afiliado o situación del afiliado"
              className="form-control"
              value={searchTerm}
              onChange={handleSearch}
            />
          </div>
          <div className="col-md-2 mt-2">
            <select className="form-control" value={filterType} onChange={handleFilterTypeChange}>
              <option value="<=">Menor o igual que</option>
              <option value="=">Igual a</option>
            </select>
          </div>
          <div className="col-md-2 mt-2">
            <select className="form-control" value={numCuotasFilter} onChange={handleNumCuotasChange}>
              <option value="Todos">Todos los afiliados</option>
              <option value="1">1 cuota</option>
              <option value="2">2 cuotas</option>
              <option value="3">3 cuotas</option>
              <option value="4">4 cuotas</option>
              <option value="5">5 cuotas</option>
            </select>
          </div>
        </div>
        <div className="list-container-header">
          <div className="right-buttons">
            <button title="Imprimir" onClick={() => setShowPreviewModal(true)} className="icon-button btn-transparent">
              <i className="bi bi-printer"></i>
            </button>
            <button title="Exportar a Excel" onDoubleClick={handleExport} className="icon-button btn-transparent">
              <i className="bi bi-file-spreadsheet"></i>
            </button>
          </div>
        </div>
        {sortedAfiliados.length === 0 ? (
          <p>No hay afiliados con cuotas no pagadas.</p>
        ) : (
          <div className='table-container'>
            <Table bordered hover size='sm'>
              <thead style={{backgroundColor:"#b2aea3"}}>
                <tr>
                  <th>N°</th>
                  <th>N° Registro</th>
                  <th>Apellido Paterno</th>
                  <th>Apellido Materno</th>
                  <th>Nombre</th>
                  <th>C.I.</th>
                  <th>Situación</th>
                  <th>Cuotas No Pagadas</th>
                  <th>Acciones</th>
                </tr>
              </thead>
              <tbody>
                {sortedAfiliados.map((afiliado, index) => {
                  const cuotasNoPagadas = cuotas.filter(cuota => cuota.id_afiliado === afiliado.id && cuota.estado === 'no_pagada').length;
                  return (
                    <tr key={afiliado.id}>
                      <td>{index + 1}</td>
                      <td>{afiliado.num_reg}</td>
                      <td>{afiliado.persona.apellido1}</td>
                      <td>{afiliado.persona.apellido2}</td>
                      <td>{afiliado.persona.nombre}</td>
                      <td>{afiliado.persona.ci}</td>
                      <td>{afiliado.situacion.nombre || 'N/A'}</td>
                      <td>{cuotasNoPagadas}</td>
                      <td>
                        {codigoFuncionalidad === "1" && (
                          <button
                            className="icon-button btn-secondary-outline"
                            title="Pagar cuotas"
                            onClick={() => handlePagoCuotas(afiliado)}
                          >
                            <i className="bi bi-cash-stack"></i>
                          </button>
                        )}
                        {codigoFuncionalidad === "2" && (
                          <button
                            className="icon-button btn-secondary-outline"
                            title="Imprimir estado de cuenta"
                            onClick={() => handleImprimirEstadoCuenta(afiliado)}
                          >
                            <i className="bi bi-printer"></i>
                          </button>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        )}
        {showModal && currentAfiliado && (
          <PagoCuotasModal 
            show={showModal} 
            handleClose={handleClose} 
            afiliado={currentAfiliado} 
            openVistaPagoCuotaModal={openVistaPagoCuotaModal}
          />
        )}
        {showVistaPagoCuota && currentAfiliado && (
          <VistaPagoCuota
            show={showVistaPagoCuota}
            handleClose={() => setShowVistaPagoCuota(false)}
            afiliado={currentAfiliado}
            depositoId={depositoId}
          />
        )}
        <CustomPrintModal
          show={showPreviewModal}
          handleClose={() => setShowPreviewModal(false)}
          title1="LISTA DEL ESTADO DE CUENTAS DE AFILIADOS"
          title2={`${
            numCuotasFilter === 'Todos' ? 'TODOS' : `${filterType === '<=' ? 'MENOR O IGUAL A' : 'IGUAL A'} ${numCuotasFilter}`
          } CUOTAS VENCIDAS`}
          content={getPreviewContent()}
        />
      </div>
    </div>
  );
};

export default ListaAfiliados;
