const adminSistemaMenu = [
  {
    title: "Administración Sistema",
    tipo: "dropdown",
    sections: [{
      name: "",
      options: [
        { name: "Gestión Países", path: "/admin/paises" },
        { name: "Gestión Lugares", path: "/admin/lugares" },
        { name: "Gestión Instituciones", path: "/admin/instituciones" },
        { name: "Gestión Personas", path: "/admin/personas" },
        { name: "Gestión Requisitos", path: "/admin/requisitos" },
        { name: "Gestión Usuarios", path: "/admin/instituciones" },
        { name: "Gestión Roles", path: "/admin/instituciones" },
        { name: "Gestión Asignar Roles a Usuarios", path: "/admin/instituciones" },
        { name: "Backup del Sistema", path: "/admin/instituciones" },
      ],
    }]
  },
  { title: "Módulo Caja",
    tipo: "dropdown",
    sections: [{
      name: "",
      options: [
        { name: "Apertura diaria de Caja", path: "/admin/caja-diarias"},
        { name: "Venta de valorados", path: "/admin/valorado-vens" },
        { name: "Cobro de cuotas de afiliados", path:  `/admin/${encodeURIComponent("pago-cuotas")}/1` },
        { name: "Otros ingresos", path: "/admin/otros-ingresos"  },
        { name: "Registro de Compra de Valorados", path: "/admin/valorado-dets" },
        { name: "Lista de Otros Ingresos", path: "/admin/ingresos-varios"},
  
      ]
    }]
   },
  { title: "Módulo Afiliación",
    tipo: "dropdown",
    sections: [{
    name: "",
      options: [
        { name: "Afiliados", path: "/admin/afiliados" },
        //{ name: "Inscripciones", path: "/admin/inscripciones" },
        { name: "Seguimiento de situación de afiliados", path: "/admin/seguimiento-situacion-afiliados" },
        { name: "Situaciones", path: "/admin/situaciones" },

      ],   
    }]
  },
  { title: "Gestion de Cuotas", 
    tipo: "dropdown",
    sections: [{
      name: "",
    options: [
      { name: "Estado de cuentas", path:  `/admin/${encodeURIComponent("pago-cuotas")}/2` },
      { name: "Seguimiento de Pagos", path: "/admin/lugares" },
      { name: "Generación Reportes de Cuotas", path: "/admin/instituciones" },

    ],
  }]
  },
  { title: "Gestión de Valorados", 
    tipo: "dropdown",
    sections: [{
      name: "",
      options: [
        { name: "Registro de productos de valorados", path: "/admin/afiliados" },
        { name: "Gestión de ventas", path: "/admin/lugares" },
        { name: "Registro de compra de Valorados", path: "/admin/valorado-dets" },
        { name: "Gestión Valorados", path: "/admin/valorados" },
      ],
    }]
  },
  { title: "Propiedad intelectual", 
    tipo: "dropdown",
    sections: [{
      name: "Proyectos",
      options: [
        { name: "Proyectos", path: "/admin/proyectos" },
        { name: "Tipos de proyecto", path: "/admin/tipos-proyecto" },
        { name: "Tipologías por categoría y rubro", path: "/admin/tipologias" },  
        { name: "Rubros", path: "/admin/rubros-proyecto" },
        { name: "Categorías", path: "/admin/categorias" },
      ],
    },
    // {
    //   name: "Propiedad intelectual",
    //   options: [
    //     { name: "Registro de propiedad intelectual", path: "/admin/proyectos" },
    //     { name: "Resellado y modificación de inscripciones de Propiedad Intelectual", path: "/admin/proyectos" },
    //     { name: "Generación de recibos y contabilización", path: "/admin/proyectos-construccion" },
    //   ],
    // },
    {
      name: "Bienes Inmuebles",
      options: [
        { name: "Inmuebles", path: "/admin/inmuebles", icon:'bi bi-house' },
        //{ name: "Propietarios", path: "/admin/propietarios" },
      ],
    },]
    
   

  },
  { title: "Contabilidad", 
    tipo: "dropdown",
    sections: [{
      name: "",
      options: [
      { name: "Gestión de caja", path: "/admin/tipos-proyecto" },
      { name: "Gestión de Comprobantes", path: "/admin/comprobantes" },
      { name: "Balance General", path: "/admin/balance-general" },
      { name: "Gestión Otros Ingresos", path: "/admin/otros-ingresos" },
      { name: "Gestión General de la contabilidad", path: "/admin/proyectos-construccion" },
      { name: "Gestión de Plan de Cuentas", path: "/admin/plan-cuentas" },
      { name: "Cierre y Apertura de Gestión", path: "/admin/proyectos-construccion" },
      { name: "Registro de Bancos", path: "/admin/bancos" },
    ],
  }]
  } 
];

export default adminSistemaMenu;

