export const fullNamePersona = (persona) => {
    return typeof persona === 'object' && persona !== null
        ? `${persona.apellido1 || ''} ${persona.apellido2 || ''} ${persona.nombre || ''}`.trim()
        : "";
};

export const formatearFechaSimple = (fecha)=>{
    if(fecha)
   { const partes = fecha.split('-');
    return `${partes[2]}/${partes[1]}/${partes[0]}`;
    }else return "" 
}
export const formatearFecha = (fecha)=>{
    if(fecha)
   { const partes = fecha.split('T');
    return `${partes[2]}/${partes[1]}/${partes[0]}`;
    }else return "" 
}

export const formatearDecimal = (value) => {
    if(value)
    return parseFloat(value).toFixed(2)
    else return "0.00"
}
export function normalizarTexto(texto) {
    if(texto)
   { return texto.normalize("NFD").replace(/[\u0300-\u036f]/g, "");}
    return ""
}
export function nombreArhivoNuevo(texto) {
    if(texto)
   { return normalizarTexto(texto).replace(/[\s_]+/g, '-')}
    return ""
}

export function formatoOracion(texto) {
    if (!texto) return ''; // Si el texto es vacío o undefined, retornar una cadena vacía
    return texto.charAt(0).toUpperCase() + texto.slice(1).toLowerCase();
}
export function formatNumber(number) {
    return new Intl.NumberFormat('es-ES', {
        minimumFractionDigits: 0, // Para enteros sin decimales
        maximumFractionDigits: 5, // Para mostrar hasta 2 decimales si es necesario
    }).format(number);
}