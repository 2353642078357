import "../styles/Inicio.scss";
import portadaImg from '../assets/portada.png';
import dibujo from '../assets/dibujo.jpg';
import { useSelector } from "react-redux";
import { Link } from 'react-router-dom';
import portada from '../assets/portadaintterior.jpg'
const DashboardAdmin = () => {
  const usuario = useSelector(state => state.sesion.usuario);

  return (
    <>
      <div className="home-container img-background" style={{ backgroundImage: `url(${portada})` }}>
      </div>
    </>
  );
};

export default DashboardAdmin;
