import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import apiService from "../services/apiService";

const tituloProfesionalService = apiService('/titulos-profesionales');

export const fetchTitulosProfesionales = createAsyncThunk('tituloProfesional/fetchTitulosProfesionales', async () => {
  const response = await tituloProfesionalService.getAll();
  return response || [];
});

export const fetchTituloProfesionalById = createAsyncThunk('tituloProfesional/fetchTituloProfesionalById', async (id) => {
  const response = await tituloProfesionalService.getById(id);
  return response;
});

export const addTituloProfesional = createAsyncThunk('tituloProfesional/addTituloProfesional', async (tituloProfesional, { rejectWithValue }) => {
  try {
    const response = await tituloProfesionalService.createWithFormData(tituloProfesional);
    return response;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const modifyTituloProfesional = createAsyncThunk('tituloProfesional/modifyTituloProfesional', async ({ id, tituloProfesional }, { rejectWithValue }) => {
  try {
    const response = await tituloProfesionalService.updateWithFormData(id, tituloProfesional);
    return response;
  }  catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const removeTituloProfesional = createAsyncThunk('tituloProfesional/removeTituloProfesional', async (id, { rejectWithValue }) => {
  try {
    await tituloProfesionalService.remove(id);
    return id;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

const initialState = {
  titulosProfesionales: [],
  current: null,
  statusSend: 'idle',
  statusGet:'idle',
  error: {
    status: null,
    messages:[]
  },
};

const tituloProfesionalSlice = createSlice({
  name: 'tituloProfesional',
  initialState,
  reducers: {
    setCurrentTituloProfesional(state, action) {
      state.current = action.payload;
    },
    resetStatusSend(state) {
        state.statusSend = "idle";
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTitulosProfesionales.fulfilled, (state, action) => {
        state.titulosProfesionales = action.payload;
        state.statusGet = 'idle';
      })
      .addCase(fetchTitulosProfesionales.pending, (state) => {
        state.statusGet = 'loading';
      })
      .addCase(fetchTitulosProfesionales.rejected, (state, action) => {
        state.statusGet = 'failed';
        state.error = action.error.message;
      })
      .addCase(fetchTituloProfesionalById.fulfilled, (state, action) => {
        state.current = action.payload;
        state.statusGet = 'idle';
      })
      .addCase(addTituloProfesional.fulfilled, (state, action) => {
        state.titulosProfesionales.push(action.payload);
        state.statusSend = 'idle';
      })
      .addCase(addTituloProfesional.pending, (state) => {
        state.statusSend = 'sending';
      })
      .addCase(addTituloProfesional.rejected, (state, action) => {
        state.statusSend = 'failed';
        state.error = action.payload || action.error.message;
      })
      .addCase(modifyTituloProfesional.fulfilled, (state, action) => {
        const index = state.titulosProfesionales.findIndex(tituloProfesional => tituloProfesional.id === action.payload.id);
        if (index !== -1) {
          state.titulosProfesionales[index] = action.payload;
        }
        state.statusSend = 'idle';
      })
      .addCase(modifyTituloProfesional.pending, (state, action) => {
        state.statusSend = 'sending';
      })
      .addCase(modifyTituloProfesional.rejected, (state, action) => {
        state.statusSend = 'failed';
        state.error = action.payload.error || action.error.message;
      })
      .addCase(removeTituloProfesional.fulfilled, (state, action) => {
        state.titulosProfesionales = state.titulosProfesionales.filter(tituloProfesional => tituloProfesional.id !== action.payload);
        state.statusSend = 'idle';
      })
      .addCase(removeTituloProfesional.pending, (state) => {
        state.statusSend = 'sending';
      })
      .addCase(removeTituloProfesional.rejected, (state, action) => {
        state.statusSend = 'failed';
        state.error = action.payload || action.error.message;
      });
  }
});

export const { setCurrentTituloProfesional, resetStatusSend } = tituloProfesionalSlice.actions;

export default tituloProfesionalSlice.reducer;
