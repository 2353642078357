import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import apiService from "../services/apiService";

const valoradoDetService = apiService('/valorado-dets');

export const fetchValoradoDets = createAsyncThunk('valoradoDet/fetchValoradoDets', async () => {
    const response = await valoradoDetService.getAll();
    return response || [];
});

export const addValoradoDet = createAsyncThunk('valoradoDet/addValoradoDet', async (valoradoDet, { rejectWithValue }) => {
    try {
        const response = await valoradoDetService.create(valoradoDet);
        return response;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});

export const modifyValoradoDet = createAsyncThunk('valoradoDet/modifyValoradoDet', async ({ id, valoradoDet }, { rejectWithValue }) => {
    try {
        const response = await valoradoDetService.update(id, valoradoDet);
        return response;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});

export const removeValoradoDet = createAsyncThunk('valoradoDet/removeValoradoDet', async (id, { rejectWithValue }) => {
    try {
        await valoradoDetService.remove(id);
        return id;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});

const initialState = {
    valoradoDets: [],
    statusSend: 'idle',
    error: null,
};

const valoradoDetSlice = createSlice({
    name: 'valoradoDet',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchValoradoDets.fulfilled, (state, action) => {
                state.valoradoDets = action.payload;
            })
            .addCase(addValoradoDet.fulfilled, (state, action) => {
                state.valoradoDets.push(action.payload);
            })
            .addCase(modifyValoradoDet.fulfilled, (state, action) => {
                const index = state.valoradoDets.findIndex(v => v.id === action.payload.id);
                if (index !== -1) {
                    state.valoradoDets[index] = action.payload;
                }
            })
            .addCase(removeValoradoDet.fulfilled, (state, action) => {
                state.valoradoDets = state.valoradoDets.filter(v => v.id !== action.payload);
            });
    }
});

export default valoradoDetSlice.reducer;
