import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchComprobantes, removeComprobante } from '../../reducers/comprobante_slice';
import ComprobanteForm from './ComprobanteForm';
import { Button, Table, Modal, Form } from 'react-bootstrap';
import Swal from 'sweetalert2';
import CustomPrintModal from '../UI/CustomPrintModal';
import * as XLSX from 'xlsx';

const ComprobanteList = () => {
  const dispatch = useDispatch();
  const comprobantes = useSelector((state) => state.comprobante.comprobantes || []);
  const [showModal, setShowModal] = useState(false);
  const [currentComprobante, setCurrentComprobante] = useState(null);
  const [newNroComprobante, setNewNroComprobante] = useState('');
  const [sortedComprobantes, setSortedComprobantes] = useState([]);
  const [isAscending, setIsAscending] = useState(true);
  const [searchNroComprobante, setSearchNroComprobante] = useState('');
  const [fechaInicial, setFechaInicial] = useState('');
  const [fechaFinal, setFechaFinal] = useState('');
  const [showPrintModal, setShowPrintModal] = useState(false);
  const [printContent, setPrintContent] = useState({ head: [], body: [] });

  useEffect(() => {
    if (!showModal) {
      console.log("Modal cerrado, ejecutando dispatch de comprobantes...");
      dispatch(fetchComprobantes());
    }
  }, [showModal, dispatch]);

  useEffect(() => {
    setSortedComprobantes(comprobantes);
  }, [comprobantes]);

  const generarNuevoNroComprobante = () => {
    const currentYear = new Date().getFullYear();
    const comprobantesYear = comprobantes.filter(comp => comp.nrocomprobante.startsWith(`${currentYear}-`));
    const ultimoComprobante = comprobantesYear.reduce((max, comp) => {
      const num = parseInt(comp.nrocomprobante.split('-')[1], 10);
      return num > max ? num : max;
    }, 0);

    const nuevoNumero = (ultimoComprobante + 1).toString().padStart(5, '0');
    return `${currentYear}-${nuevoNumero}`;
  };

  const handleDelete = (comprobante) => {
    Swal.fire({
      title: '¿Estás seguro?',
      text: 'No podrás revertir esto.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Sí, eliminar!',
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(removeComprobante(comprobante.id))
          .unwrap()
          .then(() => {
            Swal.fire('¡Eliminado!', 'El comprobante ha sido eliminado.', 'success');
          })
          .catch((error) => {
            Swal.fire('Error', error.error || 'Ocurrió un error al eliminar el comprobante', 'error');
          });
      }
    });
  };

  const handleCreate = () => {
    const nroComprobanteGenerado = generarNuevoNroComprobante();
    setNewNroComprobante(nroComprobanteGenerado);
    setCurrentComprobante(null);
    setShowModal(true);
  };

  const handleEdit = (comprobante) => {
    setCurrentComprobante(comprobante);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setCurrentComprobante(null);
    setNewNroComprobante('');
  };

  const handleSortByNumber = () => {
    const sorted = [...sortedComprobantes].sort((a, b) => {
      if (isAscending) {
        return a.nrocomprobante.localeCompare(b.nrocomprobante);
      } else {
        return b.nrocomprobante.localeCompare(a.nrocomprobante);
      }
    });
    setIsAscending(!isAscending);
    setSortedComprobantes(sorted);
  };

  const filteredComprobantes = sortedComprobantes.filter(comprobante => {
    const matchNroComprobante = searchNroComprobante
      ? comprobante.nrocomprobante.includes(searchNroComprobante)
      : true;

    const matchFecha = (fechaInicial === '' || comprobante.fecha >= fechaInicial) &&
                       (fechaFinal === '' || comprobante.fecha <= fechaFinal);

    return matchNroComprobante && matchFecha;
  });

  const handlePrint = () => {
    const content = {
      head: [['Número', 'Glosa', 'Fecha', 'Tipo', 'Estado']],
      body: filteredComprobantes.map(comp => [comp.nrocomprobante, comp.glosa, comp.fecha, comp.tipo_comprobante, comp.estado])
    };
    setPrintContent(content);
    setShowPrintModal(true);
  };

  const handleExport = () => {
    const exportData = filteredComprobantes.map(comp => ({
      Número: comp.nrocomprobante,
      Glosa: comp.glosa,
      Fecha: comp.fecha,
      Tipo: comp.tipo_comprobante,
      Estado: comp.estado
    }));

    const worksheet = XLSX.utils.json_to_sheet(exportData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Comprobantes");
    XLSX.writeFile(workbook, "comprobantes.xlsx");
  };

  return (
    <div className="list-container list1">
      <h1 className="mb-4">Gestión Comprobantes</h1>
      
      <div className="list-header d-flex justify-content-between">
        <Button variant="outline-primary" onClick={handleCreate}>
          Nuevo Comprobante
        </Button>

        {/* Filtros de búsqueda */}
        <Form inline className="d-flex gap-2">
          <Form.Control
            type="text"
            placeholder="Buscar Nro. Comprobante"
            value={searchNroComprobante}
            onChange={(e) => setSearchNroComprobante(e.target.value)}
          />
          <Form.Control
            type="date"
            value={fechaInicial}
            onChange={(e) => setFechaInicial(e.target.value)}
          />
          <Form.Control
            type="date"
            value={fechaFinal}
            onChange={(e) => setFechaFinal(e.target.value)}
          />
        </Form>

        <div className="right-buttons">
          <button title="Imprimir" onClick={handlePrint} className="icon-button btn-transparent">
            <i className="bi bi-printer"></i>
          </button>
          <button title="Exportar a Excel" onDoubleClick={handleExport} className="icon-button btn-transparent">
            <i className="bi bi-file-spreadsheet"></i>
          </button>
        </div>
      </div>

      {filteredComprobantes.length === 0 ? (
        <p>No hay comprobantes disponibles.</p>
      ) : (
        <div style={{ overflowX: 'auto', maxHeight: '600px' }}>
         <Table striped bordered hover>
            <colgroup>
              <col style={{ width: '10%' }} />
              <col style={{ width: '40%' }} />
              <col style={{ width: '10%' }} />
              <col style={{ width: '10%' }} />
              <col style={{ width: '10%' }} />
              <col style={{ width: '20%' }} />
            </colgroup>
            <thead>
              <tr>
                <th onClick={handleSortByNumber} style={{ cursor: 'pointer' }}>
                  Nro.{' '}
                  {isAscending ? (
                    <i className="bi bi-arrow-up"></i>
                  ) : (
                    <i className="bi bi-arrow-down"></i>
                  )}
                </th>
                <th>Glosa</th>
                <th>Fecha</th>
                <th>Tipo</th>
                <th>Estado</th>
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody>
              {filteredComprobantes.map((comprobante) => (
                <tr key={comprobante.id}>
                  <td>{comprobante.nrocomprobante}</td>
                  <td>{comprobante.glosa}</td>
                  <td>{comprobante.fecha}</td>
                  <td>{comprobante.tipo_comprobante}</td>
                  <td>{comprobante.estado}</td>
                  <td>
                    <button className="icon-button btn-secondary-outline" title="Editar" onClick={() => handleEdit(comprobante)}>
                      <i className="bi bi-pencil"></i>
                    </button>
                    {comprobante.estado === 'borrador' && (
                      <Button variant="outline-danger" onClick={() => handleDelete(comprobante)}>
                        Eliminar
                      </Button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>

        </div>
      )}

      <Modal
        show={showModal}
        onHide={handleCloseModal}
        dialogClassName="custom-modal-width"
        size="lg"
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>{currentComprobante ? 'Editar Comprobante' : 'Nuevo Comprobante'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ComprobanteForm
            currentComprobante={currentComprobante}
            nroComprobanteGenerado={newNroComprobante}
            handleClose={handleCloseModal}
          />
        </Modal.Body>
      </Modal>

      {showPrintModal && (
        <CustomPrintModal
          show={showPrintModal}
          handleClose={() => setShowPrintModal(false)}
          title1="LISTA DE COMPROBANTES"
          title2=""
          content={printContent}
          copia={false}
        />
      )}
    </div>
  );
};

export default ComprobanteList;
