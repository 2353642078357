import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import apiService from "../services/apiService";

const ingresosVariosService = apiService('/ingresos-varios');

// Thunk para obtener todos los ingresos varios
export const fetchIngresosVarios = createAsyncThunk('ingresosVarios/fetchIngresosVarios', async () => {
    const response = await ingresosVariosService.getAll();
    return response || [];
});

// Thunk para agregar un nuevo ingreso
export const addIngresoVarios = createAsyncThunk('ingresosVarios/addIngresoVarios', async (ingresoVarios, { rejectWithValue }) => {
    try {
        const response = await ingresosVariosService.create(ingresoVarios);
        return response;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});

// Thunk para modificar un ingreso
export const modifyIngresoVarios = createAsyncThunk('ingresosVarios/modifyIngresoVarios', async ({ id, ingresoVarios }, { rejectWithValue }) => {
    try {
        const response = await ingresosVariosService.update(id, ingresoVarios);
        return response;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});

// Thunk para eliminar un ingreso
export const removeIngresoVarios = createAsyncThunk('ingresosVarios/removeIngresoVarios', async (id, { rejectWithValue }) => {
    try {
        await ingresosVariosService.remove(id);
        return id;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});

const initialState = {
    ingresosVarios: [],
    statusSend: 'idle',
    error: null,
};

const ingresosVariosSlice = createSlice({
    name: 'ingresosVarios',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchIngresosVarios.fulfilled, (state, action) => {
                state.ingresosVarios = action.payload;
            })
            .addCase(addIngresoVarios.fulfilled, (state, action) => {
                state.ingresosVarios.push(action.payload);
            })
            .addCase(modifyIngresoVarios.fulfilled, (state, action) => {
                const index = state.ingresosVarios.findIndex(i => i.id === action.payload.id);
                if (index !== -1) {
                    state.ingresosVarios[index] = action.payload;
                }
            })
            .addCase(removeIngresoVarios.fulfilled, (state, action) => {
                state.ingresosVarios = state.ingresosVarios.filter(i => i.id !== action.payload);
            });
    }
});

export default ingresosVariosSlice.reducer;
