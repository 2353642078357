import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import apiService from '../services/apiService';

// Crear una instancia del servicio para la entidad "usuarios"
const usuarioService = apiService('/usuarios');

// Thunks para las operaciones CRUD

// Obtener todos los usuarios
export const fetchUsuarios = createAsyncThunk('usuario/fetchUsuarios', async () => {
  const response = await usuarioService.getAll();
  return response || [];
});

// Obtener un usuario por ID
export const fetchUsuarioById = createAsyncThunk('usuario/fetchUsuarioById', async (id) => {
  const response = await usuarioService.getById(id);
  return response;
});

// Crear un nuevo usuario
export const addUsuario = createAsyncThunk('usuario/addUsuario', async (usuario, { rejectWithValue }) => {
  try {
    const response = await usuarioService.create(usuario);
    return response;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

// Modificar un usuario existente
export const modifyUsuario = createAsyncThunk('usuario/modifyUsuario', async ({ id, usuario }, { rejectWithValue }) => {
  try {
    const response = await usuarioService.update(id, usuario);
    return response;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

// Eliminar un usuario
export const removeUsuario = createAsyncThunk('usuario/removeUsuario', async (id, { rejectWithValue }) => {
  try {
    await usuarioService.remove(id);
    return id;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

// Estado inicial del slice
const initialState = {
  usuarios: [],
  current: null,
  statusSend: 'idle',
  statusGet: 'idle',
  error: {
    status: null,
    messages: []
  }
};

// Definición del slice
const usuarioSlice = createSlice({
  name: 'usuario',
  initialState,
  reducers: {
    setCurrentUsuario(state, action) {
      state.current = action.payload;
    },
    resetStatusSend(state) {
      state.statusSend = 'idle';
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUsuarios.fulfilled, (state, action) => {
        state.usuarios = action.payload;
        state.statusGet = 'idle';
      })
      .addCase(fetchUsuarios.pending, (state) => {
        state.statusGet = 'loading';
      })
      .addCase(fetchUsuarios.rejected, (state, action) => {
        state.statusGet = 'failed';
        state.error = action.error.message;
      })
      .addCase(fetchUsuarioById.fulfilled, (state, action) => {
        state.current = action.payload;
        state.statusGet = 'idle';
      })
      .addCase(addUsuario.fulfilled, (state, action) => {
        state.usuarios.push(action.payload);
        state.statusSend = 'idle';
      })
      .addCase(addUsuario.pending, (state) => {
        state.statusSend = 'sending';
      })
      .addCase(addUsuario.rejected, (state, action) => {
        state.statusSend = 'failed';
        state.error = action.payload || action.error.message;
      })
      .addCase(modifyUsuario.fulfilled, (state, action) => {
        const index = state.usuarios.findIndex(usuario => usuario.id === action.payload.id);
        if (index !== -1) {
          state.usuarios[index] = action.payload;
        }
        state.statusSend = 'idle';
      })
      .addCase(modifyUsuario.pending, (state) => {
        state.statusSend = 'sending';
      })
      .addCase(modifyUsuario.rejected, (state, action) => {
        state.statusSend = 'failed';
        state.error = action.payload || action.error.message;
      })
      .addCase(removeUsuario.fulfilled, (state, action) => {
        state.usuarios = state.usuarios.filter(usuario => usuario.id !== action.payload);
        state.statusSend = 'idle';
      })
      .addCase(removeUsuario.pending, (state) => {
        state.statusSend = 'sending';
      })
      .addCase(removeUsuario.rejected, (state, action) => {
        state.statusSend = 'failed';
        state.error = action.payload || action.error.message;
      });
  }
});

// Exportar las acciones y el reducer
export const { setCurrentUsuario, resetStatusSend } = usuarioSlice.actions;

export default usuarioSlice.reducer;
