import "../styles/Inicio.scss";
import portadaImg from '../assets/portada.png';
import dibujo from '../assets/dibujo.jpg';
import { useSelector } from "react-redux";
import { Link } from 'react-router-dom';

const InicioPage = () => {
  const usuario = useSelector(state => state.sesion.usuario);

  let sections = (<></>);
  let portada = portadaImg
  if (!usuario) {
    sections = (
      <div id="section1" className="home-container">
        <h1 className="home-subtitle">Bienvenido!</h1>
        <p className="home-description">
          Esta plataforma te permite gestionar los registros de afiliados, gestionar las cuotas, compras y ventas de valorados y más.
        </p>
      </div>
    );
  }

  return (
    <>
      <div className="home-container img-background" style={{ backgroundImage: `url(${portada})` }}>
        <h2 className="home-title special-title">SISTEMA</h2>
        <h1 className="home-title special-title">COLEGIO DE ARQUITECTOS</h1>
        <h2 className="home-subtitle special-title">TARIJA</h2>
        {/* {usuario && (
          <div className="home-actions">
            <Link to="/paises" className="btn btn-danger" style={{ backgroundColor: 'plomo', marginRight: '10px' }}>
              Gestionar Países
            </Link>
            <Link to="/lugares" className="btn btn-danger" style={{ backgroundColor: 'plomo', marginRight: '10px' }}>
              Gestionar Lugares
            </Link>
            <Link to="/personas" className="btn btn-danger" style={{ backgroundColor: 'plomo', marginRight: '10px' }}>
              Gestionar Personas
            </Link>
            <Link to="/instituciones" className="btn btn-danger" style={{ backgroundColor: 'plomo', marginRight: '10px' }}>
              Gestionar Instituciones
            </Link>
          </div>
        )} */}
      </div>
      {sections}
    </>
  );
};

export default InicioPage;
