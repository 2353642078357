import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import apiService from "../services/apiService"; // Import the API service

// Create an instance of the service for the "comprobantes" entity
const comprobanteService = apiService('/comprobantes');

// Thunks for CRUD operations

// Fetch all comprobantes without details
export const fetchComprobantes = createAsyncThunk('comprobante/fetchComprobantes', async () => {
  const response = await comprobanteService.getAll();
  return response || [];
});

// Fetch a specific comprobante by nrocomprobante with details
export const fetchComprobanteByNroWithDetails = createAsyncThunk('comprobante/fetchComprobanteByNroWithDetails', async (nrocomprobante, { rejectWithValue }) => {
  try {
    const response = await comprobanteService.getAllBySomeId('condetalles',nrocomprobante); // Adjusted to use the correct route
    return response;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

// Add a new comprobante with its details
export const addComprobante = createAsyncThunk('comprobante/addComprobante', async (comprobante, { rejectWithValue }) => {
  try {
    const response = await comprobanteService.create(comprobante); // `comprobante` includes both the header and details
    return response;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

// Modify an existing comprobante and its details
export const modifyComprobante = createAsyncThunk('comprobante/modifyComprobante', async ({ id, comprobante }, { rejectWithValue }) => {
  try {
    const response = await comprobanteService.update(id, comprobante);
    return response;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

// Remove a comprobante and its details
export const removeComprobante = createAsyncThunk('comprobante/removeComprobante', async (id, { rejectWithValue }) => {
  try {
    await comprobanteService.remove(id);
    return id;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

// Initial state
const initialState = {
  comprobantes: [],
  current: null,
  statusSend: 'idle',
  statusGet: 'idle',
  error: {
    status: null,
    messages: []
  }
};

// Slice definition
const comprobanteSlice = createSlice({
  name: 'comprobante',
  initialState,
  reducers: {
    setCurrentComprobante(state, action) {
      state.current = action.payload;
    },
    resetStatusSend(state) {
      state.statusSend = 'idle';
    }
  },
  extraReducers: (builder) => {
    builder
      // Handle fetchComprobantes
      .addCase(fetchComprobantes.fulfilled, (state, action) => {
        state.comprobantes = action.payload;
        state.statusGet = 'idle';
      })
      .addCase(fetchComprobantes.pending, (state) => {
        state.statusGet = 'loading';
      })
      .addCase(fetchComprobantes.rejected, (state, action) => {
        state.statusGet = 'failed';
        state.error = action.error.message;
      })

      // Handle fetchComprobanteByNroWithDetails
      .addCase(fetchComprobanteByNroWithDetails.fulfilled, (state, action) => {
        //console.log("respuesta fetchComprobanteByNroWithDetails", action.payload)
        state.current = action.payload;
        state.statusGet = 'idle';
      })
      .addCase(fetchComprobanteByNroWithDetails.pending, (state) => {
        state.statusGet = 'loading';
      })
      .addCase(fetchComprobanteByNroWithDetails.rejected, (state, action) => {
        state.statusGet = 'failed';
        state.error = action.error.message;
      })

      // Handle addComprobante
      .addCase(addComprobante.fulfilled, (state, action) => {
        state.comprobantes.push(action.payload);
        state.statusSend = 'idle';
      })
      .addCase(addComprobante.pending, (state) => {
        state.statusSend = 'sending';
      })
      .addCase(addComprobante.rejected, (state, action) => {
        state.statusSend = 'failed';
        state.error = action.payload || action.error.message;
      })

      // Handle modifyComprobante
      .addCase(modifyComprobante.fulfilled, (state, action) => {
        const index = state.comprobantes.findIndex(c => c.id === action.payload.id);
        if (index !== -1) {
          state.comprobantes[index] = action.payload;
        }
        state.statusSend = 'idle';
      })
      .addCase(modifyComprobante.pending, (state) => {
        state.statusSend = 'sending';
      })
      .addCase(modifyComprobante.rejected, (state, action) => {
        state.statusSend = 'failed';
        state.error = action.payload || action.error.message;
      })

      // Handle removeComprobante
      .addCase(removeComprobante.fulfilled, (state, action) => {
        state.comprobantes = state.comprobantes.filter(c => c.id !== action.payload);
        state.statusSend = 'idle';
      })
      .addCase(removeComprobante.pending, (state) => {
        state.statusSend = 'sending';
      })
      .addCase(removeComprobante.rejected, (state, action) => {
        state.statusSend = 'failed';
        state.error = action.payload || action.error.message;
      });
  }
});

// Export the reducer actions and the reducer itself
export const { setCurrentComprobante, resetStatusSend } = comprobanteSlice.actions;
export default comprobanteSlice.reducer;
