import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import apiService from "../services/apiService";

const institucionService = apiService('/instituciones');

export const fetchInstituciones = createAsyncThunk('institucion/fetchInstituciones', async () => {
  const response = await institucionService.getAll();
  return response || [];
});

export const fetchInstitucionById = createAsyncThunk('institucion/fetchInstitucionById', async (id) => {
  const response = await institucionService.getById(id);
  return response;
});

export const addInstitucion = createAsyncThunk('institucion/addInstitucion', async (institucion, { rejectWithValue }) => {
  try {
    const response = await institucionService.create(institucion);
    return response;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const modifyInstitucion = createAsyncThunk('institucion/modifyInstitucion', async ({ id, institucion }, { rejectWithValue }) => {
  try {
    const response = await institucionService.update(id, institucion);
    return response;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const removeInstitucion = createAsyncThunk('institucion/removeInstitucion', async (id, { rejectWithValue }) => {
  try {
    await institucionService.remove(id);
    return id;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

const initialState = {
  instituciones: [],
  current: null,
  statusSend: 'idle',
  statusGet:'idle',
  error: {
    status: null,
    messages:[]
  },
};


  

const institucionSlice = createSlice({
  name: 'institucion',
  initialState,
  reducers: {
    setCurrentInstitucion(state, action) {
      state.current = action.payload;
    },
    resetStatusSend(state) {
        state.statusSend = "idle";
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchInstituciones.fulfilled, (state, action) => {
        state.instituciones = action.payload;
        state.statusGet = 'idle';
      })
      .addCase(fetchInstituciones.pending, (state) => {
        state.statusGet = 'loading';
      })
      .addCase(fetchInstituciones.rejected, (state, action) => {
        state.statusGet = 'failed';
        state.error = action.error.message;
      })
      .addCase(fetchInstitucionById.fulfilled, (state, action) => {
        state.current = action.payload;
        state.statusGet = 'idle';
      })
      .addCase(addInstitucion.fulfilled, (state, action) => {
        state.instituciones.push(action.payload);
        state.statusSend = 'idle';
      })
      .addCase(addInstitucion.pending, (state) => {
        state.statusSend = 'sending';
      })
      .addCase(addInstitucion.rejected, (state, action) => {
        state.statusSend = 'failed';
        state.error = action.payload || action.error.message;
      })
      .addCase(modifyInstitucion.fulfilled, (state, action) => {
        const index = state.instituciones.findIndex(institucion => institucion.id === action.payload.id);
        if (index !== -1) {
          state.instituciones[index] = action.payload;
        }
        state.statusSend = 'idle';
      })
      .addCase(modifyInstitucion.pending, (state) => {
        state.statusSend = 'sending';
      })
      .addCase(modifyInstitucion.rejected, (state, action) => {
        state.statusSend = 'failed';
        state.error = action.payload || action.error.message;
      })
      .addCase(removeInstitucion.fulfilled, (state, action) => {
        state.instituciones = state.instituciones.filter(institucion => institucion.id !== action.payload);
        state.statusSend = 'idle';
      })
      .addCase(removeInstitucion.pending, (state) => {
        state.statusSend = 'sending';
      })
      .addCase(removeInstitucion.rejected, (state, action) => {
        state.statusSend = 'failed';
        state.error = action.payload || action.error.message;
      });
  }
});

export const { setCurrentInstitucion, resetStatusSend } = institucionSlice.actions;

export default institucionSlice.reducer;
