import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addTipoProyecto, modifyTipoProyecto, fetchTiposProyecto } from '../../reducers/tipoProyecto_slice';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Swal from 'sweetalert2';
import { Alert, Col, Row } from 'react-bootstrap';
import ActionButton from '../UI/Buttons/ActionButton';

const initialState = {
    nombre:"",
    descripcion: "",
    adicion_costo_mes: "",
}

const TipoProyectoForm = ({ currentTipoProyecto, showModal, handleClose }) => {
    const [datos, setDatos] = useState(initialState);
    const [error, setError] = useState('');
    const [isModified, setIsModified] = useState(false);

    const dispatch = useDispatch();
    const requestStatus = useSelector(state => state.tipoProyecto.statusSend);
    const errorInRequest = useSelector(state => state.tipoProyecto.error);

    useEffect(() => {
        if (currentTipoProyecto) {
            setDatos({...currentTipoProyecto,adicion_costo_mes: currentTipoProyecto.adicion_costo_mes*100});
            setIsModified(false);
        } else {
            resetForm();
        }
    }, [currentTipoProyecto]);

    const handleSubmit = e => {
        e.preventDefault();

        if (isModified) {
            setError('');

            const tipoData = {descripcion: datos.descripcion.toUpperCase(),nombre: datos.nombre.toUpperCase(),
                adicion_costo_mes: datos.adicion_costo_mes/100
              };

            if (currentTipoProyecto) {
                dispatch(modifyTipoProyecto({ id: currentTipoProyecto.id, tipo: tipoData })).then(response => {
                    if (response.error || response.payload.error) {
                        Swal.fire({
                            icon: 'error',
                            title: 'Error',
                            text: response.payload.error || response.error.message || 'Error al actualizar el tipo de proyecto.',
                        });
                    } else {
                        handleClose();
                        resetForm();
                        dispatch(fetchTiposProyecto());
                    }
                });
            } else {
                dispatch(addTipoProyecto(tipoData)).then(response => {
                    if (response.error || response.payload.error) {
                        Swal.fire({
                            icon: 'error',
                            title: 'Error',
                            text: response.payload.error || response.error.message || 'Error al crear el tipo de proyecto.',
                        });
                    } else {
                        handleClose();
                        resetForm();
                        dispatch(fetchTiposProyecto());
                    }
                });
            }
        } else {
            handleClose();
            resetForm();
        }
    };

    const resetForm = () => {
        setDatos(initialState);
        setError('');
        setIsModified(false);
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setDatos((prevDatos) => ({ ...prevDatos, [name]: value }));
        setIsModified(true);
    }

    const handlePorcentaje = e => {
        const { name, value } = e.target;
        let porcentaje = parseInt(value, 10);
    
        // Asegurar que el valor esté entre -100 y 100
        if (isNaN(porcentaje) || porcentaje < -100) {
            porcentaje = -100;
        } else if (porcentaje > 100) {
            porcentaje = 100;
        }
    
        // Actualizar el estado con el valor ajustado
        setDatos(prevDatos => ({
            ...prevDatos,
            [name]: porcentaje
        }));
        setIsModified(true);
    };
    
    let title = currentTipoProyecto ? "Editar Tipo de Proyecto" : "Nuevo Tipo de Proyecto";
    let headerColor = currentTipoProyecto ? '#748b39' : '#6CB1C2';
    let buttonText = requestStatus !== "sending" ? (currentTipoProyecto ? 'Actualizar' : 'Crear') : "Enviando...";

    return (
        <Modal show={showModal} onHide={() => { handleClose(); resetForm(); }} backdrop="static" keyboard={false} size="lg">
            <Modal.Header closeButton style={{ backgroundColor: headerColor, color: 'white' }}>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {error && (
                    <Alert variant="danger">
                        {error}
                    </Alert>
                )}
                <Form onSubmit={handleSubmit}>
                    <Row xs={1} md={2}>
                    <Col className='col-form'>
                            <Form.Label>Nombre</Form.Label>
                            <Form.Control
                                type="text"
                                name="nombre"
                                value={datos.nombre}
                                onChange={handleInputChange}
                                required
                            />
                        </Col>
                        <Col className='col-form'>
                            <Form.Label>Descripción</Form.Label>
                            <Form.Control
                                type="text"
                                name="descripcion"
                                value={datos.descripcion}
                                onChange={handleInputChange}
                                required
                            />
                        </Col>
                        <Col className='col-form'>
                            <Form.Label>Porcentaje adicional al CM (%)</Form.Label>
                            <Form.Control
                                type="number"
                                step="1"
                                name="adicion_costo_mes"
                                value={datos.adicion_costo_mes}
                                onChange={handleInputChange}
                                onBlur={handlePorcentaje}
                                required
                            />
                        </Col>
                    </Row>
                    <div className="d-flex justify-content-between mt-5">
                        <Button variant="outline-dark" onClick={() => { handleClose(); resetForm(); }}>
                            Cancelar
                        </Button>
                        <ActionButton outline>
                            {buttonText}
                        </ActionButton>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default TipoProyectoForm;
