// LugarForm.js
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addLugar, modifyLugar, fetchLugares } from '../../reducers/lugar_slice';
import { fetchPaises } from '../../reducers/pais_slice';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Swal from 'sweetalert2';

const LugarForm = ({ currentLugar, showModal, handleClose }) => {
  const [nombre, setNombre] = useState('');
  const [idPais, setIdPais] = useState('');
  const [error, setError] = useState('');
  const [isModified, setIsModified] = useState(false); // Estado para rastrear modificaciones

  const dispatch = useDispatch();
  const paises = useSelector(state => state.pais.paises);

  useEffect(() => {
    dispatch(fetchPaises());
    if (currentLugar) {
      setNombre(currentLugar.nombre);
      setIdPais(currentLugar.id_pais);
      setIsModified(false); // Inicialmente, no se ha modificado nada
    } else {
      setNombre('');
      setIdPais('');
      setIsModified(true); // Para un nuevo lugar, el botón debe estar habilitado
    }
  }, [currentLugar, dispatch]);

  const handleSubmit = e => {
    e.preventDefault();
    const lugarData = { nombre: nombre ? nombre.toUpperCase() : null, id_pais: idPais };
    setError('');
    if (currentLugar) {
      dispatch(modifyLugar({ id: currentLugar.id, lugar: lugarData })).then(response => {
        if (response.error || response.payload.error) {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: response.payload.error || response.error.message || 'Error al actualizar el lugar',
          });
        } else {
          handleClose();
          resetForm();
          dispatch(fetchLugares());
        }
      });
    } else {
      dispatch(addLugar(lugarData)).then(response => {
        if (response.error || response.payload.error) {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: response.payload.error || response.error.message || 'Error al crear el lugar',
          });
        } else {
          handleClose();
          resetForm();
          dispatch(fetchLugares());
        }
      });
    }
  };

  const resetForm = () => {
    setNombre('');
    setIdPais('');
    setError('');
  };

  const handleChangeNombre = e => {
    setNombre(e.target.value);
    setIsModified(e.target.value !== (currentLugar ? currentLugar.nombre : '')); // Compara con el valor original
  };

  const handleChangeIdPais = e => {
    setIdPais(e.target.value);
    setIsModified(e.target.value !== (currentLugar ? currentLugar.id_pais : '')); // Compara con el valor original
  };

  let title = currentLugar ? "Editar lugar" : "Nuevo lugar";
  let headerColor = currentLugar ? '#748b39' : '#6CB1C2';
  let buttonText = currentLugar ? 'Actualizar' : 'Crear';

  return (
    <Modal show={showModal} onHide={() => { handleClose(); resetForm(); }} centered>
      <Modal.Header closeButton style={{ backgroundColor: headerColor, color: 'white' }}>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="formNombreLugar">
            <Form.Label>Nombre</Form.Label>
            <Form.Control
              type="text"
              placeholder="Nombre del Lugar"
              value={nombre}
              onChange={handleChangeNombre}
              required
            />
          </Form.Group>
          <Form.Group controlId="formIdPais">
            <Form.Label>País</Form.Label>
            <Form.Control
              as="select"
              value={idPais}
              onChange={handleChangeIdPais}
              required
            >
              <option value="">Seleccione un país</option>
              {paises.map(pais => (
                <option key={pais.id} value={pais.id}>{pais.nombre}</option>
              ))}
            </Form.Control>
          </Form.Group>
          {error && <p className="text-danger">{error}</p>}
          <div className="d-flex justify-content-between mt-5">
            <Button variant="outline-dark" onClick={() => { handleClose(); resetForm(); }}>
              Cancelar
            </Button>
            <button className="button-mine btn-mine-primary-outline" type="submit" disabled={currentLugar && !isModified}>
              {buttonText}
            </button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default LugarForm;
