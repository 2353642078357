import React, { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchPaises, removePais } from '../../reducers/pais_slice';
import PaisForm from './PaisForm';
import Table from 'react-bootstrap/Table';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import * as XLSX from 'xlsx';
import ActionButton from '../UI/Buttons/ActionButton';
import CustomPrintModal from '../UI/CustomPrintModal';

const PaisList = () => {
  const dispatch = useDispatch();
  const paisState = useSelector(state => state.pais || { paises: [] });
  const paises = paisState.paises || [];
  const [showModal, setShowModal] = useState(false);
  const [currentPais, setCurrentPais] = useState(null);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [printContent, setPrintContent] = useState({ head: [], body: [] });
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(fetchPaises());
  }, [dispatch]);

  const handleDelete = id => {
    Swal.fire({
      title: '¿Estás seguro?',
      text: "¡No podrás revertir esto!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Sí, eliminarlo!'
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(removePais(id)).unwrap().then(() => {
          Swal.fire('¡Eliminado!', 'El país ha sido eliminado.', 'success');
        }).catch((error) => {
          Swal.fire('Error', error.error || 'Ocurrió un error al eliminar el país', 'error');
        });
      }
    });
  };

  const handleSort = (key) => {
    if (sortConfig.direction === 'desc') {
      setSortConfig({ key: null, direction: 'asc' });
      return;
    }

    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    } else if (sortConfig.key === key && sortConfig.direction === 'desc') {
      direction = null; // Si ya estaba descendente, lo dejamos sin ordenar
    }

    setSortConfig({ key, direction });
  };

  const handleEdit = pais => {
    setCurrentPais(pais);
    setShowModal(true);
  };

  const handleCreate = () => {
    setCurrentPais(null);
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
    setCurrentPais(null);
  };

  const handleBack = () => {
    navigate('/admin'); // Ruta a la que deseas regresar, en este caso, la página principal
  };

  const handleExport = () => {
    const worksheet = XLSX.utils.json_to_sheet(sortedPaises);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Paises");
    XLSX.writeFile(workbook, "paises.xlsx");
  };

  const sortedPaises = useMemo(() => {
    let sortedData = [...paises];
    if (sortConfig.key) {
      sortedData.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'asc' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'asc' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortedData;
  }, [paises, sortConfig]);

  const handleShowPreview = () => {
    setPrintContent(getPreviewContent());
    setShowPreviewModal(true);
  };

  const handleClosePreview = () => {
    setShowPreviewModal(false);
  };

  const getPreviewContent = () => {
    const content = {
      head: [['ID', 'Nombre']],
      body: sortedPaises.map(pais => [
        pais.id,
        pais.nombre
      ])
    };
    return content;
  };

  return (
    <div className="list-container list0">
      <h1 className='table-title'>Lista de Paises</h1>
      <div className="list-content">
        <div className='list-controls'>
          <div className="col-md-6">
          </div>
          <div className="col-md-6 text-right">
            <ActionButton onClick={handleCreate} outline icon='plus' floatEnd>
              Nuevo País
            </ActionButton>
          </div>
        </div>
        <div className="list-container-header">
          <div className="left-buttons">
            <button title="Volver" onClick={handleBack} className="icon-button btn-transparent"><i className="bi bi-arrow-left"></i></button>
          </div>
          <div className="right-buttons">
            <button title="Imprimir" onClick={handleShowPreview} className="icon-button btn-transparent"><i className="bi bi-printer"></i></button>
            <button title="Exportar a excel" onDoubleClick={handleExport} className="icon-button btn-transparent"><i className="bi bi-file-spreadsheet"></i></button>
          </div>
        </div>
        {paises.length === 0 ? (
          <p>No hay países disponibles.</p>
        ) : (
          <div className='table-container'>
            <Table bordered hover size='sm'>
              <thead className='text-center align-middle'>
                <tr>
                  <th className='col-index'>N°</th>
                  <th className='col-index'>
                    ID
                    {sortConfig.key === 'id' && (
                      sortConfig.direction === 'asc' ? <i className="bi bi-arrow-up"></i> : <i className="bi bi-arrow-down"></i>
                    )}
                  </th>
                  <th onClick={() => handleSort('nombre')}>
                    Nombre
                    {sortConfig.key === 'nombre' && (
                      sortConfig.direction === 'asc' ? <i className="bi bi-arrow-up"></i> : <i className="bi bi-arrow-down"></i>
                    )}
                  </th>
                  <th className='col-simple-actions'>Acciones</th>
                </tr>
              </thead>
              <tbody>
                {sortedPaises.map((pais, index) => (
                  <tr key={pais.id}>
                    <td>{index + 1}</td>
                    <td>{pais.id}</td>
                    <td>{pais.nombre}</td>
                    <td>
                      <button className="icon-button btn-secondary-outline" title="Editar" onClick={() => handleEdit(pais)}>
                        <i className="bi bi-pencil"></i>
                      </button>
                      <button className="icon-button btn-secondary-outline" title="Eliminar" onClick={() => handleDelete(pais.id)}>
                        <i className="bi bi-trash"></i>
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        )}
        <PaisForm currentPais={currentPais} showModal={showModal} handleClose={handleClose} />
        <CustomPrintModal
          show={showPreviewModal}
          handleClose={handleClosePreview}
          title1="LISTA DE PAISES"
          title2=""
          content={printContent}
          copia={false}
        />
      </div>
    </div>
  );
};

export default PaisList;
