import React, { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchAreasProyecto, removeAreaProyecto } from '../../reducers/areaProyecto_slice';
import AreaProyectoForm from './AreaProyectoForm';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import CustomPrintModal from '../UI/CustomPrintModal';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import * as XLSX from 'xlsx';
import ActionButton from '../UI/Buttons/ActionButton';

const AreaProyectoList = () => {
  const dispatch = useDispatch();
  const areaProyectoState = useSelector(state => state.areaProyecto || { areas: [] });
  const areas = areaProyectoState.areas || [];
  const [showModal, setShowModal] = useState(false);
  const [currentAreaProyecto, setCurrentAreaProyecto] = useState(null);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [printContent, setPrintContent] = useState({ head: [], body: [] });
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(fetchAreasProyecto());
  }, [dispatch]);

  const handleDelete = id => {
    Swal.fire({
      title: '¿Estás seguro?',
      text: "¡No podrás revertir esto!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Sí, eliminarlo!'
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(removeAreaProyecto(id)).unwrap().then(() => {
          Swal.fire('¡Eliminado!', 'El rubro de proyecto ha sido eliminada.', 'success');
        }).catch((error) => {
          Swal.fire('Error', error.error || 'Ocurrió un error al eliminar el rubro de proyecto', 'error');
        });
      }
    });
  };

  const handleSort = (key) => {
    if(sortConfig.direction === 'desc'){
        setSortConfig({ key: null, direction: 'asc' });
        return;
    }
    
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    } else if (sortConfig.key === key && sortConfig.direction === 'desc') {
      direction = null;
    }
    setSortConfig({ key, direction });
  };

  const handleEdit = area => {
    setCurrentAreaProyecto(area);
    setShowModal(true);
  };

  const handleCreate = () => {
    setCurrentAreaProyecto(null);
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
    setCurrentAreaProyecto(null);
  };

  const handleBack = () => {
    navigate('/admin');
  };

  const handleExport = () => {
    const contentArrays = getPreviewContent()
    // Combinar las cabeceras y los datos
    const exportData = [...contentArrays.head, ...contentArrays.body];
    // Crear la hoja de trabajo con las cabeceras personalizadas
    const worksheet = XLSX.utils.aoa_to_sheet(exportData); 
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "RubrosProyecto");
    XLSX.writeFile(workbook, "RubrosProyecto.xlsx");
  };

  const sortedAreas = useMemo(() => {
    let sortedData = [...areas];
    if (sortConfig.key) {
      sortedData.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'asc' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'asc' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortedData;
  }, [areas, sortConfig]);

  const handleShowPreview = () => {
    setPrintContent(getPreviewContent());
    setShowPreviewModal(true);
  };

  const handleClosePreview = () => {
    setShowPreviewModal(false);
  };

  const getPreviewContent = () => {
    const content = {
      head: [['N°', 'Rubro']],
      body: sortedAreas.map((area, index) => [
        index+1,
        area.nombre,
      ])
    };
    return content;
  };

  return (
    <div className="list-container list0">
      <h1 className='table-title'>Lista de Rubros</h1>
      <div className="list-content">
        <div className='list-controls'>
          <div className="sort-controls col-md-6">
           
          </div>
          <div className="col-md-6">
            <ActionButton onClick={handleCreate} outline icon='plus' floatEnd>
              Nuevo Rubro de Proyecto
            </ActionButton>
          </div>
        </div>
        <div className="list-container-header">
          <div className="left-buttons">
            <button title="Volver" onClick={handleBack} className="icon-button btn-transparent"><i className="bi bi-arrow-left"></i></button>
          </div>
          <div className="right-buttons">
            <button title="Imprimir" onClick={handleShowPreview} className="icon-button btn-transparent"><i className="bi bi-printer"></i></button>
            <button title="Exportar a excel" onDoubleClick={handleExport} className="icon-button btn-transparent"><i className="bi bi-file-spreadsheet"></i></button>
          </div>
        </div>
        {areas.length === 0 ? (
          <p>No hay rubros disponibles.</p>
        ) : (
          <div className='table-container'>
            <Table bordered hover size='sm'>
              <thead className='text-center align-middle'>
                <tr>
                  <th className='col-index'>N°</th>
                  <th onClick={() => handleSort('nombre')}>
                    Nombre
                    {sortConfig.key === 'nombre' && (
                        sortConfig.direction === 'asc' ? <i className="bi bi-arrow-up"></i> : <i className="bi bi-arrow-down"></i>
                    )}
                  </th>
                  <th className='col-simple-actions'>Acciones</th>
                </tr>
              </thead>
              <tbody>
                {sortedAreas.map((area, index) => (
                  <tr key={area.id}>
                    <td>{index + 1}</td>
                    <td>{area.nombre}</td>
                    <td>
                      <button className="icon-button btn-secondary-outline" title="Editar" onClick={() => handleEdit(area)}>
                        <i className="bi bi-pencil"></i>
                      </button>
                      <button className="icon-button btn-secondary-outline" title="Eliminar" onClick={() => handleDelete(area.id)}>
                        <i className="bi bi-trash"></i>
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        )}
        <AreaProyectoForm currentAreaProyecto={currentAreaProyecto} showModal={showModal} handleClose={handleClose} />
        <CustomPrintModal
          show={showPreviewModal}
          handleClose={handleClosePreview}
          title1="LISTA DE RUBROS"
          title2=""
          content={printContent}
          copia={false}
        />
      </div>
    </div>
  );
};

export default AreaProyectoList;
