import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { afiliadoActions } from '../../reducers/afiliado_slice';
import Table from 'react-bootstrap/Table';
import { formatearFechaSimple, fullNamePersona } from '../../util/customFormatter.js';
import { Form, InputGroup } from 'react-bootstrap';

const AfiliadoList = ({selectedSituaciones=null, 
                      onSelectAfiliado=null, 
                      handleEdit,
                      handleEditPersona,
                      handleRequisitos,
                      handleInscripcion,
                      handleDelete}) => 
{

  const dispatch = useDispatch();
  const afiliadoState = useSelector(state => state.afiliado || { afiliados: [] });
  const afiliados = afiliadoState.afiliados || [];
  const sortedFilteredAfiliados = useSelector(state => state.afiliado.sortedFilteredList || afiliados);
  const [sortConfig, setSortConfig] = useState({ key1: null,key2: null, direction: 'asc' });
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(()=>{
    //FILTRADO
    let  filteredAfiliados = afiliados.filter(afiliado => {
      const matchesSearch = afiliado.persona ? 
        `${fullNamePersona(afiliado.persona)} ${afiliado.persona.ci} ${afiliado.num_reg}`.toLowerCase().includes(searchTerm.toLowerCase()) 
        : false;

      const matchesState = selectedSituaciones?.length > 0
        ? selectedSituaciones.some(situ => situ.value === afiliado.id_situacion)
        : true;
      
      return matchesSearch && matchesState;
    })

    let sortedData = [...filteredAfiliados];
   //ORDENACION
    if (sortConfig.key2) {
      sortedData.sort((a, b) => {
          let aValue, bValue;
  
          // Comparar fechas correctamente
          if (sortConfig.key2 === "fecha_reg_cab") {
              aValue = a[sortConfig.key2] ? new Date(a[sortConfig.key2]) : null;
              bValue = b[sortConfig.key2] ? new Date(b[sortConfig.key2]) : null;
          } 
          // Comparar como números enteros
          else if (sortConfig.key2 === "num_reg") {

              aValue = parseInt(a[sortConfig.key2], 10);  
              bValue = parseInt(b[sortConfig.key2], 10); 
          }
          // Comparar subpropiedades
          else if (sortConfig.key1 !== "") {
              aValue = a[sortConfig.key1][sortConfig.key2];
              bValue = b[sortConfig.key1][sortConfig.key2];
          } 
          // Comparar otros valores
          else {
              aValue = a[sortConfig.key2];
              bValue = b[sortConfig.key2];
          }
          // Manejar valores nulos para que vayan al final
          if (aValue === null || aValue === undefined || Number.isNaN(aValue)) return 1;
          if (bValue === null ||  bValue === undefined || Number.isNaN(bValue)) return -1;
  
          // Comparar valores no nulos
          if (aValue < bValue) {
              return sortConfig.direction === 'asc' ? -1 : 1;
          }
          if (aValue > bValue) {
              return sortConfig.direction === 'asc' ? 1 : -1;
          }
          return 0;
      });
  }
      dispatch(afiliadoActions.setSortedFilteredList(sortedData))
},[afiliados, sortConfig, searchTerm, selectedSituaciones])


  const handleSort = (key1, key2) => {
    if (sortConfig.direction === 'desc') {
      setSortConfig({ key1:null, key2: null, direction: 'asc' });
      return;
    }
    let direction = 'asc';
    if (sortConfig.key1 === key1 && sortConfig.key2 === key2 && sortConfig.direction === 'asc') {
      direction = 'desc';
    } else if (sortConfig.key1 === key1 && sortConfig.key2 === key2 && sortConfig.direction === 'desc') {
      direction = null; // Si ya estaba descendente, lo dejamos sin ordenar
    }
    setSortConfig({key1, key2, direction });
  };
  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  return (
    <>
        <div className="list-container-header">
          <InputGroup size="sm">
        <InputGroup.Text>
        <i className="bi bi-search"></i>
        </InputGroup.Text>
        <Form.Control
             size='sm'
               type="text"
              placeholder="Busca por numero registro, C.I. o nombre del afiliado"
              className="form-control"
              value={searchTerm}
              onChange={handleSearch}
            />
              </InputGroup>
        </div>
        {sortedFilteredAfiliados.length === 0 ? (
          <p>No hay afiliados disponibles.</p>
        ) : (
         <>
         
         <div className='table-container'>
            <Table bordered hover size='sm'>
              <thead className='text-center align-middle'>
                <tr>
                  <th className='col-index'>N°</th>
                  <th onClick={() => handleSort('','num_reg')}>
                    N° de registro
                    {sortConfig.key2 === 'num_reg' && (
                      sortConfig.direction === 'asc' ? <i className="bi bi-arrow-up"></i> : <i className="bi bi-arrow-down"></i>
                    )}
                  </th>
                  <th onClick={() => handleSort('persona','apellido1')}>
                    Apellido Paterno
                    {sortConfig.key2 === 'apellido1' && (
                      sortConfig.direction === 'asc' ? <i className="bi bi-arrow-up"></i> : <i className="bi bi-arrow-down"></i>
                    )}
                  </th>
                  <th onClick={() => handleSort('persona','apellido2')}>
                    Apellido Materno
                    {sortConfig.key2 === 'apellido2' && (
                      sortConfig.direction === 'asc' ? <i className="bi bi-arrow-up"></i> : <i className="bi bi-arrow-down"></i>
                    )}
                  </th>
                  <th onClick={() => handleSort('persona','nombre')}>
                    Nombre
                    {sortConfig.key1 === 'persona' && sortConfig.key2 === 'nombre' && (
                      sortConfig.direction === 'asc' ? <i className="bi bi-arrow-up"></i> : <i className="bi bi-arrow-down"></i>
                    )}
                  </th>
                  <th onClick={() => handleSort('persona','ci')}>
                    C.I.
                    {sortConfig.key2 === 'ci' && (
                      sortConfig.direction === 'asc' ? <i className="bi bi-arrow-up"></i> : <i className="bi bi-arrow-down"></i>
                    )}
                  </th>
                  <th onClick={() => handleSort('','fecha_reg_cab')}>
                    Fecha de registro
                    {sortConfig.key2 === 'fecha_reg_cab' && (
                      sortConfig.direction === 'asc' ? <i className="bi bi-arrow-up"></i> : <i className="bi bi-arrow-down"></i>
                    )}
                  </th>
                  <th onClick={() => handleSort('situacion','nombre')}>
                     Situación
                    {sortConfig.key1 === 'situacion' && (
                      sortConfig.direction === 'asc' ? <i className="bi bi-arrow-up"></i> : <i className="bi bi-arrow-down"></i>
                    )}
                  </th>
                 {!onSelectAfiliado && <th className='col-actions3'>Acciones</th>}
                </tr>
              </thead>
              <tbody>
                {sortedFilteredAfiliados.map((afiliado, index) => (
                  <tr key={afiliado.id} onClick={() => onSelectAfiliado(afiliado)}>
                    <td>{index + 1}</td>
                    <td>{afiliado.num_reg || 'N/A'}</td>  
                    <td>{afiliado.persona.apellido1}</td>
                    <td>{afiliado.persona.apellido2}</td>
                    <td>{afiliado.persona.nombre}</td>
                     <td>{afiliado.persona.ci}</td>
                     <td>{formatearFechaSimple(afiliado.fecha_reg_cab) || 'N/A'}</td>
                    <td>{afiliado.situacion.nombre || 'N/A'}</td>
                   
                   {!onSelectAfiliado && <td onClick={(e) => {e.stopPropagation();}}>
                    <button className="icon-button btn-secondary-outline" title="Editar datos personales" onClick={() => handleEditPersona(afiliado)}>
                    <i className="bi bi-person-lines-fill"></i>
                      </button>
                    {afiliado.num_preinscripcion &&  <button className="icon-button btn-secondary-outline" title="Registrar Requisitos" onClick={() => handleRequisitos(afiliado)}>
                        <i className="bi bi-check-square"></i>
                      </button>}
                      <button className="icon-button btn-secondary-outline" title="Eliminar" onClick={() => handleDelete(afiliado.id)}>
                        <i className="bi bi-trash"></i>
                      </button>
                      {afiliado.situacion.alias !== "preinscrito" && afiliado.situacion.alias !== "preinscrito2" && 
                      <button className="icon-button btn-secondary-outline" title="Cambiar situación" onClick={() => handleEdit(afiliado)}>
                       <i className="bi bi-arrow-left-right"></i>
                      </button>}
                      {afiliado.situacion.alias === "preinscrito2" && <button className="btn-sm btn-secondary-outline" onClick={() => handleInscripcion(afiliado)}>
                        Inscripción
                      </button>}
                    </td>}
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
          </>
        )}
       
      </>
  );
};

export default AfiliadoList;
