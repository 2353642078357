import React, { useEffect, useState } from 'react';
import { Modal, Table, Button } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { formatCurrency2 } from '../../util/valueCalculator';
import { fetchUsuarioById } from '../../reducers/usuario_slice';
import { modifyCajaDiaria } from '../../reducers/caja_slice';
import { fetchComprobantes, addComprobante } from '../../reducers/comprobante_slice';
import Swal from 'sweetalert2';
import ImprimirCajaDiaria from './ImprimirCajaDiaria'; // Importa el componente ImprimirCajaDiaria

const CajaDiariaCierre = ({ show, handleClose, cajaDiaria, detallesRecibo, totals }) => {
  const dispatch = useDispatch();
  const comprobantes = useSelector((state) => state.comprobante.comprobantes || []);
  const [usuario, setUsuario] = useState(null);
  const [showPrintModal, setShowPrintModal] = useState(false); // Estado para controlar el modal de impresión

  useEffect(() => {
    if (cajaDiaria.id_usuario) {
      dispatch(fetchComprobantes());
      dispatch(fetchUsuarioById(cajaDiaria.id_usuario))
        .then((response) => {
          if (response.payload) {
            setUsuario(response.payload);
          }
        })
        .catch((error) => {
          console.error("Error al obtener el usuario:", error);
        });
    }
  }, [cajaDiaria.id_usuario, dispatch]);

  const nombreCompletoPersona = usuario && usuario.persona
    ? `${usuario.persona.nombre} ${usuario.persona.apellido1} ${usuario.persona.apellido2}`
    : 'Cargando...';

  const nusuario = usuario ? usuario.usuario : '';

  const handleCloseCaja = () => {
    Swal.fire({
      title: '¿Está seguro?',
      text: 'Este es un proceso irreversible. ¿Desea continuar?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sí, cerrar caja',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.isConfirmed) {
        const currentHour = new Date().toLocaleTimeString('es-BO', { hour12: false });
        const fechaCierre = new Date().toISOString().split('T')[0]; // Fecha de cierre actual
  
        const nroComprobanteGenerado = generarNuevoNroComprobante();
        const today = new Date().toISOString().split('T')[0];
  
        const comprobanteCabecera = {
          nrocomprobante: nroComprobanteGenerado,
          gestion: new Date().getFullYear(),
          fecha: today,
          tipo_comprobante: 'TRASPASOS',
          glosa: `MOVIMIENTOS DIARIOS DE CAJA ${cajaDiaria.nrocaja}`,
          beneficiario: 'VARIOS',
          estado: 'BORRADOR',
        };
  
        const comprobanteDetalles = [
          {
            codigo_cuenta: cajaDiaria.numero_cuenta,
            glosa: 'INGRESOS EGRESOS VARIOS',
            debe: totals.ingresos,
            haber: totals.egresos,
          },
          ...detallesRecibo
            .filter(det => det.id_caja === cajaDiaria.id_caja && det.fecha === cajaDiaria.fechaAper)
            .map(det => ({
              codigo_cuenta: det.codigo_cuentaM,
              glosa: det.concepto,
              debe: det.tipo === 'EGRESO' ? det.pagado : 0,
              haber: det.tipo === 'INGRESO' ? det.pagado : 0,
            }))
        ];
  
        const comprobanteCompleto = {
          ...comprobanteCabecera,
          detalles: comprobanteDetalles,
        };
  
        const updatedCajaDiaria = {
          ...cajaDiaria,
          fechaCierre: fechaCierre,
          horaCierre: currentHour,
          ingresos: totals.ingresos,
          egresos: totals.egresos,
          comprobante: comprobanteCompleto,  // Adjuntar el comprobante completo
        };
  
        // Actualizar CajaDiaria con la fecha y hora de cierre y el comprobante
        dispatch(modifyCajaDiaria({ id: cajaDiaria.id, cajaDiaria: updatedCajaDiaria }))
          .then(() => {
            Swal.fire('Caja Cerrada', 'La caja ha sido cerrada correctamente y el comprobante ha sido generado.', 'success');
            handleClose();
          })
          .catch((error) => {
            Swal.fire('Error', 'No se pudo cerrar la caja o generar el comprobante.', 'error');
          });
      }
    });
  };

  const generarNuevoNroComprobante = () => {
    const currentYear = new Date().getFullYear();
    const comprobantesYear = comprobantes.filter(comp => comp.nrocomprobante.startsWith(`${currentYear}-`));
    const ultimoComprobante = comprobantesYear.reduce((max, comp) => {
      const num = parseInt(comp.nrocomprobante.split('-')[1], 10);
      return num > max ? num : max;
    }, 0);

    const nuevoNumero = (ultimoComprobante + 1).toString().padStart(5, '0');
    return `${currentYear}-${nuevoNumero}`;
  };

  return (
    <>
      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Cierre de Caja</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <p><strong>Fecha:</strong> {cajaDiaria.fechaAper}</p>
            <p><strong>Caja:</strong> {cajaDiaria.nrocaja}</p>
            <p><strong>Usuario:</strong> {nombreCompletoPersona} ({nusuario})</p>
          </div>
          <p><strong>Monto Inicial:</strong> {formatCurrency2(cajaDiaria.montoInicial)}</p>
          <p><strong>Total Ingresos:</strong> {formatCurrency2(totals.ingresos)}</p>
          <p><strong>Total Egresos:</strong> {formatCurrency2(totals.egresos)}</p>
          <p><strong>Saldo:</strong> {formatCurrency2(totals.saldo)}</p>
          <Table bordered hover>
            <thead>
              <tr>
                <th>Nro Recibo</th>
                <th>Concepto</th>
                <th>Ingreso</th>
                <th>Egreso</th>
              </tr>
            </thead>
            <tbody>
              {detallesRecibo
                .filter(det => det.id_caja === cajaDiaria.id_caja && det.fecha === cajaDiaria.fechaAper)
                .map((detalle, index) => (
                  <tr key={index}>
                    <td>{detalle.nroRecibo}</td>
                    <td>{detalle.concepto}</td>
                    <td>{detalle.tipo === 'INGRESO' ? formatCurrency2(detalle.pagado) : ''}</td>
                    <td>{detalle.tipo === 'EGRESO' ? formatCurrency2(detalle.pagado) : ''}</td>
                  </tr>
              ))}
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleClose}>Cancelar</Button>
          <Button variant="success" onClick={handleCloseCaja}>Cerrar Caja</Button>
          <Button variant="primary" onClick={() => setShowPrintModal(true)}>Imprimir</Button>
        </Modal.Footer>
      </Modal>

      {showPrintModal && (
        <ImprimirCajaDiaria
          show={showPrintModal}
          handleClose={() => setShowPrintModal(false)}
          cajaDiaria={cajaDiaria}
          detallesRecibo={detallesRecibo}
          totals={totals}
          usuario={usuario}
        />
      )}
    </>
  );
};

export default CajaDiariaCierre;
