import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { addPais, modifyPais, fetchPaises } from '../../reducers/pais_slice';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Swal from 'sweetalert2';

const PaisForm = ({ currentPais, showModal, handleClose }) => {
  const [nombre, setNombre] = useState('');
  const [error, setError] = useState('');
  const [isModified, setIsModified] = useState(false); // Estado para rastrear modificaciones

  useEffect(() => {
    if (currentPais) {
      setNombre(currentPais.nombre);
      setIsModified(false); // Inicialmente, no se ha modificado nada
    } else {
      setNombre('');
      setIsModified(true); // Para un nuevo país, el botón debe estar habilitado
    }
  }, [currentPais]);

  const dispatch = useDispatch();

  const handleSubmit = e => {
    e.preventDefault();
    const paisData = { nombre: nombre ? nombre.toUpperCase() : null };
    setError('');
    if (currentPais) {
      dispatch(modifyPais({ id: currentPais.id, pais: paisData })).then(response => {
        if (response.error || response.payload.error) {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: response.payload.error || response.error.message || 'Error al actualizar el país',
          });
        } else {
          handleClose();
          resetForm();
          dispatch(fetchPaises());
        }
      });
    } else {
      dispatch(addPais(paisData)).then(response => {
        if (response.error || response.payload.error) {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: response.payload.error || response.error.message || 'Error al crear el país',
          });
        } else {
          handleClose();
          resetForm();
          dispatch(fetchPaises());
        }
      });
    }
  };

  const resetForm = () => {
    setNombre('');
    setError('');
  };

  const handleChange = e => {
    setNombre(e.target.value);
    setIsModified(e.target.value !== (currentPais ? currentPais.nombre : '')); // Compara con el valor original
  };

  let title = currentPais ? "Editar país" : "Nuevo país";
  let headerColor = currentPais ? '#748b39' : '#6CB1C2';
  let buttonText = currentPais ? 'Actualizar' : 'Crear';

  return (
    <Modal show={showModal} onHide={() => { handleClose(); resetForm(); }} centered>
      <Modal.Header closeButton style={{ backgroundColor: headerColor, color: 'white' }}>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="formNombrePais">
            <Form.Label>Nombre</Form.Label>
            <Form.Control
              type="text"
              placeholder="Nombre del País"
              value={nombre}
              onChange={handleChange}
              required
            />
          </Form.Group>
          {error && <p className="text-danger">{error}</p>}
          <div className="d-flex justify-content-between mt-5">
            <Button variant="outline-dark" onClick={() => { handleClose(); resetForm(); }}>
              Cancelar
            </Button>
            <button className="button-mine btn-mine-primary-outline" type="submit" disabled={currentPais && !isModified}>
              {buttonText}
            </button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default PaisForm;
