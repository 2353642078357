import React, { useEffect, useState } from 'react';
import { Form, Table } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { addComprobante, fetchComprobanteByNroWithDetails } from '../../reducers/comprobante_slice';
import { fetchPlanCuentas } from '../../reducers/planCuentas_slice';
import CustomPrintModal from '../UI/CustomPrintModal3';
import { getLocalDate, splitGlosa, formatCurrency } from '../../util/valueCalculator'; // Importamos la nueva función

const ComprobanteForm = ({ currentComprobante, nroComprobanteGenerado, handleClose }) => {
  const dispatch = useDispatch();
  const cuentas = useSelector((state) => state.planCuentas?.planCuentas || []);
  const isNuevoComprobante = !currentComprobante;

  const [fecha, setFecha] = useState(currentComprobante?.fecha || getLocalDate());
  const [gestion, setGestion] = useState(isNuevoComprobante ? new Date().getFullYear() : currentComprobante?.gestion || '');
  const [tipoComprobante, setTipoComprobante] = useState(currentComprobante?.tipo_comprobante || 'INGRESOS');
  const [glosa, setGlosa] = useState(currentComprobante?.glosa || '');
  const [beneficiario, setBeneficiario] = useState(currentComprobante?.beneficiario || ''); // Nuevo campo de beneficiario
  const [detalles, setDetalles] = useState([]);
  const [debeTotal, setDebeTotal] = useState(0);
  const [haberTotal, setHaberTotal] = useState(0);
  const [estadoComprobante, setEstadoComprobante] = useState(currentComprobante?.estado || 'BORRADOR');

  const [isPrintEnabled, setIsPrintEnabled] = useState(!isNuevoComprobante);
  const [showPrintModal, setShowPrintModal] = useState(false);
  const [printContent, setPrintContent] = useState({ head: [], body: [] });

  useEffect(() => {
    dispatch(fetchPlanCuentas());
  }, [dispatch]);

  useEffect(() => {
    if (currentComprobante && cuentas.length > 0) {
      dispatch(fetchComprobanteByNroWithDetails(currentComprobante.nrocomprobante)).then((res) => {
        const details = res?.payload?.detalles || [];
        setDetalles(
          details.length > 0
            ? details.map((detalle) => {
                const cuentaEncontrada = cuentas.find(cuenta => cuenta.codigo_cuenta === detalle.codigo_cuenta && cuenta.tipo_cuenta === 'DETALLE');
                return {
                  ...detalle,
                  nombreCuenta: cuentaEncontrada ? cuentaEncontrada.nombre_cuenta : 'No disponible'
                };
              })
            : [{ codigo_cuenta: '', nombreCuenta: '', glosa: '', debe: '', haber: '' }]
        );
      });
    } else if (!currentComprobante) {
      setDetalles([{ codigo_cuenta: '', nombreCuenta: '', glosa: '', debe: '0', haber: '0' }]);
    }
  }, [currentComprobante, cuentas, dispatch]);

  useEffect(() => {
    updateTotals(detalles);
  }, [detalles]);

  const updateTotals = (detalles) => {
    const debeSum = detalles.reduce((sum, d) => sum + (parseFloat(d.debe) || 0), 0);
    const haberSum = detalles.reduce((sum, d) => sum + (parseFloat(d.haber) || 0), 0);
    setDebeTotal(debeSum);
    setHaberTotal(haberSum);
  };

  const isEditable = estadoComprobante !== 'REGISTRADO';

  const handleSave = (status) => {
    if (!glosa.trim()) {
      Swal.fire('Error', 'La glosa no puede estar vacía', 'error');
      return;
    }

    if (!beneficiario.trim()) {
      Swal.fire('Error', 'El beneficiario no puede estar vacío', 'error');
      return;
    }

    const comprobanteData = {
      nrocomprobante: currentComprobante ? currentComprobante.nrocomprobante : nroComprobanteGenerado,
      gestion: gestion,
      fecha: fecha,
      tipo_comprobante: tipoComprobante,
      glosa: glosa,
      beneficiario: beneficiario, // Incluimos el beneficiario
      estado: status,
      detalles: detalles
    };

    dispatch(addComprobante(comprobanteData))
      .then((response) => {
        if (response?.meta?.requestStatus === 'fulfilled') {
          Swal.fire('Éxito', 'El comprobante ha sido registrado correctamente', 'success');
          setEstadoComprobante(status);
          setIsPrintEnabled(true); // Habilitar impresión después de guardar
        } else {
          Swal.fire('Error', 'No se pudo registrar el comprobante', 'error');
        }
      })
      .catch(() => {
        Swal.fire('Error', 'Ocurrió un error al registrar el comprobante', 'error');
      });
  };

  const handleRegister = () => {
    const today = new Date().toISOString().split('T')[0];

    if (fecha > today) {
      Swal.fire('Error', 'La fecha no puede ser futura', 'error');
      return;
    }

    if (new Date(fecha).getFullYear() !== gestion) {
      Swal.fire('Error', 'La fecha pertenece a otra gestión', 'error');
      return;
    }

    if (!glosa.trim()) {
      Swal.fire('Error', 'La glosa no puede estar vacía', 'error');
      return;
    }

    if (!beneficiario.trim()) {
      Swal.fire('Error', 'El beneficiario no puede estar vacío', 'error');
      return;
    }

    if (detalles.some(det => !det.codigo_cuenta)) {
      Swal.fire('Error', 'Todos los detalles deben tener una cuenta válida', 'error');
      return;
    }

    if (detalles.some(det => (parseFloat(det.debe) <= 0 && parseFloat(det.haber) <= 0) || parseFloat(det.debe) < 0 || parseFloat(det.haber) < 0)) {
      Swal.fire('Error', 'Debe haber al menos un valor positivo en Debe o Haber, y no se permiten valores negativos', 'error');
      return;
    }

    if (debeTotal !== haberTotal || debeTotal <= 0 || haberTotal <= 0) {
      Swal.fire('Error', 'Las sumas de Debe y Haber deben ser iguales y mayores a 0', 'error');
      return;
    }

    // Alerta de confirmación antes de grabar
    Swal.fire({
      title: '¿Estás seguro?',
      text: "Este proceso será irreversible",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Sí, continuar',
      cancelButtonText: 'Cancelar',
    }).then((result) => {
      if (result.isConfirmed) {
        handleSave('REGISTRADO');
      }
    });
  };

  const addNewDetail = () => {
    setDetalles([...detalles, { codigo_cuenta: '', nombreCuenta: '', glosa: '', debe: '0', haber: '0' }]);
    setIsPrintEnabled(false); // Inhabilitar impresión al agregar nuevo detalle
  };

  const removeDetail = (index) => {
    const updatedDetails = [...detalles];
    updatedDetails.splice(index, 1);
    setDetalles(updatedDetails);
    updateTotals(updatedDetails);
    setIsPrintEnabled(false); // Inhabilitar impresión si se elimina un detalle
  };

  const { glosa1, glosa2, glosa3 } = splitGlosa(glosa);

  const handlePrint = () => {
    if (!isPrintEnabled) {
      Swal.fire('Error', 'Impresión no disponible. Debes guardar o registrar el comprobante primero.', 'error');
      return;
    }

    // Preparar títulos y contenido para la impresión
    const title1 = `COMPROBANTE ${estadoComprobante === 'BORRADOR' ? 'BORRADOR' : ''}`;
    const title2 = `Nro. Comprobante: ${currentComprobante ? currentComprobante.nrocomprobante : nroComprobanteGenerado}`;
    const title3 = `Fecha: ${fecha}       Tipo: ${tipoComprobante}`;
    const title4 = `Glosa: ${glosa}`;
    const total1 = `TOTALES       ${formatCurrency(debeTotal)}       ${formatCurrency(haberTotal)}`;

    const content = {
      head: [['Código', 'Nombre de Cuenta', 'Glosa', 'Debe', 'Haber']],
      body: detalles.map((d) => [d.codigo_cuenta, d.nombreCuenta, d.glosa, formatCurrency(d.debe), formatCurrency(d.haber)])
    };

    setPrintContent(content);
    setShowPrintModal(true);
  };

  const handleDetailChange = (index, field, value) => {
    const updatedDetails = [...detalles];
    if (field === 'codigo_cuenta') {
      const cuentaEncontrada = cuentas.find(cuenta => cuenta.codigo_cuenta === value && cuenta.tipo_cuenta === 'DETALLE');
      if (cuentaEncontrada) {
        updatedDetails[index] = { ...updatedDetails[index], codigo_cuenta: value, nombreCuenta: cuentaEncontrada.nombre_cuenta };
      } else {
        Swal.fire('Error', 'Debe seleccionar una cuenta de tipo DETALLE', 'error');
      }
    } else {
      updatedDetails[index][field] = value;
    }
    setDetalles(updatedDetails);
    updateTotals(updatedDetails);
    if (estadoComprobante === 'BORRADOR') {
      setIsPrintEnabled(false); // Inhabilitar impresión si se realiza algún cambio en BORRADOR
    }
  };

  const handleGlosaChange = (e) => {
    setGlosa(e.target.value);
    setIsPrintEnabled(false);
  };

  const handleFechaChange = (e) => {
    setFecha(e.target.value);
    setIsPrintEnabled(false);
  };

  const handleTipoComprobanteChange = (e) => {
    setTipoComprobante(e.target.value);
    setIsPrintEnabled(false);
  };

  const handleCloseForm = () => {
    handleClose();
  };

  return (
    <div className="comprobante-form">
      <h3 className="text-center font-weight-bold">
        {estadoComprobante === 'BORRADOR' ? 'B O R R A D O R' : 'COMPROBANTE'} NRO: {currentComprobante ? currentComprobante.nrocomprobante : nroComprobanteGenerado}
      </h3>

      <Form>
        <div className="d-flex justify-content-between">
          <Form.Group>
            <Form.Label>Fecha</Form.Label>
            <Form.Control
              type="date"
              value={fecha}
              onChange={(e) => {
                if (isEditable) handleFechaChange(e);
                if (isNuevoComprobante) {
                  setGestion(new Date(e.target.value).getFullYear());
                }
              }}
              disabled={!isEditable}
              required
            />
          </Form.Group>

          <Form.Group>
            <Form.Label>TIPO DE COMPROBANTE</Form.Label>
            <Form.Control
              as="select"
              value={tipoComprobante}
              onChange={(e) => isEditable && handleTipoComprobanteChange(e)}
              disabled={!isEditable}
            >
              <option value="INGRESOS">INGRESOS</option>
              <option value="EGRESOS">EGRESOS</option>
              <option value="TRASPASOS">TRASPASOS</option>
            </Form.Control>
          </Form.Group>
        </div>

        <Form.Group>
          <Form.Label>Beneficiario</Form.Label>
          <Form.Control
            type="text"
            value={beneficiario}
            onChange={(e) => setBeneficiario(e.target.value)} // Manejar el valor del beneficiario
            placeholder="Ingrese el nombre del beneficiario"
            required
          />
        </Form.Group>

        <Form.Group>
          <Form.Label>GLOSA:</Form.Label>
          <Form.Control
            as="textarea"
            value={glosa}
            onChange={(e) => isEditable && handleGlosaChange(e)}
            placeholder="Descripción del comprobante"
            disabled={!isEditable}
            required
          />
        </Form.Group>

        <h5>Detalle del Comprobante</h5>
        <Table bordered>
          <thead>
            <tr>
              <th>Código de Cuenta</th>
              <th>Nombre de Cuenta</th>
              <th>Glosa</th>
              <th>Debe</th>
              <th>Haber</th>
              <th>Acciones</th>
            </tr>
          </thead>
          <tbody>
            {detalles.map((detalle, index) => (
              <tr key={index}>
                <td>
                  <Form.Control
                    as="select"
                    value={detalle.codigo_cuenta}
                    onChange={(e) => isEditable && handleDetailChange(index, 'codigo_cuenta', e.target.value)}
                    disabled={!isEditable}
                  >
                    <option value="">Seleccione Cuenta</option>
                    {cuentas
                      .filter((cuenta) => cuenta.tipo_cuenta === 'DETALLE')
                      .map((cuenta) => (
                        <option key={cuenta.id} value={cuenta.codigo_cuenta}>
                          {cuenta.codigo_cuenta.padEnd(18, '\u00A0') + cuenta.nombre_cuenta}
                        </option>
                      ))}
                  </Form.Control>
                </td>
                <td>{detalle.nombreCuenta || 'Nombre no disponible'}</td>
                <td>
                  <Form.Control
                    type="text"
                    value={detalle.glosa}
                    onChange={(e) => isEditable && handleDetailChange(index, 'glosa', e.target.value)}
                    disabled={!isEditable}
                  />
                </td>
                <td>
                  <Form.Control
                    type="number"
                    value={detalle.debe}
                    onChange={(e) => isEditable && handleDetailChange(index, 'debe', e.target.value)}
                    disabled={!isEditable}
                  />
                </td>
                <td>
                  <Form.Control
                    type="number"
                    value={detalle.haber}
                    onChange={(e) => isEditable && handleDetailChange(index, 'haber', e.target.value)}
                    disabled={!isEditable}
                  />
                </td>
                <td>
                  {isEditable && detalles.length > 1 && (
                    <button type="button" className="icon-button" onClick={() => removeDetail(index)}>
                      <i className="bi bi-trash"></i>
                    </button>
                  )}
                  {isEditable && index === detalles.length - 1 && (
                    <button className="icon-button" onClick={addNewDetail} disabled={!detalle.codigo_cuenta}>
                      <i className="bi bi-plus-circle"></i>
                    </button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>

        <div className="totales mt-4">
          <strong>Debe Total: </strong> {formatCurrency(debeTotal)}<br />
          <strong>Haber Total: </strong> {formatCurrency(haberTotal)}
        </div>
        <div className="list-container-header mt-4">
          <div className="left-buttons">
            <button title="Volver" onClick={handleCloseForm} className="icon-button btn-transparent">
              <i className="bi bi-arrow-left"></i>
            </button>
            <button
              type="button"
              title="Imprimir"
              className="icon-button btn-transparent"
              onClick={handlePrint}
              disabled={!isPrintEnabled}
            >
              <i className="bi bi-printer"></i>
            </button>
          </div>
          <div className="right-buttons">
            {(isNuevoComprobante || estadoComprobante === 'BORRADOR') && (
              <>
                <button type="button" className="button-mine btn-mine-primary-outline" onClick={() => handleSave('BORRADOR')} disabled={!isEditable}>
                  Pre-grabar
                </button>
                <button type="button" className="button-mine btn-secondary-outline" onClick={handleRegister} disabled={!isEditable}>
                  <i className="bi bi-check-circle"></i> Registrar
                </button>
              </>
            )}
          </div>
        </div>
      </Form>

      {showPrintModal && (
        <CustomPrintModal
          show={showPrintModal}
          handleClose={() => setShowPrintModal(false)}
          title1={`COMPROBANTE ${estadoComprobante === 'BORRADOR' ? 'BORRADOR' : ''}`}
          title2={`Nro.: ${currentComprobante ? currentComprobante.nrocomprobante : nroComprobanteGenerado}     Fecha: ${fecha}       Tipo: ${tipoComprobante} `}
          title4={`Glosa: ${glosa1}`}
          title5={ `${glosa2} `}  
          title3={ `Beneficiario: ${beneficiario} `}  
          content={printContent}
          total1={`TOTALES                                                                                    ${formatCurrency(debeTotal)}             ${formatCurrency(haberTotal)}`}
        />
      )}
    </div>
  );
};

export default ComprobanteForm;
