import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import apiService from "../services/apiService"; // Importa el servicio actualizado

// Crear una instancia del servicio para la entidad "personas"
const personaService = apiService('/personas');

// Thunks para las operaciones CRUD
export const fetchPersonas = createAsyncThunk('persona/fetchPersonas', async () => {
  const response = await personaService.getAll();
  //console.log("slice persona=",response)
  return response || [];
});

export const fetchPersonaById = createAsyncThunk('persona/fetchPersonaById', async (id) => {
  const response = await personaService.getById(id);
  return response;
});

export const addPersona = createAsyncThunk('persona/addPersona', async (persona, { rejectWithValue }) => {
  try {
    const response = await personaService.createWithFormData(persona);
    return response;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const modifyPersona = createAsyncThunk('persona/modifyPersona', async ({ id, persona }, { rejectWithValue }) => {
  try {
    const response = await personaService.updateWithFormData(id, persona);
    return response;
  }  catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const removePersona = createAsyncThunk('persona/removePersona', async (id, { rejectWithValue }) => {
  try {
    await personaService.remove(id);
    return id;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

// Estado inicial del slice
const initialState = {
  personas: [],
  sortedFilteredList: null ,
  current: null,
  statusSend: 'idle',
  statusGet:'idle',
  error: {
    status: null,
    messages:[]
  },
};

// Definición del slice
const personaSlice = createSlice({
  name: 'persona',
  initialState,
  reducers: {
    setListPersonas(state, action) {
      console.log("setListPersonas sortedFilteredList",action.payload)
      state.sortedFilteredList = action.payload;
    },
    setCurrentPersona(state, action) {
      state.current = action.payload;
    },
    resetStatusSend(state) {
        state.statusSend = "idle";
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPersonas.fulfilled, (state, action) => {
        state.personas = action.payload;
        state.statusGet = 'idle';
      })
      .addCase(fetchPersonas.pending, (state) => {
        state.statusGet = 'loading';
      })
      .addCase(fetchPersonas.rejected, (state, action) => {
        state.statusGet = 'failed';
        state.error = action.error.message;
      })
      .addCase(fetchPersonaById.fulfilled, (state, action) => {
        state.current = action.payload;
        state.statusGet = 'idle';
      })
      .addCase(addPersona.fulfilled, (state, action) => {
        state.personas.push(action.payload);
        state.current = action.payload;
        state.statusSend = 'idle';
      })
      .addCase(addPersona.pending, (state) => {
        state.statusSend = 'sending';
      })
      .addCase(addPersona.rejected, (state, action) => {
        state.statusSend = 'failed';
        state.error = action.payload || action.error.message;
      })
      .addCase(modifyPersona.fulfilled, (state, action) => {
        const index = state.personas.findIndex(persona => persona.id === action.payload.id);
        if (index !== -1) {
          state.personas[index] = action.payload;
        }
        state.current = action.payload;
        state.statusSend = 'idle';
      })
      .addCase(modifyPersona.pending, (state, action) => {
        state.statusSend = 'sending';
      })
      .addCase(modifyPersona.rejected, (state, action) => {
        state.statusSend = 'failed';
        state.error = action.payload.error || action.error.message;
      })
      .addCase(removePersona.fulfilled, (state, action) => {
        state.personas = state.personas.filter(persona => persona.id !== action.payload);
        state.statusSend = 'idle';
      })
      .addCase(removePersona.pending, (state) => {
        state.statusSend = 'sending';
      })
      .addCase(removePersona.rejected, (state, action) => {
        state.statusSend = 'failed';
        state.error = action.payload || action.error.message;
      });
  }
});

export const personaActions = personaSlice.actions;
export default personaSlice.reducer;
