import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import apiService from "../services/apiService";

const valoradoVenService = apiService('/valorado-vens');

export const fetchValoradoVens = createAsyncThunk('valoradoVen/fetchValoradoVens', async () => {
    const response = await valoradoVenService.getAll();
    return response || [];
});

export const addValoradoVen = createAsyncThunk('valoradoVen/addValoradoVen', async (valoradoVen, { rejectWithValue }) => {
    try {
        const response = await valoradoVenService.create(valoradoVen);
        return response;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});

const initialState = {
    valoradoVens: [],
    statusSend: 'idle',
    error: null,
};

const valoradoVenSlice = createSlice({
    name: 'valoradoVen',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchValoradoVens.fulfilled, (state, action) => {
                state.valoradoVens = action.payload;
            })
            .addCase(addValoradoVen.fulfilled, (state, action) => {
                state.valoradoVens.push(action.payload);
            });
    }
});

export default valoradoVenSlice.reducer;
