import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { addSituacion, modifySituacion, fetchSituaciones } from '../../reducers/situacion_slice';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Swal from 'sweetalert2';
import { formatearDecimal } from '../../util/customFormatter';

const initialState = {
  nombre: "",
  descripcion:"",
  cuota_cat:"",
  cuota_cab:""
}

const initialFormDetalles = {title:"Nueva situación",
  headerColor:"#6CB1C2", 
  buttonText:"Crear" }

const FormSituacion = ({ currentSituacion, showModal, handleClose }) => {
  const dispatch = useDispatch();
  const [datos, setDatos] = useState(initialState);
  const [error, setError] = useState('');
  const [isModified, setIsModified] = useState(false);
  const [formDetalles, setFormDetalles] = useState(initialFormDetalles);

  useEffect(() => {
    if(showModal)
   { if (currentSituacion) {

      setFormDetalles({title:"Editar situación",headerColor:"#748b39", buttonText:"Actualizar" })
      const {alias, ...situacion} = currentSituacion
      setDatos({...situacion, 
        cuota_cat: situacion.cuota_cat === "0.00"? "" : formatearDecimal(situacion.cuota_cat),
        cuota_cab:  situacion.cuota_cab === "0.00"? "" :formatearDecimal(situacion.cuota_cab),
      });
      setIsModified(false);
    } else {
      resetForm();
    }}
  }, [currentSituacion,showModal]);

  const handleSubmit = e => {
    e.preventDefault();
    const situacionData = {
      nombre: datos.nombre ? datos.nombre.toUpperCase() : null,
      descripcion: datos.descripcion ? datos.descripcion : null,
      cuota_cab: formatearDecimal(datos.cuota_cab),
      cuota_cat: formatearDecimal(datos.cuota_cat),
    };
    setError('');
    if (currentSituacion) {

      dispatch(modifySituacion({ id: currentSituacion.id, situacion: situacionData })).then(response => {
        if (response.error || response.payload.error) {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: response.payload.error || response.error.message || 'Error al actualizar el situacion',
          });
        } else {
          handleCloseForm()
          dispatch(fetchSituaciones());
        }
      });
    } else {
      dispatch(addSituacion(situacionData)).then(response => {
        if (response.error || response.payload.error) {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: response.payload.error || response.error.message || 'Error al crear el situacion',
          });
        } else {
          handleCloseForm()
          dispatch(fetchSituaciones());
        }
      });
    }
  };

  const resetForm = () => {
    setDatos(initialState);
    setFormDetalles(initialFormDetalles);
    setIsModified(false);
  };

  const handleCloseForm = () =>{
    handleClose();
  }
  const handleChange = e => {
    const { name, value } = e.target;
    setDatos((prevDatos) => ({ ...prevDatos, 
      [name]: value
    }));
    setIsModified(true);
  };

  const handleFormat = e => {
    const { name, value } = e.target;
    const valueFormatted = name === 'cuota_cat' || name === 'cuota_cab' ? formatearDecimal(value) : value 
    setDatos((prevDatos) => ({ ...prevDatos, 
      [name]: valueFormatted
    }));
    setIsModified(true);
  };



  return (
    <Modal show={showModal} onHide={handleCloseForm} centered>
      <Modal.Header closeButton style={{ backgroundColor: formDetalles.headerColor, color: 'white' }}>
        <Modal.Title>{formDetalles.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group className='row'>
            <div className='col-12'>
            <Form.Label>Nombre</Form.Label>
            <Form.Control
              type="text"
              placeholder="Nombre de la situación"
              name="nombre"
              value={datos.nombre}
              onChange={handleChange}
              //disabled={currentSituacion?.extra === false}
              required
            />
            </div>
            <div className='col-12 mt-3'>
            <Form.Label>Descripción</Form.Label>
            <Form.Control
              type="text"
              placeholder="Descripcion de la situación"
              name="descripcion"
              value={datos.descripcion}
              onChange={handleChange}
              //disabled={currentSituacion?.extra === false}
              required
            />
              </div>
              <div className='col-12 mt-3'>
                <Form.Label>Cuota CAT</Form.Label>
                <Form.Control
                  type="number"
                  step="0.01"
                  placeholder="0,00"
                  name="cuota_cat"
                  value={datos.cuota_cat}
                  onChange={handleChange}
                  onBlur={handleFormat}
                />
              </div>

              {/* Input for CAB quota */}
              <div className='col-12 mt-3'>
                <Form.Label>Cuota CAB</Form.Label>
                <Form.Control
                  type="number"
                  step="0.01"
                  placeholder="0,00"
                  name="cuota_cab"
                  value={datos.cuota_cab}
                  onChange={handleChange}
                  onBlur={handleFormat}
                />
              </div>
          </Form.Group>
        
          {error && <p className="text-danger">{error}</p>}
          <div className="d-flex justify-content-between mt-5">
            <button className="button-mine btn-cancel-outline" type="button" onClick={handleCloseForm}>
              Cancelar
            </button>
            <button className="button-mine btn-mine-primary-outline" type="submit" disabled={currentSituacion && !isModified}>
              {formDetalles.buttonText}
            </button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default FormSituacion;
