import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addPropietario, modifyPropietario, fetchPropietarios } from '../../reducers/propietario_slice';
import { fetchAfiliados } from '../../reducers/afiliado_slice';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Swal from 'sweetalert2';
import { Alert, Col, Row } from 'react-bootstrap';
import ActionButton from '../UI/Buttons/ActionButton';
import AfiliadoList from './AfiliadoList';
import { fetchSituaciones } from '../../reducers/situacion_slice';

const initialState = {
    id_afiliado:"",
    afiliado:null
}

const SelectAfiliado = ({onSelect, afiliado, labelText, excludeList=null,simpleAfiliado=null}) => {
    const dispatch = useDispatch();
    const [datos, setDatos] = useState(initialState);
    const situaciones = useSelector(state => state.situacion.situaciones);
 
    const handleSelectAfiliado = (afiliado) => {
        const afiliadoId=afiliado? afiliado.id : ""
        setDatos({ ...datos, id_afiliado: afiliadoId, afiliadoSeleccionado:afiliado });
        onSelect(labelText.toLowerCase(), afiliado)
    };
    useEffect(() => {
        dispatch(fetchAfiliados());
        dispatch(fetchSituaciones());
       
}, [dispatch]);
useEffect(() => {
    if(afiliado){
      setDatos({ ...datos, id_afiliado: afiliado.id, afiliadoSeleccionado: afiliado});
    }else{
        setDatos(initialState)
    }
  }, [afiliado]);

    const selectedSituaciones = situaciones.filter(s=> s.alias ==="activo" || s.alias ==="activo2" || s.alias ==="externo")
                                            .map(s=> ({label:s.nombre, value:s.id}))
    return (
        <>
                    <Row xs={1} className="mt-1">
                       <><Col className='col-form'>
                            <Form.Label>{labelText}
                                </Form.Label>
                    {datos.afiliadoSeleccionado ? (
                        <div className="selected-container p-3 border rounded shadow-sm">
                        <div>
                            <strong>{`${datos.afiliadoSeleccionado.persona.nombre} ${datos.afiliadoSeleccionado.persona.apellido1} ${datos.afiliadoSeleccionado.persona.apellido2}`}</strong> <br />
                            <span>C.I.: {datos.afiliadoSeleccionado.persona.ci}</span> 
                        </div>
                        <Button variant="link" onClick={() => {setDatos({ ...datos, id_afiliado: '',afiliadoSeleccionado:null});handleSelectAfiliado(null)}}>
                            Cambiar afiliado
                        </Button>
                        </div>
                    ) : (
                        <>
                        <div className="alert alert-info p-2">
                        <p>No se ha seleccionado un afiliado aún.</p>
                        </div>
                        {/* Aquí puedes renderizar la tabla de afiliados */}
                        <AfiliadoList onSelectAfiliado={handleSelectAfiliado} selectedSituaciones={selectedSituaciones}/>
                        </>
                    )} 
                        </Col>
                        </>
                       
                    </Row>
        </>
    );
};

export default SelectAfiliado;
