import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import apiService from "../services/apiService"; // Importa el servicio actualizado

// Crear una instancia del servicio para la entidad "inmuebles"
const inmuebleService = apiService('/inmuebles');

// Thunks para las operaciones CRUD
export const fetchInmuebles = createAsyncThunk('inmueble/fetchInmuebles', async () => {
  const response = await inmuebleService.getAll();
  return response || [];
});

export const fetchInmuebleById = createAsyncThunk('inmueble/fetchInmuebleById', async (id) => {
  const response = await inmuebleService.getById(id);
  return response;
});

export const addInmueble = createAsyncThunk('inmueble/addInmueble', async (inmueble, { rejectWithValue }) => {
  try {
    const response = await inmuebleService.create(inmueble);
    return response;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const modifyInmueble = createAsyncThunk('inmueble/modifyInmueble', async ({ id, inmueble }, { rejectWithValue }) => {
  try {
    const response = await inmuebleService.update(id, inmueble);
    return response;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const removeInmueble = createAsyncThunk('inmueble/removeInmueble', async (id, { rejectWithValue }) => {
  try {
    await inmuebleService.remove(id);
    return id;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

// Estado inicial del slice
const initialState = {
  inmuebles: [],
  sortedFilteredList: null,
  current: null,
  statusSend: 'idle',
  statusGet:'idle',
  error: {
    status: null,
    messages:[]
  },
};

// Definición del slice
const inmuebleSlice = createSlice({
  name: 'inmueble',
  initialState,
  reducers: {
    setSortedFilteredList(state, action) {
      state.sortedFilteredList = action.payload;
    },
    setCurrentInmueble(state, action) {
      state.current = action.payload;
    },
    resetStatusSend(state) {
        state.statusSend = "idle";
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchInmuebles.fulfilled, (state, action) => {
        state.inmuebles = action.payload;
        state.statusGet = 'idle';
      })
      .addCase(fetchInmuebles.pending, (state) => {
        state.statusGet = 'loading';
      })
      .addCase(fetchInmuebles.rejected, (state, action) => {
        state.statusGet = 'failed';
        state.error = action.error.message;
      })
      .addCase(fetchInmuebleById.fulfilled, (state, action) => {
        state.current = action.payload;
        state.statusGet = 'idle';
      })
      .addCase(addInmueble.fulfilled, (state, action) => {
        state.statusSend = 'idle';
      })
      .addCase(addInmueble.pending, (state) => {
        state.statusSend = 'sending';
      })
      .addCase(addInmueble.rejected, (state, action) => {
        state.statusSend = 'failed';
        state.error = action.payload || action.error.message;
      })
      .addCase(modifyInmueble.fulfilled, (state, action) => {
        state.statusSend = 'idle';
      })
      .addCase(modifyInmueble.pending, (state, action) => {
        state.statusSend = 'sending';
      })
      .addCase(modifyInmueble.rejected, (state, action) => {
        state.statusSend = 'failed';
        state.error = action.payload.error || action.error.message;
      })
      .addCase(removeInmueble.fulfilled, (state, action) => {
        state.inmuebles = state.inmuebles.filter(inmueble => inmueble.id !== action.payload);
        state.statusSend = 'idle';
      })
      .addCase(removeInmueble.pending, (state) => {
        state.statusSend = 'sending';
      })
      .addCase(removeInmueble.rejected, (state, action) => {
        state.statusSend = 'failed';
        state.error = action.payload || action.error.message;
      });
  }
});

export const inmuebleActions = inmuebleSlice.actions;

export default inmuebleSlice.reducer;
