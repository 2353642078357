import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import apiService from "../services/apiService";

// Crear una instancia del servicio para la entidad "paises"
const paisService = apiService('/paises');

// Thunks para las operaciones CRUD
export const fetchPaises = createAsyncThunk('pais/fetchPaises', async () => {
  const response = await paisService.getAll();
  return response || [];
});

export const fetchPaisById = createAsyncThunk('pais/fetchPaisById', async (id) => {
  const response = await paisService.getById(id);
  return response;
});

export const addPais = createAsyncThunk('pais/addPais', async (pais, { rejectWithValue }) => {
  try {
    const response = await paisService.create(pais);
    return response;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const modifyPais = createAsyncThunk('pais/modifyPais', async ({ id, pais }, { rejectWithValue }) => {
  try {
    const response = await paisService.update(id, pais);
    return response;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const removePais = createAsyncThunk('pais/removePais', async (id, { rejectWithValue }) => {
  try {
    await paisService.remove(id);
    return id;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

// Estado inicial del slice
const initialState = {
  paises: [],
  current: null,
  status: 'idle',
  error: null,
};

// Definición del slice
const paisSlice = createSlice({
  name: 'pais',
  initialState,
  reducers: {
    setCurrentPais(state, action) {
      state.current = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPaises.fulfilled, (state, action) => {
        state.paises = action.payload;
      })
      .addCase(fetchPaises.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchPaises.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(fetchPaisById.fulfilled, (state, action) => {
        state.current = action.payload;
      })
      .addCase(addPais.fulfilled, (state, action) => {
        state.paises.push(action.payload);
      })
      .addCase(addPais.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(addPais.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload.error || action.error.message;
      })
      .addCase(modifyPais.fulfilled, (state, action) => {
        const index = state.paises.findIndex(pais => pais.id === action.payload.id);
        if (index !== -1) {
          state.paises[index] = action.payload;
        }
      })
      .addCase(modifyPais.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(modifyPais.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload.error || action.error.message;
      })
      .addCase(removePais.fulfilled, (state, action) => {
        state.paises = state.paises.filter(pais => pais.id !== action.payload);
      })
      .addCase(removePais.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(removePais.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload.error || action.error.message;
      });
  }
});

export const { setCurrentPais } = paisSlice.actions;

export default paisSlice.reducer;
