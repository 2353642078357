import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPlanCuentas } from '../../reducers/planCuentas_slice';
import Table from 'react-bootstrap/Table';

const BalanceGeneral = () => {
  const dispatch = useDispatch();
  const planCuentas = useSelector(state => state.planCuentas.planCuentas || []);
  const [activo, setActivo] = useState({ corriente: [], noCorriente: [] });
  const [pasivo, setPasivo] = useState({ corriente: [], noCorriente: [] });
  const [patrimonio, setPatrimonio] = useState([]);

  useEffect(() => {
    dispatch(fetchPlanCuentas());
  }, [dispatch]);

  useEffect(() => {
    if (planCuentas.length > 0) {
      const activos = planCuentas.filter(cuenta => cuenta.codigo_cuenta.startsWith('01')); // Activos
      const pasivos = planCuentas.filter(cuenta => cuenta.codigo_cuenta.startsWith('02')); // Pasivos
      const patrimonios = planCuentas.filter(cuenta => cuenta.codigo_cuenta.startsWith('03')); // Patrimonio

      const activoCorriente = activos.filter(cuenta => cuenta.codigo_cuenta.startsWith('0101')); // Activo Corriente
      const activoNoCorriente = activos.filter(cuenta => !cuenta.codigo_cuenta.startsWith('0101')); // Activo No Corriente

      const pasivoCorriente = pasivos.filter(cuenta => cuenta.codigo_cuenta.startsWith('0201')); // Pasivo Corriente
      const pasivoNoCorriente = pasivos.filter(cuenta => !cuenta.codigo_cuenta.startsWith('0201')); // Pasivo No Corriente

      setActivo({ corriente: activoCorriente, noCorriente: activoNoCorriente });
      setPasivo({ corriente: pasivoCorriente, noCorriente: pasivoNoCorriente });
      setPatrimonio(patrimonios);
    }
  }, [planCuentas]);

  const calcularTotal = cuentas => cuentas.reduce((total, cuenta) => total + parseFloat(cuenta.saldo || 0), 0);

  const formatSaldo = (saldo) => {
    const saldoNumerico = parseFloat(saldo);
    return isNaN(saldoNumerico) ? '0.00' : saldoNumerico.toFixed(2);
  };

  return (
    <div>
      <h2>Balance General</h2>
      <Table bordered>
        <thead>
          <tr>
            <th>Cuenta</th>
            <th>Saldo</th>
          </tr>
        </thead>
        <tbody>
          {/* Activo */}
          <tr>
            <td colSpan="2"><strong>Activo</strong></td>
          </tr>
          <tr>
            <td><strong>Activo Corriente</strong></td>
            <td></td>
          </tr>
          {activo.corriente.map(cuenta => (
            <tr key={cuenta.id}>
              <td>{cuenta.nombre_cuenta}</td>
              <td>{formatSaldo(cuenta.saldo)}</td>
            </tr>
          ))}
          <tr>
            <td><strong>Activo No Corriente</strong></td>
            <td></td>
          </tr>
          {activo.noCorriente.map(cuenta => (
            <tr key={cuenta.id}>
              <td>{cuenta.nombre_cuenta}</td>
              <td>{formatSaldo(cuenta.saldo)}</td>
            </tr>
          ))}
          <tr>
            <td><strong>Total Activo</strong></td>
            <td>{(calcularTotal(activo.corriente) + calcularTotal(activo.noCorriente)).toFixed(2)}</td>
          </tr>

          {/* Pasivo */}
          <tr>
            <td colSpan="2"><strong>Pasivo</strong></td>
          </tr>
          <tr>
            <td><strong>Pasivo Corriente</strong></td>
            <td></td>
          </tr>
          {pasivo.corriente.map(cuenta => (
            <tr key={cuenta.id}>
              <td>{cuenta.nombre_cuenta}</td>
              <td>{formatSaldo(cuenta.saldo)}</td>
            </tr>
          ))}
          <tr>
            <td><strong>Pasivo No Corriente</strong></td>
            <td></td>
          </tr>
          {pasivo.noCorriente.map(cuenta => (
            <tr key={cuenta.id}>
              <td>{cuenta.nombre_cuenta}</td>
              <td>{formatSaldo(cuenta.saldo)}</td>
            </tr>
          ))}
          <tr>
            <td><strong>Total Pasivo</strong></td>
            <td>{(calcularTotal(pasivo.corriente) + calcularTotal(pasivo.noCorriente)).toFixed(2)}</td>
          </tr>

          {/* Patrimonio */}
          <tr>
            <td colSpan="2"><strong>Patrimonio</strong></td>
          </tr>
          {patrimonio.map(cuenta => (
            <tr key={cuenta.id}>
              <td>{cuenta.nombre_cuenta}</td>
              <td>{formatSaldo(cuenta.saldo)}</td>
            </tr>
          ))}
          <tr>
            <td><strong>Total Patrimonio</strong></td>
            <td>{calcularTotal(patrimonio).toFixed(2)}</td>
          </tr>

          {/* Total Pasivo + Patrimonio */}
          <tr>
            <td><strong>Total Pasivo + Patrimonio</strong></td>
            <td>{(calcularTotal(pasivo.corriente) + calcularTotal(pasivo.noCorriente) + calcularTotal(patrimonio)).toFixed(2)}</td>
          </tr>
        </tbody>
      </Table>
    </div>
  );
};

export default BalanceGeneral;
