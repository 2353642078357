
const ActionButton = ({children, icon = "", outline = false, iconBtn =false, floatEnd=false, ...props}) => {
    let icono = ""
    let classButton = "button-mine btn-mine-primary"
    if(iconBtn) classButton = "icon-button btn-mine-primary"
    if(icon === 'plus'){
        icono = <i className="bi bi-plus-lg"></i>
    }
    if(outline) classButton += "-outline"

    if(floatEnd) classButton += " float-end" 
    
    return( <button className={`${classButton}`} {...props}>
        {icono} {children}
    </button>)
}

export default ActionButton;