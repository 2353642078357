import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import apiService from '../services/apiService';

const api = apiService('/afiliadorequisitos');

export const fetchAfiliadoRequisitos = createAsyncThunk('afiliadorequisito/fetchAfiliadoRequisitos', async (afiliadoId) => {
    const response = await api.getAllBySomeId('afiliado', afiliadoId);
    return response;
});

export const updateAfiliadoRequisito = createAsyncThunk('afiliadorequisito/updateAfiliadoRequisito', async ({ id, data }) => {
    const response = await api.updateWithFormData(id, data);
    return response;
});

const afiliadorequisitoSlice = createSlice({
    name: 'afiliadorequisito',
    initialState: {
        afiliadoRequisitos: [],
        status: 'idle',
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchAfiliadoRequisitos.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchAfiliadoRequisitos.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.afiliadoRequisitos = action.payload;
            })
            .addCase(fetchAfiliadoRequisitos.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            .addCase(updateAfiliadoRequisito.fulfilled, (state, action) => {
                const index = state.afiliadoRequisitos.findIndex(req => req.id === action.payload.id);
                if (index >= 0) {
                    state.afiliadoRequisitos[index] = action.payload;
                }
            });
    },
});

export default afiliadorequisitoSlice.reducer;
