import React from 'react';
import CustomPrintModal3 from '../UI/CustomPrintModal3';
import { formatCurrency2 } from '../../util/valueCalculator';

const ImprimirCajaDiaria = ({ show, handleClose, cajaDiaria, detallesRecibo, totals, usuario }) => {
    console.log("DEtalles recibo=",detallesRecibo)
    const formattedDate = cajaDiaria.fechaCierre;
    const nombreCompletoPersona = `${usuario.persona.apellido1} ${usuario.persona.apellido2} ${usuario.persona.nombre}`;

    const content = {
        head: [['Fecha', 'Concepto', 'Ingreso', 'Egreso']],
        body: detallesRecibo
            .filter(det => det.id_caja === cajaDiaria.id_caja && det.fecha === cajaDiaria.fechaAper)
            .map(detalle => [
                detalle.fecha,
                detalle.concepto,
                detalle.tipo === 'INGRESO' ? formatCurrency2(detalle.pagado) : '',
                detalle.tipo === 'EGRESO' ? formatCurrency2(detalle.pagado) : ''
            ])
    };

    return (
        <CustomPrintModal3
            show={show}
            handleClose={handleClose}
            title1="LIBRO DIARIO DE CAJA"
            title2={`FECHA: ${formattedDate}  CAJA: ${cajaDiaria.nrocaja}  USUARIO: ${nombreCompletoPersona}`}
            title3={`MONTO INICIAL: ${formatCurrency2(cajaDiaria.montoInicial)}  INGRESOS: ${formatCurrency2(totals.ingresos)}  EGRESOS: ${formatCurrency2(totals.egresos)}  SALDO: ${formatCurrency2(totals.saldo)}`}
            title4=""
            title5=""
            content={content}
        />
    );
};

export default ImprimirCajaDiaria;
