import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Swal from 'sweetalert2';
import { fetchLugares } from '../../reducers/lugar_slice';
import { fetchAfiliados, inscribirAfiliado, modifySituacionAfiliado } from '../../reducers/afiliado_slice';
import { fetchRegionesByPais } from '../../reducers/region_slice';

const initialState = {
    id_afiliado:"",
    num_reg: "",
    fecha_reg:"",
    id_region_reg_cab:"",
    id_lugar_reg_cat:"",
    situacion:"activo",
    comentario:"Inscripción completada",
  }

  const initialFormDetalles = {title:"Completar inscripción",
    headerColor:"#0080d5", 
    buttonText:"Completar" }
  
const InscripcionModal = ({ currentAfiliado, showModal, handleClose }) => {
    const dispatch = useDispatch();
  const [datos, setDatos] = useState(initialState);
  const [error, setError] = useState('');
  const [isModified, setIsModified] = useState(false);
  const [formDetalles, setFormDetalles] = useState(initialFormDetalles);
  const lugares = useSelector(state => state.lugar.lugares) || [];
  const regiones = useSelector(state => state.region.regiones) || [];

  useEffect(() => {
    if(showModal)
    { resetForm();
      dispatch(fetchLugares());
      dispatch(fetchRegionesByPais());
    }
  }, [showModal]);

  const handleSubmit = e => {
    e.preventDefault();
    const {fecha_reg, ...afiliadoData} = {
      ...datos,
      id_afiliado: currentAfiliado?.id,
      fecha_reg_cab: datos.fecha_reg,
      fecha_reg_cat: datos.fecha_reg,
    };
    setError('');
    dispatch(modifySituacionAfiliado({ id: currentAfiliado.id, afiliado: afiliadoData })).then(response => {
        if (response.error || response.payload.error) {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: response.payload.error || response.error.message || 'Error al actualizar la situacion',
          });
        } else {
          handleCloseForm()
          dispatch(fetchAfiliados());
        }
      });
   
  };

  const cambiarSituacionAfiliado = () => {
    const id = currentAfiliado.id
    const data = {
      situacion:"preinscrito",
      comentario:"Respuesta negativa del C.A.B."
    }
    dispatch(modifySituacionAfiliado({id, afiliado:data}))
    .then(data => dispatch(fetchAfiliados()))
    handleClose();
};
  const resetForm = () => {
    setDatos(initialState);
    setFormDetalles(initialFormDetalles);
    setIsModified(false);
  };

  const handleCloseForm = () =>{
    handleClose();
  }
  const handleChange = e => {
    const { name, value } = e.target;
    setDatos((prevDatos) => ({ ...prevDatos, 
      [name]: value
    }));
    setIsModified(true);
  };

    return (
        <Modal show={showModal} onHide={handleCloseForm}>
        <Modal.Header closeButton style={{ backgroundColor: formDetalles.headerColor, color: 'white' }}>
          <Modal.Title>{formDetalles.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit} className='normal-form'>
            <Form.Group className='row'>
            <div className='col-12'>
            <Form.Label>Número de registro</Form.Label>
            <Form.Control
              type="text"
              placeholder="N° de registro"
              name="num_reg"
              value={datos.num_reg}
              onChange={handleChange}
              required
            />
            </div>
            <div className='col-12 mt-3'>
            <Form.Label>Fecha de registro</Form.Label>
                    <Form.Control
                    type="date"
                    name="fecha_reg"
                    value={datos.fecha_reg}
                    onChange={handleChange}
                            />
                          </div>
                      <div className='col-12 mt-3'>
                            <Form.Label>Lugar de registro C.A.B. (Bolivia)</Form.Label>
                            <Form.Control
                                as="select"
                                name="id_region_reg_cab"
                                value={datos.id_region_reg_cab}
                                onChange={handleChange}
                                required
                            >
                                <option value="">Selecciona un lugar</option>
                                {regiones.map(region =>
                                 (<option key={region.id} value={region.id}>{`${region.nombre}`}</option>
                                ))}
                            </Form.Control>
                            </div>
                            <div className='col-12 mt-3'>
                            <Form.Label>Lugar de registro C.A.T. (Tarija)</Form.Label>
                            <Form.Control
                                as="select"
                                name="id_lugar_reg_cat"
                                value={datos.id_lugar_reg_cat}
                                onChange={handleChange}
                                required
                            >
                                <option value="">Selecciona un lugar</option>
                                {lugares.map(lugar =>
                                 (<option key={lugar.id} value={lugar.id}>{`${lugar.nombre}`}</option>
                                ))}
                            </Form.Control>
                            </div>
                            <div className='col-12 mt-3'>
                            <Form.Label>Situación</Form.Label>
                            <div>
                              <Form.Check
                                type="radio"
                                label="ACTIVO"
                                name="situacion"
                                value="activo"
                                checked={datos.situacion === "activo"}
                                onChange={handleChange}
                              />
                              <Form.Check
                                type="radio"
                                label="EXTERNO"
                                name="situacion"
                                value="externo"
                                checked={datos.situacion === "externo"}
                                onChange={handleChange}
                              />
                            </div>
                          </div>

              </Form.Group>
          
              {error && <p className="text-danger">{error}</p>}
          <div className="d-flex justify-content-end mt-5">
            <button className="button-mine btn-cancel-outline" type="button" onClick={handleCloseForm}>
              Cancelar
            </button>
            <button className="button-mine btn-light-blue-outline" type="button" onClick={cambiarSituacionAfiliado}>
            <i className="bi bi-arrow-left"></i> Respuesta negativa del C.A.B
            </button>
            <button className="button-mine btn-mine-primary-outline" type="submit" disabled={currentAfiliado && !isModified}>
            <i className="bi bi-check"></i> {formDetalles.buttonText}
            </button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
    );
};

export default InscripcionModal;
