import { configureStore } from '@reduxjs/toolkit';
import paisReducer from './pais_slice';
import sesionReducer from './session_slice';
import lugarReducer from './lugar_slice';
import regionReducer from './region_slice';
import personaReducer from './persona_slice';
import institucionReducer from './institucion_slice';
import requisitoReducer from './requisito_slice';
import situacionReducer from './situacion_slice';
import afiliadoReducer from './afiliado_slice';
import segSituAfiliadoReducer from './seg_situ_afiliado_slice';
import tituloProfesionalReducer from './tituloProfesional_slice';
import afiliadoRequisitoReducer from './afiliadoRequisito_slice';
import areaProyectoReducer from './areaProyecto_slice';
import categoriaReducer from './categoria_slice';
import inmuebleReducer from './inmueble_slice';
import tipoProyectoReducer from './tipoProyecto_slice';
import tipologiaReducer from './tipologia_slice';
import proyectoReducer from './proyecto_slice';
import zonaReducer from './zona_slice';
import propietarioReducer from './propietario_slice';
import unidadReducer from './unidad_slice';
import planCuentasReducer from './planCuentas_slice';
import comprobanteReducer from './comprobante_slice';
import valoradoReducer from './valorado_slice';
import valoradoDetReducer from './valoradoDet_slice';
import reciboReducer from './recibo_slice';
import cuotaReducer from './cuota_slice';
import bancoReducer from './banco_slice';
import depositoReducer from './deposito_slice';
import valoradoVenReducer from './valoradoVen_slice';
import ingresosVariosReducer from './ingresosVarios_slice';
import cajaReducer from './caja_slice';
import usuarioReducer from './usuario_slice';  // Agregar el reducer de usuario

const store = configureStore({
  reducer: {
    valoradoVen: valoradoVenReducer,
    valoradoDet: valoradoDetReducer,
    valorado: valoradoReducer,
    pais: paisReducer,
    sesion: sesionReducer,
    lugar: lugarReducer,
    unidad: unidadReducer,
    zona: zonaReducer,
    region: regionReducer,
    persona: personaReducer,
    propietario: propietarioReducer,
    institucion: institucionReducer,
    requisito: requisitoReducer,
    afiliado: afiliadoReducer,
    segSituAfiliado: segSituAfiliadoReducer,
    situacion: situacionReducer,
    tituloProfesional: tituloProfesionalReducer,
    afiliadorequisito: afiliadoRequisitoReducer,
    areaProyecto: areaProyectoReducer,
    categoria: categoriaReducer,
    inmueble: inmuebleReducer,
    tipoProyecto: tipoProyectoReducer,
    tipologia: tipologiaReducer,
    proyecto: proyectoReducer,
    planCuentas: planCuentasReducer,
    comprobante: comprobanteReducer,
    recibo: reciboReducer,
    cuota: cuotaReducer,  
    banco: bancoReducer,
    deposito: depositoReducer,
    ingresosVarios: ingresosVariosReducer,
    caja: cajaReducer,
    usuario: usuarioReducer,  // Añadir el nuevo reducer de usuario
  },
});

export default store;
