import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addCaja, modifyCaja } from '../../reducers/caja_slice';
import { fetchPlanCuentas } from '../../reducers/planCuentas_slice';
import { Form, Button, Modal } from 'react-bootstrap';
import Swal from 'sweetalert2';

const CajaForm = ({ caja, handleClose, show }) => {
  const dispatch = useDispatch();
  const cuentas = useSelector((state) => state.planCuentas?.planCuentas || []);
  const planCuentasStatus = useSelector((state) => state.planCuentas.status);

  const [formData, setFormData] = useState({
    nrocaja: '',
    ubicacion: '',
    numero_cuenta: ''
  });

  // Fetch plan de cuentas si no está en idle
  useEffect(() => {
    if (planCuentasStatus !== 'idle') {
      dispatch(fetchPlanCuentas());
    }
  }, [dispatch, planCuentasStatus]);

  useEffect(() => {
    if (caja) {
      setFormData(caja);
    } else {
      setFormData({
        nrocaja: '',
        ubicacion: '',
        numero_cuenta: ''
      });
    }
  }, [caja]);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (caja) {
      dispatch(modifyCaja({ id: caja.id, caja: formData })).then(() => {
        Swal.fire('Éxito', 'La caja ha sido actualizada correctamente', 'success');
        handleClose();
      });
    } else {
      dispatch(addCaja(formData)).then(() => {
        Swal.fire('Éxito', 'La caja ha sido creada correctamente', 'success');
        handleClose();
      });
    }
  };

  const cuentasFiltradas = cuentas.filter(
    (cuenta) => cuenta.tipo_cuenta === 'DETALLE'
  );

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{caja ? 'Editar Caja' : 'Nueva Caja'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="formNroCaja">
            <Form.Label>Nro Caja</Form.Label>
            <Form.Control
              type="text"
              name="nrocaja"
              value={formData.nrocaja}
              onChange={handleChange}
              required
            />
          </Form.Group>

          <Form.Group controlId="formUbicacion">
            <Form.Label>Ubicación</Form.Label>
            <Form.Control
              type="text"
              name="ubicacion"
              value={formData.ubicacion}
              onChange={handleChange}
              required
            />
          </Form.Group>

          {/* Selección de la cuenta contable */}
          <Form.Group controlId="formNumeroCuenta">
            <Form.Label>Número Cuenta</Form.Label>
            <Form.Control
              as="select"
              name="numero_cuenta"
              value={formData.numero_cuenta}
              onChange={handleChange}
              required
            >
              <option value="">Seleccione una cuenta</option>
              {cuentasFiltradas.map((cuenta) => (
                <option key={cuenta.id} value={cuenta.codigo_cuenta}>
                  {cuenta.nombre_cuenta}
                </option>
              ))}
            </Form.Control>
          </Form.Group>

          <Button variant="primary" type="submit">
            {caja ? 'Actualizar' : 'Guardar'}
          </Button>
          <Button variant="secondary" onClick={handleClose} className="ml-2">
            Cancelar
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default CajaForm;
