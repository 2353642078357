import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import apiService from "../services/apiService"; // Importa el servicio actualizado

// Crear una instancia del servicio para la entidad "propietarios"
const propietarioService = apiService('/propietarios');

// Thunks para las operaciones CRUD
export const fetchPropietarios = createAsyncThunk('propietario/fetchPropietarios', async () => {
  const response = await propietarioService.getAll();
  return response || [];
});

export const fetchPropietarioById = createAsyncThunk('propietario/fetchPropietarioById', async (id) => {
  const response = await propietarioService.getById(id);
  return response;
});

export const addPropietario = createAsyncThunk('propietario/addPropietario', async (propietario, { rejectWithValue }) => {
  try {
    const response = await propietarioService.create(propietario);
    return response;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const modifyPropietario = createAsyncThunk('propietario/modifyPropietario', async ({ id, propietario }, { rejectWithValue }) => {
  try {
    const response = await propietarioService.update(id, propietario);
    return response;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const removePropietario = createAsyncThunk('propietario/removePropietario', async (id, { rejectWithValue }) => {
  try {
    await propietarioService.remove(id);
    return id;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

// Estado inicial del slice
const initialState = {
  propietarios: [],
  current: null,
  statusSend: 'idle',
  statusGet:'idle',
  error: {
    status: null,
    messages:[]
  },
};

// Definición del slice
const propietarioSlice = createSlice({
  name: 'propietario',
  initialState,
  reducers: {
    setCurrentPropietario(state, action) {
      state.current = action.payload;
    },
    resetStatusSend(state) {
        state.statusSend = "idle";
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPropietarios.fulfilled, (state, action) => {
        state.propietarios = action.payload;
        state.statusGet = 'idle';
      })
      .addCase(fetchPropietarios.pending, (state) => {
        state.statusGet = 'loading';
      })
      .addCase(fetchPropietarios.rejected, (state, action) => {
        state.statusGet = 'failed';
        state.error = action.error.message;
      })
      .addCase(fetchPropietarioById.fulfilled, (state, action) => {
        state.current = action.payload;
        state.statusGet = 'idle';
      })
      .addCase(addPropietario.fulfilled, (state, action) => {
        state.statusSend = 'idle';
      })
      .addCase(addPropietario.pending, (state) => {
        state.statusSend = 'sending';
      })
      .addCase(addPropietario.rejected, (state, action) => {
        state.statusSend = 'failed';
        state.error = action.payload || action.error.message;
      })
      .addCase(modifyPropietario.fulfilled, (state, action) => {
        const index = state.propietarios.findIndex(propietario => propietario.id === action.payload.id);
        if (index !== -1) {
          state.propietarios[index] = action.payload;
        }
        state.statusSend = 'idle';
      })
      .addCase(modifyPropietario.pending, (state, action) => {
        state.statusSend = 'sending';
      })
      .addCase(modifyPropietario.rejected, (state, action) => {
        state.statusSend = 'failed';
        state.error = action.payload.error || action.error.message;
      })
      .addCase(removePropietario.fulfilled, (state, action) => {
        state.propietarios = state.propietarios.filter(propietario => propietario.id !== action.payload);
        state.statusSend = 'idle';
      })
      .addCase(removePropietario.pending, (state) => {
        state.statusSend = 'sending';
      })
      .addCase(removePropietario.rejected, (state, action) => {
        state.statusSend = 'failed';
        state.error = action.payload || action.error.message;
      });
  }
});

export const { setCurrentPropietario, resetStatusSend } = propietarioSlice.actions;

export default propietarioSlice.reducer;
