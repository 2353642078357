import { Container, Dropdown, Nav, Navbar } from 'react-bootstrap';
import logo from '../../assets/logo.png';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { sesionActions } from '../../reducers/session_slice';
import LogoutButton from '../autenticacion/LogoutButton';
import usuarioImage from '../../assets/usuario.png';
import adminSistemaMenu from '../../data_files/menus/admin_sistema_menu';
import menu from '../../data_files/menus/public_menu';
import React, { useState } from 'react';
const Header = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { nombre, rolActivo, roles } = useSelector(state => state.sesion);
  const [showDropdown, setShowDropdown] = useState(null);
  const handleMouseEnter = (index) => {
           setShowDropdown(index);
         };
    const handleMouseLeave = () => {
            setShowDropdown(null);
          };
  const handleRolChange = (rol) => {
    dispatch(sesionActions.changeRol(rol));
    navigate('/admin');
  };

  const getMenu = (rol) => {
    if (rol === 'admin') return adminSistemaMenu;
    // Otras opciones de menú según el rol
    return [];
  };

  const menus = nombre && rolActivo ? getMenu(rolActivo.alias) : menu;

  return (
    <header id='headerIn'>
      <Navbar expand="lg" className="navbar-dark" style={{ padding:"10px 50px",background: 'linear-gradient(to right, #2b1402,#8a6443)' }}>
          <Navbar.Brand>
            <Link to="/admin" className="brand-link d-flex align-items-center">
              <img
                alt="Logo CAT"
                src={logo}
                width="60"
                height="60"
                className="d-inline-block align-top"
              />
              <span className="brand-text">CAT</span>
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="principal-nav" />
          <Navbar.Collapse id="principal-nav">
            <Nav className="me-auto" id="nav-menu">
              {menus.map((item, index) => (
                <React.Fragment key={item.title}>
                  {item.tipo === 'button' && (
                    <Link className="nav-link bottomline_link" to={item.path} role="button">
                      {item.title}
                    </Link>
                  )}
                  {item.tipo === 'dropdown' && (
                    <Dropdown
                      show={showDropdown === index}
                      onMouseEnter={() => handleMouseEnter(index)}
                      onMouseLeave={handleMouseLeave}
                      className="nav-item"
                    >
                      <Dropdown.Toggle
                        className="nav-link"
                        as="a"
                        href="#"
                      >
                        {item.title}
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="dropdown-menu mb-2">
                      {item.sections.map((section, sectionIndex) =>(
                        <div key={section.name}>
                          {section.name !== "" && <p className="dropdown-header">{section.name}</p>}
                          {section.options.map((option, optionIndex) => (
                          <Dropdown.Item
                            as="a"
                            href={option.path}
                            key={option.name + '' + optionIndex}
                            className="dropdown-item bottomline_dropdown_item"
                          >
                            <i className={option.icon}></i> {option.name}
                          </Dropdown.Item>
                        ))}
                         {sectionIndex < item.sections.length-1 && <li>
                            <hr className="dropdown-divider" />
                          </li>}
                        </div>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown>
                  )}
                </React.Fragment>
              ))}
            </Nav>
        <Nav className="ms-auto d-flex align-items-center" id="nav-user">
              <li className="nav-item dropdown">
                <a
                  className="nav-link"
                  href="#"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <span> Rol: {rolActivo ? rolActivo.nombre : 'No tiene rol'}</span>
                </a>
                <ul className="dropdown-menu mb-2">
                  <p className="dropdown-header">Cambiar de rol</p>
                  {roles.map(rol => {
                    if (rol.alias === rolActivo.alias) { return null; }
                    return (
                      <li key={rol.alias}>
                        <button className="dropdown-item" onClick={() => handleRolChange(rol)}>
                          {rol.nombre}
                        </button>
                      </li>
                    );
                  })}
                </ul>
              </li>
              <li className="nav-item-usuario dropdown">
                <a
                  className="nav-link"
                  href="#"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <img src={usuarioImage} className='rounded-img' />
                </a>
                <ul className="dropdown-menu mb-2">
                  <li style={{ marginLeft: "15px", fontWeight: "bold", marginRight: "15px" }}>
                    {nombre ? nombre.toUpperCase() : ""}
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/perfil-usuario">
                      Perfil
                    </Link>
                  </li>
                  <li>
                    <LogoutButton className="dropdown-item" />
                  </li>
                </ul>
              </li>
            </Nav>
          </Navbar.Collapse>
      </Navbar>
    </header>
  );
}

export default Header;