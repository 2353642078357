import React, { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Table from 'react-bootstrap/Table';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import * as XLSX from 'xlsx';
import ActionButton from '../UI/Buttons/ActionButton';
import CustomPrintModal from '../UI/CustomPrintModal';
import { fetchSituaciones, removeSituacion } from '../../reducers/situacion_slice';
import FormSituacion from './FormSituacion';

const ListaSituaciones = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [currentSituacion, setCurrentSituacion] = useState(null);
  const [sortConfig, setSortConfig] = useState({ key: 'nombre', direction: 'asc' });
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [printContent, setPrintContent] = useState({ head: [], body: [] });
  const situaciones = useSelector(state => state.situacion.situaciones || []);
  useEffect(() => {
    dispatch(fetchSituaciones());
  }, [dispatch]);

  const handleDelete = id => {
    Swal.fire({
      title: '¿Estás seguro?',
      text: "¡No podrás revertir esto!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Sí, eliminarlo!'
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(removeSituacion(id)).unwrap().then(() => {
          Swal.fire('¡Eliminado!', 'El situacion ha sido eliminado.', 'success');
        }).catch((error) => {
          Swal.fire('Error', error.error || 'Ocurrió un error al eliminar el situacion', 'error');
        });
      }
    });
  };

  const handleSort = (key) => {
    if (sortConfig.direction === 'desc') {
      setSortConfig({ key: null, direction: 'asc' });
      return;
    }
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    } else if (sortConfig.key === key && sortConfig.direction === 'desc') {
      direction = null;
    }

    setSortConfig({ key, direction });
  };

  const handleEdit = situacion => {
    setCurrentSituacion(situacion);
    setShowModal(true);
  };

  const handleCreate = () => {
    setCurrentSituacion(null);
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
    setCurrentSituacion(null);
  };

  const handleBack = () => {
    navigate('/admin'); // Ruta a la que deseas regresar, en este caso, la página principal
  };

  const handleExport = () => {
    const worksheet = XLSX.utils.json_to_sheet(sortedSituaciones);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Situaciones");
    XLSX.writeFile(workbook, "situaciones.xlsx");
  };

  const sortedSituaciones = useMemo(() => {
    let sortedData = [...situaciones];
    if (sortConfig.key) {
      
      sortedData.sort((a, b) => {
        let aValue = a[sortConfig.key];
        let bValue = b[sortConfig.key];
  
        if (sortConfig.key === 'cuota_cat' || sortConfig.key === 'cuota_cab') {
          aValue = parseFloat(aValue);
          bValue = parseFloat(bValue);
        }
        if (aValue < bValue) {
          return sortConfig.direction === 'asc' ? -1 : 1;
        }
        if (aValue > bValue) {
          return sortConfig.direction === 'asc' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortedData;
  }, [situaciones, sortConfig]);

  const handleShowPreview = () => {
    setPrintContent(getPreviewContent());
    setShowPreviewModal(true);
  };

  const handleClosePreview = () => {
    setShowPreviewModal(false);
  };

  const getPreviewContent = () => {
    const content = {
      head: [['N°','Situación', 'Descripción', 'Cuota CAT', 'Cuota CAB']],
      body: sortedSituaciones.map((situacion, index) => [
        index+1,
        situacion.nombre,
        situacion.descripcion,
        situacion.cuota_cat,
        situacion.cuota_cab
      ])
    };
    return content;
  };

  return (
    <div className="list-container list0">
      <h1 className='table-title'>Lista de situaciones</h1>
      <div className="list-content">
        <div className='list-controls'>
          <div className="col-md-6">
          </div>
          <div className="col-md-6 text-right">
            <ActionButton onClick={handleCreate} outline icon='plus' floatEnd>
              Nueva situación
            </ActionButton>
          </div>
        </div>
        <div className="list-container-header">
          <div className="left-buttons">
            <button title="Volver" onClick={handleBack} className="icon-button btn-transparent"><i className="bi bi-arrow-left"></i></button>
          </div>
          <div className="right-buttons">
            <button title="Imprimir" onClick={handleShowPreview} className="icon-button btn-transparent"><i className="bi bi-printer"></i></button>
            <button title="Exportar a excel" onDoubleClick={handleExport} className="icon-button btn-transparent"><i className="bi bi-file-spreadsheet"></i></button>
          </div>
        </div>
        {situaciones.length === 0 ? (
          <p>No hay situaciones disponibles.</p>
        ) : (
          <div className='table-container'>
            <Table bordered hover size='sm'>
              <thead className='text-center align-middle'>
                <tr>
                  <th className='col-index'>N°</th>
                  <th onClick={() => handleSort('nombre')}>
                    Situación
                    {sortConfig.key === 'nombre' && (
                      sortConfig.direction === 'asc' ? <i className="bi bi-arrow-up"></i> : <i className="bi bi-arrow-down"></i>
                    )}
                  </th>
                  <th onClick={() => handleSort('descripcion')}>
                    Descripcion
                    {sortConfig.key === 'descripcion' && (
                      sortConfig.direction === 'asc' ? <i className="bi bi-arrow-up"></i> : <i className="bi bi-arrow-down"></i>
                    )}
                  </th>
                  <th onClick={() => handleSort('cuota_cat')}>
                    Cuota CAT
                    {sortConfig.key === 'cuota_cat' && (
                      sortConfig.direction === 'asc' ? <i className="bi bi-arrow-up"></i> : <i className="bi bi-arrow-down"></i>
                    )}
                  </th>
                  <th onClick={() => handleSort('cuota_cab')}>
                    Cuota CAB
                    {sortConfig.key === 'cuota_cab' && (
                      sortConfig.direction === 'asc' ? <i className="bi bi-arrow-up"></i> : <i className="bi bi-arrow-down"></i>
                    )}
                  </th>
                  <th className='col-simple-actions'>Acciones</th>
                </tr>
              </thead>
              <tbody>
                {sortedSituaciones.map((situacion, index) => (
                  <tr key={situacion.id}>
                    <td>{index + 1}</td>
                    <td>{situacion.nombre}</td>
                    <td>{situacion.descripcion}</td>
                    <td>{situacion.cuota_cat}</td>
                    <td>{situacion.cuota_cab}</td>
                    <td>
                      <button className="icon-button btn-secondary-outline" title="Editar" onClick={() => handleEdit(situacion)}>
                        <i className="bi bi-pencil"></i>
                      </button>
                      {situacion.extra === true && <button className="icon-button btn-secondary-outline" title="Eliminar" onClick={() => handleDelete(situacion.id)}>
                        <i className="bi bi-trash"></i>
                      </button>}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        )}
        <FormSituacion currentSituacion={currentSituacion} showModal={showModal} handleClose={handleClose} />
        <CustomPrintModal
          show={showPreviewModal}
          handleClose={handleClosePreview}
          title1="LISTA DE SITUACIONES"
          title2=""
          content={printContent}
          copia={false}
        />
      </div>
    </div>
  );
};

export default ListaSituaciones;
