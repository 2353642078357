import { createBrowserRouter } from "react-router-dom";
import RootLayout from "./pages/RootLayout";
import { tokenLoader, checkAuthLoader } from "./util/auth";
import PublicLayout from "./pages/PublicLayout";
import InicioPage from "./pages/Inicio";
import LoginForm from "./components/autenticacion/LoginForm";
import RegisterForm from "./components/autenticacion/RegisterForm";
import ErrorPage from "./pages/Error";
import AutenticacionPage from "./pages/Autenticacion";
import PaisList from "./components/pais/PaisList";
import LugarList from "./components/lugar/LugarList";
import PersonaList from './components/persona/PersonaList';
import InstitucionList from './components/institucion/InstitucionList'; 
import RequisitoList from "./components/requisito/RequisitoList";
import AfiliadoList from "./components/afiliado/AfiliadoList";
import AreaProyectoList from "./components/areaProyecto/AreaProyectoList";
import TipoProyectoList from "./components/tipoProyecto/TipoProyectoList";
import PlanCuentasList from './components/plancuentas/PlanCuentasList';  // Import PlanCuentasList component
import ComprobanteList from './components/comprobante/ComprobanteList';  // Import ComprobanteList component
import ValoradoList from './components/valorado/ValoradoList';
import ValoradoDetList from './components/valorado/ValoradoDetList';
import BalanceGeneral from './components/comprobante/BalanceGeneral';  // Import ComprobanteList component
import DashboardAdmin from "./pages/DashboardAdmin";
import ListaSituaciones from "./components/situacion/ListaSituaciones";
import ListaSeguimientosSituAfiliado from "./components/seguimientoSituAfiliado/ListaSeguimientosSituAfiliado";
import ListCategorias from "./components/categoria/ListCategorias";
import ListTipologias from "./components/tipologia/ListTipologias";
import ListProyectos from "./components/proyecto/ListProyectos";
import ListPropietarios from "./components/propietario/ListPropietarios";
import GestionInmuebles from "./components/inmueble/GestionInmuebles";
import GestionAfiliados from "./components/afiliado/GestionAfiliados";
import ListaAfiliados from "./components/cuota/ListaAfiliados";
import BancoList from "./components/banco/BancoList";
import ValoradoVenList from './components/valorado/ValoradoVenList';
import IngresosVariosList from './components/ingresosVarios/IngresosVariosList';
import ReciboList from './components/recibo/ReciboList';
import CajaList from './components/caja/CajaList.js';
import CajaDiariaList from './components/caja/CajaDiariaList.js';



//import ListaInscripciones from "./components/inscripcion/ListaInscripciones";

const router = createBrowserRouter([
  {
    path: '/',
    element: <PublicLayout />,  // Layout para páginas públicas
    errorElement: <ErrorPage />,
    children: [
      { index: true, element: <InicioPage /> },  // Página de inicio público
      // { path: 'proyectos', element: <ListaProyectosPublicos /> },  // Proyectos visibles al público
    ],
  },
  {
    element: <AutenticacionPage />,
    children: [
      { path: 'login', element: <LoginForm /> },
      { path: 'register', element: <RegisterForm /> },
    ],
  },
  
  // Rutas internas para la directiva (acceso restringido por roles)
  {
    path: '/admin',  // O puedes usar '/directiva'
    element: <RootLayout />,  // Layout de la directiva
    errorElement: <ErrorPage />,
    loader: tokenLoader,  // Verifica si el usuario es de la directiva
    children: [
      { index: true, element: <DashboardAdmin />, },  // Resumen del panel de la directiva
      { path: 'afiliados', element: <GestionAfiliados />},  // Gestión de afiliados
      //{ path: 'inscripciones', element: <ListaInscripciones /> }, 
      { path: 'seguimiento-situacion-afiliados', element: <ListaSeguimientosSituAfiliado /> }, 
      { path: 'personas', element: <PersonaList /> },
      { path: 'paises', element: <PaisList /> },  
      { path: 'lugares', element: <LugarList /> },  
      { path: 'instituciones', element: <InstitucionList /> },  
      { path: 'requisitos', element: <RequisitoList /> },  
      { path: 'situaciones', element: <ListaSituaciones /> },  
      { path: 'rubros-proyecto', element: <AreaProyectoList /> },
      { path: 'tipos-proyecto', element: <TipoProyectoList /> },
      { path: 'categorias', element: <ListCategorias /> },
      { path: 'inmuebles', element: <GestionInmuebles /> },
      { path: 'propietarios', element: <ListPropietarios /> },
      { path: 'tipologias', element: <ListTipologias /> },
      { path: 'plan-cuentas', element: <PlanCuentasList /> },  // Route for PlanCuentasList
      { path: 'valorados', element: <ValoradoList /> },
      { path: 'valorado-vens', element: <ValoradoVenList /> }, 
      { path: 'valorado-dets', element: <ValoradoDetList /> },
      { path: 'comprobantes', element: <ComprobanteList /> },   // New route for ComprobanteList
      { path: 'balance-general', element: <BalanceGeneral /> } ,
      { path: 'pago-cuotas/:codigoFuncionalidad', element: <ListaAfiliados /> },
      { path: 'ingresos-varios', element: <IngresosVariosList /> } , 
      { path: 'otros-ingresos', element: <ReciboList /> } , 
      { path: 'cajas', element: <CajaList /> } , 
      { path: 'bancos', element: <BancoList /> } , 
      { path: 'caja-diarias', element: <CajaDiariaList /> } , 
      // { path: 'ventas', element: <VentasList /> },  // Gestión de ventas de valorados
      // { path: 'contabilidad', element: <Contabilidad /> },  // Gestión de contabilidad
     { path: 'proyectos', element: <ListProyectos />, loader:checkAuthLoader },  // Gestión de propiedades intelectuales
    ],
  },
  
  // Rutas internas para asociados (gestión de proyectos)
  {
    path: '/asociados',  // Rubro exclusiva para los asociados
    element: <RootLayout />,  // Layout de asociados
    errorElement: <ErrorPage />,
    loader: tokenLoader,  // Verifica si el usuario está autenticado y es asociado
    children: [
      // { index: true, element: <DashboardAsociados /> },  // Panel general de proyectos
      // { path: 'mis-proyectos', element: <MisProyectosList /> },  // Lista de proyectos del asociado
      // { path: 'proyectos/nuevo', element: <NuevoProyectoForm /> },  // Registro de un nuevo proyecto
      // { path: 'proyectos/:id', element: <ProyectoDetail /> },  // Detalles de un proyecto específico
    ],
  },
]);

export default router;
