import { UPLOADS_BASE_URL } from "./axiosInstance";

import moment from 'moment-timezone';


export function formatFecha(fechaISO){
  return fechaISO.split('T')[0].split('-').reverse().join('/');
};

export function formaFechaI(fechaISO) {
 return fechaISO.split('T')[0];  // Dejar la fecha en formato YYYY-MM-DD
}

 export function formaFecha(fecha) {
  // Asegura que la fecha se convierta en la zona horaria correcta
  return moment.utc(fecha).tz('America/La_Paz').format('DD/MM/YYYY');;
   
}
export const formatCurrency2 = (value) => {
  if (isNaN(value)) {
    return '0,00';
  }

  // Convertir a número flotante
  const numericValue = parseFloat(value);

  // Aseguramos dos decimales y añadimos separador de miles manualmente
  const formattedValue = numericValue
    .toFixed(2)
    .replace(/\d(?=(\d{3})+\.)/g, '$&,');  // Insertar separador de miles

  return formattedValue.padStart(15, ' ');  // Alineación opcional
};

export const formatCurrency = (value) => {
  if (isNaN(value)) {
    return '          0,00';
  }

  // Convertir el número en una cadena formateada con separadores de miles y decimales
  const formattedValue = parseFloat(value).toLocaleString('es-ES', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });

  // Alinear a la derecha con un tamaño fijo de 12 caracteres
  return formattedValue.padStart(15, ' ');
};
export const formatMonetaryValue = (value) => {
  // Asegurarse de que el valor es un número, si no, convertirlo
  const numericValue = parseFloat(value);
  
  if (isNaN(numericValue)) {
    return '       0.00';  // Retornar "0.00" alineado a la derecha en 12 caracteres
  }

  // Formatear el número con separadores de miles y dos decimales
  const formattedValue = numericValue.toLocaleString('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });

  // Alinear el valor a la derecha con un espacio fijo de 12 caracteres
  return formattedValue.padStart(12, ' ');
};







export const splitGlosa = (glosa) => {
  // Limitar la glosa a 255 caracteres
  glosa = glosa.slice(0, 255);

  // Función para cortar glosa sin cortar palabras
  const cutAtWord = (text, maxLength) => {
    if (text.length <= maxLength) return text;
    const cutIndex = text.lastIndexOf(' ', maxLength);
    return text.slice(0, cutIndex > 0 ? cutIndex : maxLength);
  };

  const glosa1 = cutAtWord(glosa, 90);
  const remainder1 = glosa.slice(glosa1.length).trim();
  
  const glosa2 = cutAtWord(remainder1, 90);
  const remainder2 = remainder1.slice(glosa2.length).trim();
  
  const glosa3 = remainder2;

  return { glosa1, glosa2, glosa3 };
};

// util/dateUtils.js
export const getLocalDate = () => {
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, '0'); // Añadir 0 al mes si es necesario
  const day = String(today.getDate()).padStart(2, '0'); // Añadir 0 al día si es necesario
  return `${year}-${month}-${day}`;
};


export const calculateAge = (birthdate, referenceDate) => {
    const birth = new Date(birthdate);
    const reference = new Date(referenceDate);

    let years = reference.getFullYear() - birth.getFullYear();
    let months = reference.getMonth() - birth.getMonth();
    let days = reference.getDate() - birth.getDate();

    if (days < 0) {
      months -= 1;
      days += new Date(reference.getFullYear(), reference.getMonth(), 0).getDate();
    }

    if (months < 0) {
      years -= 1;
      months += 12;
    }

    return { years, months, days };
};


  export const getUploadUrl= (image) => {
    if(image){
    const fileName = image.split('uploads\\')[1] || image;
    return `${UPLOADS_BASE_URL}/${fileName}`}

    return null

  }
  export const formatearFecha = (fecha) =>{
    
    if (fecha) return fecha.split("T")[0];
    else return "";
  }
  
  export const datosNoNulos=(datos)=>{
    const datosNoNulos = Object.keys(datos).reduce((acc, key) => {
      acc[key] = datos[key] === null ? "" : datos[key];
      return acc;
    }, {});

    return datosNoNulos
  }

  
  export const getFileName = (file) => {
    if (typeof file === 'string') {
        const parts = file.split('-');
        return parts.length > 1 ? parts.slice(1).join('-') : file;
    }
    return file.name;
};

// numeroALetras.js

export function numeroALetras(xnum) {
  let num = parseFloat(xnum);
  console.log("num=",num,"xnum=",xnum)
  const unidades = ['CERO', 'UNO', 'DOS', 'TRES', 'CUATRO', 'CINCO', 'SEIS', 'SIETE', 'OCHO', 'NUEVE'];
  const decenas = ['DIEZ', 'ONCE', 'DOCE', 'TRECE', 'CATORCE', 'QUINCE', 'DIECISÉIS', 'DIECISIETE', 'DIECIOCHO', 'DIECINUEVE'];
  const decenasDiez = ['', '', 'VEINTE', 'TREINTA', 'CUARENTA', 'CINCUENTA', 'SESENTA', 'SETENTA', 'OCHENTA', 'NOVENTA'];
  const centenas = ['', 'CIENTO', 'DOSCIENTOS', 'TRESCIENTOS', 'CUATROCIENTOS', 'QUINIENTOS', 'SEISCIENTOS', 'SETECIENTOS', 'OCHOCIENTOS', 'NOVECIENTOS'];

  if (num === 0) return 'CERO';

  const getUnidades = (n) => unidades[n];
  const getDecenas = (n) => (n < 10 ? getUnidades(n) : (n < 20 ? decenas[n - 10] : decenasDiez[Math.floor(n / 10)] + (n % 10 !== 0 ? ' Y ' + getUnidades(n % 10) : '')));
  const getCentenas = (n) => (n === 100 ? 'CIEN' : centenas[Math.floor(n / 100)] + (n % 100 !== 0 ? ' ' + getDecenas(n % 100) : ''));
  
  const getMiles = (n) => {
      if (n < 1000) return getCentenas(n);
      return (n < 2000 ? 'MIL ' : getCentenas(Math.floor(n / 1000)) + ' MIL ') + (n % 1000 !== 0 ? getCentenas(n % 1000) : '');
  };

  const getMillones = (n) => {
      if (n < 1000000) return getMiles(n);
      return (n < 2000000 ? 'UN MILLÓN ' : getMiles(Math.floor(n / 1000000)) + ' MILLONES ') + (n % 1000000 !== 0 ? getMiles(n % 1000000) : '');
  };

  // Obtener parte entera y parte decimal
  const [entero, decimal] = num.toFixed(2).split('.');
  console.log("Entero=",entero, "decimal=",decimal)

  const literal = getMillones(parseInt(entero, 10)).trim();
  const decimalLiteral = `${decimal}/100`;

  console.log("literal=",literal)

  return `${literal} ${decimalLiteral}`.toUpperCase();
}
