import React from 'react';
import CustomPrintModal3 from '../UI/CustomPrintModal3';
import {formaFechaI, formatMonetaryValue } from '../../util/valueCalculator';

// Formato fijo para cantidad con 4 posiciones
const formatCantidad = (cantidad) => {
    return String(cantidad).padStart(4, ' ');
};

// Formato fijo para concepto con 40 posiciones
const formatConcepto = (concepto) => {
    const maxLineLength = 40;  // Longitud máxima de cada línea
    let lines = [];
    let words = concepto.split(' ');  // Dividir el concepto en palabras

    let currentLine = '';

    words.forEach((word) => {
        // Si agregar la palabra actual excede la longitud máxima de la línea, enviar la línea actual a lines y comenzar una nueva
        if ((currentLine + word).length > maxLineLength) {
            lines.push(currentLine.trim().padEnd(maxLineLength, ' '));  // Agregar la línea a 'lines' y ajustar
            currentLine = word + ' ';  // Comenzar una nueva línea con la palabra actual
        } else {
            currentLine += word + ' ';  // Agregar la palabra actual a la línea
        }
    });

    // Agregar la última línea si queda alguna
    if (currentLine.trim() !== '') {
        lines.push(currentLine.trim().padEnd(maxLineLength, ' '));
    }

    // Unir las líneas con saltos de línea
    return lines.join('\n');
};

// Función para extraer la fecha sin ajustes de zona horaria

const ImprimirRecibo = ({ show, handleClose, recibo, reciboDetalles, totalLiteral }) => {
    // Extraer la fecha en formato DD/MM/YYYY sin ajustar zona horaria
    const formattedDate = formaFechaI(recibo.fecha);

    // Preparar el contenido del body formateando cada campo
    const bodyContent = {
        head: [['Cant.', 'Concepto                                         ', '     P.U.','      Monto', '  Descuento', '     Pagado']],
        body: reciboDetalles.map(detalle => [
            formatCantidad(detalle.cantidad),            // Cantidad con 4 posiciones
            formatConcepto(detalle.concepto),            // Concepto con 40 posiciones
            formatMonetaryValue(detalle.pu),             // P.U. formateado
            formatMonetaryValue(detalle.monto),          // Monto con formato 9.999.999,99
            formatMonetaryValue(detalle.descuento),      // Descuento formateado
            formatMonetaryValue(detalle.pagado)          // Pagado formateado
        ])
    };

    return (
        <CustomPrintModal3
            show={show}
            handleClose={handleClose}
            title1={`RECIBO: ${recibo.nroRecibo}`}
            title2={`Fecha: ${formattedDate}`}  // Usar la fecha formateada sin zona horaria
            title3={`Beneficiario: ${recibo.beneficiario}`}
            title4={`Concepto: ${recibo.concepto}`}
            title5={`Caja: ${recibo.id_caja}  Usuario: ${recibo.id_usuario}`}
            content={bodyContent}
            total1={`Total: ${formatMonetaryValue(recibo.monto)} Bs.`}
            total2={`Son: ${totalLiteral} BOLIVIANOS`}
        />
    );
};

export default ImprimirRecibo;
