import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import apiService from "../services/apiService"; // Importa el servicio actualizado

// Crear una instancia del servicio para la entidad "areas"
const areaProyectoService = apiService('/areas-proyecto');

// Thunks para las operaciones CRUD
export const fetchAreasProyecto = createAsyncThunk('areaProyecto/fetchAreasProyecto', async () => {
  const response = await areaProyectoService.getAll();
  return response || [];
});

export const fetchAreaProyectoById = createAsyncThunk('areaProyecto/fetchAreaProyectoById', async (id) => {
  const response = await areaProyectoService.getById(id);
  return response;
});

export const addAreaProyecto = createAsyncThunk('areaProyecto/addAreaProyecto', async (area, { rejectWithValue }) => {
  try {
    const response = await areaProyectoService.create(area);
    return response;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const modifyAreaProyecto = createAsyncThunk('areaProyecto/modifyAreaProyecto', async ({ id, area }, { rejectWithValue }) => {
  try {
    const response = await areaProyectoService.update(id, area);
    return response;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const removeAreaProyecto = createAsyncThunk('areaProyecto/removeAreaProyecto', async (id, { rejectWithValue }) => {
  try {
    await areaProyectoService.remove(id);
    return id;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

// Estado inicial del slice
const initialState = {
  areas: [],
  current: null,
  statusSend: 'idle',
  statusGet:'idle',
  error: {
    status: null,
    messages:[]
  },
};

// Definición del slice
const areaProyectoSlice = createSlice({
  name: 'areaProyecto',
  initialState,
  reducers: {
    setCurrentAreaProyecto(state, action) {
      state.current = action.payload;
    },
    resetStatusSend(state) {
        state.statusSend = "idle";
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAreasProyecto.fulfilled, (state, action) => {
        state.areas = action.payload;
        state.statusGet = 'idle';
      })
      .addCase(fetchAreasProyecto.pending, (state) => {
        state.statusGet = 'loading';
      })
      .addCase(fetchAreasProyecto.rejected, (state, action) => {
        state.statusGet = 'failed';
        state.error = action.error.message;
      })
      .addCase(fetchAreaProyectoById.fulfilled, (state, action) => {
        state.current = action.payload;
        state.statusGet = 'idle';
      })
      .addCase(addAreaProyecto.fulfilled, (state, action) => {
        state.areas.push(action.payload);
        state.statusSend = 'idle';
      })
      .addCase(addAreaProyecto.pending, (state) => {
        state.statusSend = 'sending';
      })
      .addCase(addAreaProyecto.rejected, (state, action) => {
        state.statusSend = 'failed';
        state.error = action.payload || action.error.message;
      })
      .addCase(modifyAreaProyecto.fulfilled, (state, action) => {
        const index = state.areas.findIndex(area => area.id === action.payload.id);
        if (index !== -1) {
          state.areas[index] = action.payload;
        }
        state.statusSend = 'idle';
      })
      .addCase(modifyAreaProyecto.pending, (state, action) => {
        state.statusSend = 'sending';
      })
      .addCase(modifyAreaProyecto.rejected, (state, action) => {
        state.statusSend = 'failed';
        state.error = action.payload.error || action.error.message;
      })
      .addCase(removeAreaProyecto.fulfilled, (state, action) => {
        state.areas = state.areas.filter(area => area.id !== action.payload);
        state.statusSend = 'idle';
      })
      .addCase(removeAreaProyecto.pending, (state) => {
        state.statusSend = 'sending';
      })
      .addCase(removeAreaProyecto.rejected, (state, action) => {
        state.statusSend = 'failed';
        state.error = action.payload || action.error.message;
      });
  }
});

export const { setCurrentAreaProyecto, resetStatusSend } = areaProyectoSlice.actions;

export default areaProyectoSlice.reducer;
