// src/services/paisService.js

import axios from 'axios';
import { API_URL_BASE } from '../util/axiosInstance';

const API_URL = API_URL_BASE+'/auth'; // Asegúrate de que esta URL sea correcta

export const getUser = async (user) => {
  const response = await axios.get(`${API_URL}/user`, user);
  
  return response.data;
};

export const sendLogin = async (user) => {
 try{ const response = await axios.post(`${API_URL}/login`, user);
   return response.data;}
   catch(error){
    throw error
  }
  
};

export const sendRegister= async (user) => {
  const response = await axios.post(`${API_URL}/register`, user);
 
  return response.data;
};

