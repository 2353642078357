import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addPropietario, modifyPropietario, fetchPropietarios } from '../../reducers/propietario_slice';
import { fetchPersonas } from '../../reducers/persona_slice';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Swal from 'sweetalert2';
import { Alert, Col, Row } from 'react-bootstrap';
import ActionButton from '../UI/Buttons/ActionButton';
import PersonaForm from './PersonaForm';
import { fullNamePersona } from '../../util/customFormatter';
import TablePersona from './TablePersona';

const initialState = {
    id_persona:"",
    personaSeleccionada:null 
}

const SelectPersona = ({onSelect, labelText, personaSelected, excludeList=null,simplePersona=null}) => {
    const [datos, setDatos] = useState(initialState);
    console.log("personaSelected", personaSelected)
    useEffect(() => {
        if(personaSelected){
          setDatos({id_persona: personaSelected.id, personaSeleccionada:personaSelected});
        }
        else{ setDatos(initialState)}
      }, [personaSelected]);


    const handleSelectPersona = (persona) => {
        const personaId = persona? persona.id : ""
        setDatos({ id_persona: personaId, personaSeleccionada:persona });
        onSelect(labelText.toLowerCase(),persona)
    };

    return (
        <><Form.Label className='mt-3'>{labelText}
                                </Form.Label>
                    {datos.personaSeleccionada ? (
                        <div className="selected-container p-3 border rounded shadow-sm">
                        <div>
                            <strong>{`${datos.personaSeleccionada.nombre} ${datos.personaSeleccionada.apellido1} ${datos.personaSeleccionada.apellido2}`}</strong> <br />
                            <span>C.I.: {datos.personaSeleccionada.ci}</span> 
                        </div>
                        <Button variant="link" onClick={() =>handleSelectPersona(null)}>
                            Cambiar persona
                        </Button>
                        </div>
                    ) : (
                        <>
                        <div className="alert alert-info p-2">
                        <p>No se ha seleccionado una persona aún.</p>
                        </div>
                        {/* Aquí puedes renderizar la tabla de personas */}
                        <TablePersona onSelectPersona={handleSelectPersona} simplePersona={simplePersona} excludeList={excludeList}/>
                        </>
                    )}
        </>
    );
};

export default SelectPersona;
