import Footer from "../components/templates/PublicFooter";
import PublicHeader from "../components/templates/PublicHeader";
import "../styles/Inicio.scss";
import { Outlet } from "react-router-dom";

const PublicLayout = () => {
  
  return (
    <div className="app-container">
      <PublicHeader />
      <main className="main-public-content">
        <Outlet />
      </main>
      <Footer />
  </div>
  );
};
export default PublicLayout;
