import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import apiService from "../services/apiService"; // Importa el servicio actualizado

// Crear una instancia del servicio para la entidad "tipos-proyecto"
const tipoProyectoService = apiService('/tipos-proyecto');

// Thunks para las operaciones CRUD
export const fetchTiposProyecto = createAsyncThunk('tipoProyecto/fetchTiposProyecto', async () => {
  const response = await tipoProyectoService.getAll();
  return response || [];
});

export const fetchTipoProyectoById = createAsyncThunk('tipoProyecto/fetchTipoProyectoById', async (id) => {
  const response = await tipoProyectoService.getById(id);
  return response;
});

export const addTipoProyecto = createAsyncThunk('tipoProyecto/addTipoProyecto', async (tipo, { rejectWithValue }) => {
  try {
    const response = await tipoProyectoService.create(tipo);
    return response;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const modifyTipoProyecto = createAsyncThunk('tipoProyecto/modifyTipoProyecto', async ({ id, tipo }, { rejectWithValue }) => {
  try {
    const response = await tipoProyectoService.update(id, tipo);
    return response;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const removeTipoProyecto = createAsyncThunk('tipoProyecto/removeTipoProyecto', async (id, { rejectWithValue }) => {
  try {
    await tipoProyectoService.remove(id);
    return id;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

// Estado inicial del slice
const initialState = {
  tipos: [],
  current: null,
  statusSend: 'idle',
  statusGet:'idle',
  error: {
    status: null,
    messages:[]
  },
};

// Definición del slice
const tipoProyectoSlice = createSlice({
  name: 'tipoProyecto',
  initialState,
  reducers: {
    setCurrentTipoProyecto(state, action) {
      state.current = action.payload;
    },
    resetStatusSend(state) {
        state.statusSend = "idle";
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTiposProyecto.fulfilled, (state, action) => {
        state.tipos = action.payload;
        state.statusGet = 'idle';
      })
      .addCase(fetchTiposProyecto.pending, (state) => {
        state.statusGet = 'loading';
      })
      .addCase(fetchTiposProyecto.rejected, (state, action) => {
        state.statusGet = 'failed';
        state.error = action.error.message;
      })
      .addCase(fetchTipoProyectoById.fulfilled, (state, action) => {
        state.current = action.payload;
        state.statusGet = 'idle';
      })
      .addCase(addTipoProyecto.fulfilled, (state, action) => {
        state.tipos.push(action.payload);
        state.statusSend = 'idle';
      })
      .addCase(addTipoProyecto.pending, (state) => {
        state.statusSend = 'sending';
      })
      .addCase(addTipoProyecto.rejected, (state, action) => {
        state.statusSend = 'failed';
        state.error = action.payload || action.error.message;
      })
      .addCase(modifyTipoProyecto.fulfilled, (state, action) => {
        const index = state.tipos.findIndex(tipo => tipo.id === action.payload.id);
        if (index !== -1) {
          state.tipos[index] = action.payload;
        }
        state.statusSend = 'idle';
      })
      .addCase(modifyTipoProyecto.pending, (state, action) => {
        state.statusSend = 'sending';
      })
      .addCase(modifyTipoProyecto.rejected, (state, action) => {
        state.statusSend = 'failed';
        state.error = action.payload.error || action.error.message;
      })
      .addCase(removeTipoProyecto.fulfilled, (state, action) => {
        state.tipos = state.tipos.filter(tipo => tipo.id !== action.payload);
        state.statusSend = 'idle';
      })
      .addCase(removeTipoProyecto.pending, (state) => {
        state.statusSend = 'sending';
      })
      .addCase(removeTipoProyecto.rejected, (state, action) => {
        state.statusSend = 'failed';
        state.error = action.payload || action.error.message;
      });
  }
});

export const { setCurrentTipoProyecto, resetStatusSend } = tipoProyectoSlice.actions;

export default tipoProyectoSlice.reducer;
