import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import apiService from "../services/apiService";  // Servicio para peticiones API

const reciboService = apiService('/recibos');


export const fetchDetallesReciboByCaja = createAsyncThunk('recibo/fetchDetallesReciboByCaja', async (id_caja, { rejectWithValue }) => {
    try {
        const response = await reciboService.getAllBySomeId('detalles-recibo/caja', id_caja);
        return response;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});
// Obtener todos los recibos
export const fetchRecibos = createAsyncThunk('recibo/fetchRecibos', async () => {
    const response = await reciboService.getAll();
    return response || [];
});

// Obtener un recibo por su ID (incluyendo detalles)
export const fetchReciboById = createAsyncThunk('recibo/fetchReciboById', async (id, { rejectWithValue }) => {
    console.log(id)
    try {
        const response = await reciboService.getById(id);  // Petición para obtener el recibo por su ID
        console.log("Reposnse=",response)
        return response;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});

// Crear un nuevo recibo (incluyendo los detalles)
export const addRecibo = createAsyncThunk('recibo/addRecibo', async (recibo, { rejectWithValue }) => {
    try {
        const response = await reciboService.create(recibo);  // Ahora se envía como un objeto con ambos
        return response;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});

// Modificar un recibo existente
export const modifyRecibo = createAsyncThunk('recibo/modifyRecibo', async ({ id, recibo }, { rejectWithValue }) => {
    try {
        const response = await reciboService.update(id, recibo);
        return response;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});

// Eliminar un recibo
export const removeRecibo = createAsyncThunk('recibo/removeRecibo', async (id, { rejectWithValue }) => {
    try {
        await reciboService.remove(id);
        return id;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});

// Estado inicial
const initialState = {
    recibos: [],
    current: null,  // Para almacenar el recibo actual con sus detalles
    detallesRecibo: [], 
    statusSend: 'idle',
    statusGet: 'idle',
    error: null,
};

// Slice para recibos
const reciboSlice = createSlice({
    name: 'recibo',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchDetallesReciboByCaja.fulfilled, (state, action) => {
                state.detallesRecibo = action.payload;  // Almacenar los detalles del recibo en el estado
                state.statusGet = 'idle';
            })
            .addCase(fetchDetallesReciboByCaja.rejected, (state, action) => {
                state.error = action.payload;
                state.statusGet = 'failed';
            })
            // Manejar la acción para obtener todos los recibos
            .addCase(fetchRecibos.fulfilled, (state, action) => {
                state.recibos = action.payload;
                state.statusGet = 'idle';
            })
            // Manejar la acción para obtener un recibo por su ID
            .addCase(fetchReciboById.fulfilled, (state, action) => {
                state.current = action.payload;  // Almacenar el recibo y sus detalles
                state.statusGet = 'idle';
            })
            .addCase(fetchReciboById.rejected, (state, action) => {
                state.error = action.payload;
                state.statusGet = 'failed';
            })
            // Manejar la acción de crear un recibo
            .addCase(addRecibo.fulfilled, (state, action) => {
                state.recibos.push(action.payload);
                state.statusSend = 'idle';
            })
            // Manejar la acción de modificar un recibo
            .addCase(modifyRecibo.fulfilled, (state, action) => {
                const index = state.recibos.findIndex(r => r.id === action.payload.id);
                if (index !== -1) {
                    state.recibos[index] = action.payload;
                }
                state.statusSend = 'idle';
            })
            // Manejar la acción de eliminar un recibo
            .addCase(removeRecibo.fulfilled, (state, action) => {
                state.recibos = state.recibos.filter(r => r.id !== action.payload);
                state.statusSend = 'idle';
            });
    }
});

export default reciboSlice.reducer;
