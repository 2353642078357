import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Button, Table, Image } from 'react-bootstrap';
import { fetchDepositoConCuotas } from '../../reducers/cuota_slice';
import EpsonPrintModal from '../UI/EpsonPrintModal'; // Para la funcionalidad de impresión
import { BsEye, BsEyeSlash } from 'react-icons/bs'; // Iconos para visualizar la imagen
import { formatearFecha, getUploadUrl } from '../../util/valueCalculator';

const VistaPagoCuota = ({ show, handleClose, afiliado, depositoId }) => {
  const dispatch = useDispatch();

  // Obtener los datos del depósito y la lista de bancos desde el store
  const depositoData = useSelector(state => state.cuota.depositoConCuotas || {});
  const bancos = useSelector(state => state.banco.bancos || []);
  const { deposito = {}, deposito_det = [], cuotas_pagadas = [] } = depositoData; // Ajustar para reflejar la estructura correcta

  const [showEpsonPrintModal, setShowEpsonPrintModal] = useState(false);
  const [constanciaContent, setConstanciaContent] = useState(null);
  const [imagenPreview, setImagenPreview] = useState(null); // Estado para previsualizar la imagen
  const [imagenVisible, setImagenVisible] = useState([]); // Estado para la visibilidad de la imagen

  // Efecto para obtener los detalles del depósito cuando el modal esté visible y haya un depositoId
  useEffect(() => {
    if (show && depositoId) {
      dispatch(fetchDepositoConCuotas(depositoId));
    }
  }, [dispatch, show, depositoId]);

  // Cargar la imagen que llega desde la base de datos (si existe)
  useEffect(() => {
    if (deposito_det.length > 0) {
      const archivo = deposito_det[0].archivo; // Aquí puedes ajustar si hay más de un archivo
      if (archivo && typeof archivo === 'string') {
        setImagenPreview(getUploadUrl(archivo)); // Asignar la URL de la imagen si está guardada en la base de datos
      }
    }
  }, [deposito_det]);

  // Función para obtener el nombre del banco desde su id
  const getBancoInfo = (id_banco) => {
    const banco = bancos.find(b => b.id === id_banco);
    return banco ? `${banco.sigla} - ${banco.numero_cuenta}` : 'BANCO';
  };

  // Función para alternar la visibilidad de la imagen de depósito
  const toggleImageVisibility = (index, archivo) => {
    const newVisibility = [...imagenVisible];
    newVisibility[index] = !newVisibility[index];
    setImagenVisible(newVisibility);

    if (newVisibility[index] && archivo) {
      if (typeof archivo === 'string') {
        setImagenPreview(getUploadUrl(archivo)); // Si es una URL de archivo
      } else if (archivo instanceof Blob || archivo instanceof File) {
        setImagenPreview(getUploadUrl(archivo)); // Si es una URL de archivo
      } else {
        console.warn("Archivo no es ni URL ni Blob");
      }
    } else {
      setImagenPreview(null);
    }
  };

  const handleConstancia = () => {
    const depositosInfo = deposito_det.map(det => {
      const bancoInfo = getBancoInfo(det.id_banco);
      const monto = parseFloat(det.monto).toFixed(2);
      return `${bancoInfo.padEnd(30)}${monto.padEnd(12)}${det.docum}`;
    });

    const cuotasInfo = cuotas_pagadas.map(cuota => {
      const montoCat = isNaN(parseFloat(cuota.monto_cat)) ? 0 : parseFloat(cuota.monto_cat);
      const montoCab = isNaN(parseFloat(cuota.monto_cab)) ? 0 : parseFloat(cuota.monto_cab);
      return `${cuota.cuota.padEnd(20)}${montoCat.toFixed(2).padEnd(12)}${montoCab.toFixed(2)}`;
    });

    const detalle = [
      'CONSTANCIA DE PAGO DE CUOTAS',
      '=============================',
      `Fecha recibo: ${formatearFecha(deposito.fecha)}`,
      `Nro. Deposito: ${deposito.id}`,
      '=============================',
      `Afiliado: ${afiliado.persona.nombre} ${afiliado.persona.apellido1} ${afiliado.persona.apellido2}`,
      `C.I.: ${afiliado.persona.ci}`,
      `Nro. Reg.: ${afiliado.numero_registro}`,
      '--------------------------------------------',
      'DEPOSITOS REGISTRADOS',
      '--------------------------------------------',
      'Banco                               Monto           Comprobante',
      '--------------------------------------------',
      ...depositosInfo,
      '--------------------------------------------',
      `TOTAL BS.:           ${deposito_det.reduce((total, d) => total + parseFloat(d.monto), 0).toFixed(2)}`,
      '',
      'CUOTAS CANCELADAS',
      '--------------------------------------------',
      'Cuota                 Monto CAT   Monto CAB',
      '--------------------------------------------',
      ...cuotasInfo,
      '--------------------------------------------',
      `TOTAL BS.:           ${cuotas_pagadas.reduce((total, c) => total + (isNaN(parseFloat(c.monto_cat)) ? 0 : parseFloat(c.monto_cat)) + (isNaN(parseFloat(c.monto_cab)) ? 0 : parseFloat(c.monto_cab)), 0).toFixed(2)}`
    ];

    setConstanciaContent({ detalle });
    setShowEpsonPrintModal(true);
  };

  return (
    <>
      <Modal show={show} onHide={handleClose} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>Detalles del Pago</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {afiliado && (
            <div>
              <h5>Datos del Afiliado</h5>
              <p><strong>Nombre:</strong> {`${afiliado.persona.nombre} ${afiliado.persona.apellido1} ${afiliado.persona.apellido2}`}</p>
              <p><strong>C.I.:</strong> {afiliado.persona.ci}</p>
              <p><strong>N° de Registro:</strong> {afiliado.numero_registro}</p>
            </div>
          )}

          {imagenPreview && (
            <div style={{ position: 'absolute', top: '10px', right: '10px', zIndex: '10' }}>
              <Image
                src={imagenPreview}
                alt="Imagen de Depósito"
                className="img-fluid"
                style={{
                  maxWidth: '150px',
                  border: '1px solid #ccc',
                  padding: '5px',
                  borderRadius: '5px',
                  backgroundColor: '#fff'
                }}
              />
            </div>
          )}

          <h5>Depósito</h5>
          <Table bordered hover size="sm">
            <thead>
              <tr>
                <th>Banco</th>
                <th>Monto</th>
                <th>Fecha Depósito</th>
                <th>Documento</th>
                <th>Archivo</th>
              </tr>
            </thead>
            <tbody>
              {deposito_det.map((det, index) => (
                <tr key={index}>
                  <td>{getBancoInfo(det.id_banco)}</td>
                  <td>{parseFloat(det.monto).toFixed(2)} Bs.</td>
                  <td>{new Date(det.fecha_deposito).toLocaleDateString()}</td>
                  <td>{det.docum}</td>
                  <td>
                    {det.archivo && (
                      <>
                        <button
                          className="icon-button"
                          onClick={() => toggleImageVisibility(index, det.archivo)}
                        >
                          {imagenVisible[index] ? <BsEyeSlash /> : <BsEye />}
                        </button>
                      </>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>

          <h5>Cuotas Pagadas</h5>
          <Table bordered hover size="sm">
            <thead>
              <tr>
                <th>Cuota</th>
                <th>Monto CAT</th>
                <th>Monto CAB</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              {cuotas_pagadas.map((cuota, index) => (
                <tr key={index}>
                  <td>{cuota.cuota}</td>
                  <td>{(isNaN(parseFloat(cuota.monto_cat)) ? 0 : parseFloat(cuota.monto_cat)).toFixed(2)} Bs.</td>
                  <td>{(isNaN(parseFloat(cuota.monto_cab)) ? 0 : parseFloat(cuota.monto_cab)).toFixed(2)} Bs.</td>
                  <td>{((isNaN(parseFloat(cuota.monto_cat)) ? 0 : parseFloat(cuota.monto_cat)) + (isNaN(parseFloat(cuota.monto_cab)) ? 0 : parseFloat(cuota.monto_cab))).toFixed(2)} Bs.</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>Cerrar</Button>
          <Button variant="primary" onClick={handleConstancia}>Imprimir Constancia de Pago</Button>
        </Modal.Footer>
      </Modal>

      {showEpsonPrintModal && (
        <EpsonPrintModal
          show={showEpsonPrintModal}
          handleClose={() => setShowEpsonPrintModal(false)}
          content={constanciaContent}
        />
      )}
    </>
  );
};

export default VistaPagoCuota;
