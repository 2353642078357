import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addValoradoDet } from '../../reducers/valoradoDet_slice';
import { fetchValorados } from '../../reducers/valorado_slice';
import { fetchPlanCuentas } from '../../reducers/planCuentas_slice';
import { fetchComprobantes } from '../../reducers/comprobante_slice';
import { fetchRecibos } from '../../reducers/recibo_slice'; 
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Swal from 'sweetalert2';
import { Alert, Col, Row, Table } from 'react-bootstrap';
import moment from 'moment-timezone';
import CustomPrintModal3 from '../UI/CustomPrintModal3'; 

const initialState = {
    tipoEgreso: "Contabilidad",
    nroComprobante: "",
    fecha: moment().tz("America/La_Paz").format('YYYY-MM-DD'),
    beneficiario: "",
    detalles: [
        {
            id_valorado: "",
            primero: 0,
            ultimo: 0,
            costo: 0,
            precioventa: 0,
            codigo_cuenta: ""
        }
    ],
    totalCosto: 0,
};

const ValoradoDetForm = ({ showModal, handleClose }) => {
    const [datos, setDatos] = useState(initialState);
    const [comprobanteValido, setComprobanteValido] = useState(false);
    const [isValidatingComprobante, setIsValidatingComprobante] = useState(false);
    const [error, setError] = useState('');
    const [showComprobanteModal, setShowComprobanteModal] = useState(false); 
    const [comprobanteSeleccionado, setComprobanteSeleccionado] = useState(null); 
    const [disableForm, setDisableForm] = useState(false);  
    const [showPrintButton, setShowPrintButton] = useState(false); 
    const [printContent, setPrintContent] = useState({ head: [], body: [] });
    const [nroReciboGenerado, setNroReciboGenerado] = useState(""); 
    const [showPrintModal, setShowPrintModal] = useState(false);
    const dispatch = useDispatch();

    const xcaja = 1;
    const xidusuario = 1;
    const xcuentacaja = "1111";

    const valorados = useSelector(state => state.valorado.valorados);
    const cuentas = useSelector(state => state.planCuentas.planCuentas || []);
    const comprobantes = useSelector(state => state.comprobante.comprobantes || []);
    const recibos = useSelector(state => state.recibo.recibos || []);

    useEffect(() => {
        if (showModal) {
            dispatch(fetchValorados());
            dispatch(fetchPlanCuentas());
            dispatch(fetchComprobantes());
            dispatch(fetchRecibos());
            resetForm(); 
        }
    }, [dispatch, showModal]);

    const resetForm = () => {
        setDatos({ ...initialState });  
        setComprobanteValido(false);
        setIsValidatingComprobante(false);
        setError('');
        setDisableForm(false);
        setShowPrintButton(false);
        setNroReciboGenerado(""); 
    };

    const generarNroRecibo = () => {
        const year = moment().tz("America/La_Paz").format('YYYY');
        const prefix = `E${year}`;
        const lastRecibo = recibos
            .filter(rec => rec.nroRecibo.startsWith(prefix))
            .sort((a, b) => parseInt(b.nroRecibo.slice(6)) - parseInt(a.nroRecibo.slice(6)))[0];

        const nextNumero = lastRecibo ? parseInt(lastRecibo.nroRecibo.slice(6)) + 1 : 1;
        return `${prefix}${nextNumero.toString().padStart(5, '0')}`;
    };

    const handleAddDetail = () => {
        setDatos(prevDatos => ({
            ...prevDatos,
            detalles: [...prevDatos.detalles, { id_valorado: "", primero: "", ultimo: "", costo: 0, precioventa: 0, codigo_cuenta: "" }]
        }));
    };

    const handleRemoveDetail = (index) => {
        setDatos(prevDatos => ({
            ...prevDatos,
            detalles: prevDatos.detalles.filter((_, i) => i !== index)
        }));
    };

    const handleDetailChange = (index, field, value) => {
        const updatedDetails = [...datos.detalles];
        updatedDetails[index][field] = value;
        setDatos(prevDatos => ({
            ...prevDatos,
            detalles: updatedDetails
        }));
    };

    const calcularTotalCosto = () => {
        return datos.detalles.reduce((sum, det) => sum + parseFloat(det.costo || 0), 0);
    };

    // Validación adicional en la función handleSubmit
    const validarDetalles = () => {
        for (let i = 0; i < datos.detalles.length; i++) {
            const det = datos.detalles[i];
            const primero = Number(det.primero);
            const ultimo = Number(det.ultimo);
            const costo = Number(det.costo);
            const precioventa = Number(det.precioventa);
    
            // Validaciones
            if (costo <= 0 || precioventa <= 0 || costo<=precioventa) {
                setError('El costo y el precio de venta deben ser mayores a 0, y el precio de venta debe ser menor al costo..');
                return false;
            }
            if (primero <= 0 || ultimo <= 0) {
                setError('El primer y último número deben ser mayores a 0.');
                return false;
            }
            if (ultimo <= primero) {
                setError('El último número debe ser mayor que el primero.');
                return false;
            }
        }
        setError('');
        return true;
    };
    

    const verificarComprobante = () => {
        const comprobante = comprobantes.find(c => c.nrocomprobante === datos.nroComprobante);
        setIsValidatingComprobante(true);

        if (comprobante) {
            setComprobanteSeleccionado(comprobante);
            setError(''); 
            setShowComprobanteModal(true);
        } else {
            setComprobanteValido(false);
            setError('Número de comprobante inválido.'); 
        }

        setIsValidatingComprobante(false);
    };

    const confirmarComprobante = () => {
        setComprobanteValido(true);
        setError(''); 
        setShowComprobanteModal(false);
    };

    const handleSubmit = async e => {
        e.preventDefault();

        if (!validarDetalles()) {
            return; 
        }

        if (datos.tipoEgreso === 'Contabilidad' && !comprobanteValido) {
            setError('Debes ingresar y verificar un comprobante de contabilidad válido.');
            return;
        }

        Swal.fire({
            title: '¿Estás seguro?',
            text: "El registro es irreversible. ¿Deseas continuar?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, registrar',
            cancelButtonText: 'Cancelar'
        }).then(async (result) => {
            if (result.isConfirmed) {
                const totalCosto = calcularTotalCosto();
                if (totalCosto <= 0) {
                    setError('El total del costo debe ser mayor a 0.');
                    return;
                }

                const nroRecibo = datos.tipoEgreso === 'Caja' ? generarNroRecibo() : datos.nroComprobante;
                setNroReciboGenerado(nroRecibo);

                const detallesRecibo = datos.detalles.map(det => {
                    const valorado = valorados.find(v => Number(v.id) === Number(det.id_valorado));
                    return {
                        concepto: `COMPRA DE ${valorado ? valorado.descripcion : 'Valorado'} DEL ${det.primero} AL ${det.ultimo}`,
                        monto: det.costo,
                        codigo_cuentaC: xcuentacaja, 
                        codigo_cuentaM: det.codigo_cuenta,
                        fecha: datos.fecha,
                        nroRecibo: nroRecibo,
                        id_caja: xcaja,
                        tipo: 'EGRESO',
                        id_usuario: xidusuario
                    };
                });

                const datosConRecibo = {
                    valoradoDet: datos.detalles.map(det => ({
                        ...det,
                        nroRecibo,
                        fecha: datos.fecha,
                        estado: 'VIGENTE'
                    })),
                    recibo: {
                        nroRecibo,
                        tipo: 'EGRESO',
                        fecha: datos.fecha,
                        monto: totalCosto,
                        beneficiario: datos.beneficiario,
                        concepto: 'COMPRA DE VALORADOS',
                        id_caja: xcaja,
                        id_usuario: xidusuario,
                        detalles: detallesRecibo
                    }
                };

                dispatch(addValoradoDet(datosConRecibo)).then(response => {
                    if (response.error) {
                        Swal.fire({
                            icon: 'error',
                            title: 'Error al registrar',
                            text: 'Verifique los datos e intente nuevamente.'
                        });
                    } else {
                        Swal.fire({
                            icon: 'success',
                            title: 'Registro exitoso',
                            text: 'Se registró correctamente.'
                        });

                        if (datos.tipoEgreso === 'Caja') {
                            setShowPrintButton(true);
                            setDisableForm(true);
                        } else {
                            handleClose();
                        }
                    }
                });
            }
        });
    };

    const handlePrint = () => {
        const content = {
            head: [['Descripción', 'Monto']],
            body: datos.detalles.map(det => {
                const valorado = valorados.find(v => Number(v.id) === Number(det.id_valorado)); 
                return [
                    `COMPRA DE ${valorado ? valorado.descripcion : 'Valorado'} DEL ${det.primero} AL ${det.ultimo}`,
                    `${det.costo} Bs.`
                ];
            })
        };
        setPrintContent(content); 
        setShowPrintModal(true);  
    };

    const handleClosePrintModal = () => {
        setShowPrintModal(false);
    };

    return (
        <>
            <Modal show={showModal} onHide={handleClose} backdrop="static" keyboard={false} size="xl">
                <Modal.Header closeButton>
                    <Modal.Title>Nueva compra de valorados</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {error && <Alert variant="danger">{error}</Alert>}
                    <Form onSubmit={handleSubmit}>
                        <Row xs={1} md={2}>
                            <Col>
                                <Form.Label>Tipo de Egreso</Form.Label>
                                <Form.Control
                                    as="select"
                                    name="tipoEgreso"
                                    value={datos.tipoEgreso}
                                    onChange={(e) => setDatos({ ...datos, tipoEgreso: e.target.value })}
                                    disabled={disableForm}
                                >
                                    <option value="Contabilidad">Contabilidad</option>
                                    <option value="Caja">Caja</option>
                                </Form.Control>
                            </Col>
                            <Col>
                                <Form.Label>Fecha</Form.Label>
                                <Form.Control
                                    type="date"
                                    name="fecha"
                                    value={datos.fecha}
                                    onChange={(e) => setDatos({ ...datos, fecha: e.target.value })}
                                    disabled={disableForm}
                                    required
                                />
                            </Col>
                        </Row>

                        {datos.tipoEgreso === 'Contabilidad' && (
                            <Row className="mt-3">
                                <Col>
                                    <Form.Label>Número de Comprobante</Form.Label>
                                    <div className="d-flex">
                                        <Form.Control
                                            type="text"
                                            name="nroComprobante"
                                            value={datos.nroComprobante}
                                            onChange={(e) => setDatos({ ...datos, nroComprobante: e.target.value })}
                                            maxLength={20}
                                            style={{ width: 'auto' }}
                                            disabled={disableForm}
                                        />
                                        <Button
                                            variant="primary"
                                            className="ml-2"
                                            onClick={verificarComprobante}
                                            disabled={!datos.nroComprobante || isValidatingComprobante}
                                        >
                                            {isValidatingComprobante ? "Validando..." : "Verificar"}
                                        </Button>
                                    </div>
                                </Col>
                            </Row>
                        )}

                        <Form.Label>Beneficiario</Form.Label>
                        <Form.Control
                            type="text"
                            name="beneficiario"
                            value={datos.beneficiario}
                            onChange={(e) => setDatos({ ...datos, beneficiario: e.target.value })}
                            disabled={disableForm}
                            required
                        />

                        <h5 className="mt-4">Detalles de la Compra</h5>
                        <Table bordered>
                            <thead>
                                <tr>
                                    <th>Valorado</th>
                                    <th>Primero</th>
                                    <th>Último</th>
                                    <th>Costo</th>
                                    <th>Precio Venta</th>
                                    <th>Código Cuenta</th>
                                    <th>Acciones</th>
                                </tr>
                            </thead>
                            <tbody>
                                {datos.detalles.map((detalle, index) => (
                                    <tr key={index}>
                                        <td>
                                            <Form.Control
                                                as="select"
                                                name="id_valorado"
                                                value={detalle.id_valorado}
                                                onChange={(e) => handleDetailChange(index, 'id_valorado', e.target.value)}
                                                disabled={disableForm}
                                                required
                                            >
                                                <option value="">Selecciona un valorado</option>
                                                {valorados.map(valorado => (
                                                    <option key={valorado.id} value={valorado.id}>{valorado.descripcion}</option>
                                                ))}
                                            </Form.Control>
                                        </td>
                                        <td>
                                            <Form.Control
                                                type="number"
                                                name="primero"
                                                value={detalle.primero}
                                                onChange={(e) => handleDetailChange(index, 'primero', e.target.value)}
                                                disabled={disableForm}
                                                required
                                            />
                                        </td>
                                        <td>
                                            <Form.Control
                                                type="number"
                                                name="ultimo"
                                                value={detalle.ultimo}
                                                onChange={(e) => handleDetailChange(index, 'ultimo', e.target.value)}
                                                disabled={disableForm}
                                                required
                                            />
                                        </td>
                                        <td>
                                            <Form.Control
                                                type="number"
                                                name="costo"
                                                value={detalle.costo}
                                                onChange={(e) => handleDetailChange(index, 'costo', e.target.value)}
                                                disabled={disableForm}
                                                required
                                            />
                                        </td>
                                        <td>
                                            <Form.Control
                                                type="number"
                                                name="precioventa"
                                                value={detalle.precioventa}
                                                onChange={(e) => handleDetailChange(index, 'precioventa', e.target.value)}
                                                disabled={disableForm}
                                                required
                                            />
                                        </td>
                                        <td>
                                            <Form.Control
                                                as="select"
                                                name="codigo_cuenta"
                                                value={detalle.codigo_cuenta}
                                                onChange={(e) => handleDetailChange(index, 'codigo_cuenta', e.target.value)}
                                                disabled={disableForm}
                                                required
                                            >
                                                <option value="">Seleccione Cuenta</option>
                                                {cuentas.filter(cuenta => cuenta.tipo_cuenta === 'DETALLE' && cuenta.codigo_cuenta.startsWith('06'))
                                                    .map(cuenta => (
                                                        <option key={cuenta.id} value={cuenta.codigo_cuenta}>
                                                            {cuenta.codigo_cuenta} - {cuenta.nombre_cuenta}
                                                        </option>
                                                    ))}
                                            </Form.Control>
                                        </td>
                                        <td>
                                            {datos.detalles.length > 1 && (
                                                <button type="button" className="icon-button" onClick={() => handleRemoveDetail(index)} disabled={disableForm}>
                                                    <i className="bi bi-trash"></i>
                                                </button>
                                            )}
                                            {index === datos.detalles.length - 1 && !disableForm && (
                                                <button className="icon-button" onClick={handleAddDetail}>
                                                    <i className="bi bi-plus-circle"></i>
                                                </button>
                                            )}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>

                        <h5 className="mt-4">Total Costo: {calcularTotalCosto()} Bs.</h5>

                        <div className="d-flex justify-content-between mt-5">
                            <Button variant="outline-dark" type="submit" disabled={disableForm}>Registrar</Button>
                            {showPrintButton && (
                                <Button variant="outline-dark" onClick={handlePrint}>Imprimir Recibo</Button>
                            )}
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>

            <Modal show={showComprobanteModal} onHide={() => setShowComprobanteModal(false)} backdrop="static" keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirmar Comprobante</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h5>¿Es este el comprobante asociado?</h5>
                    <p><strong>Número de Comprobante:</strong> {comprobanteSeleccionado?.nrocomprobante}</p>
                    <p><strong>Fecha:</strong> {comprobanteSeleccionado?.fecha}</p>
                    <p><strong>Glosa:</strong> {comprobanteSeleccionado?.glosa} Bs.</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowComprobanteModal(false)}>
                        Cancelar
                    </Button>
                    <Button variant="primary" onClick={confirmarComprobante}>
                        Confirmar
                    </Button>
                </Modal.Footer>
            </Modal>

            <CustomPrintModal3
                show={showPrintModal}
                handleClose={handleClosePrintModal}
                title1="RECIBO DE EGRESO"
                title4={`Beneficiario: ${datos.beneficiario}`}
                title3={`Fecha: ${datos.fecha}`}
                title2={`Nro. Recibo: ${nroReciboGenerado}`}
                content={printContent}
                total1={`Monto Total: ${calcularTotalCosto()} Bs.`}
            />
        </>
    );
};

export default ValoradoDetForm;
