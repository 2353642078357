import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import apiService from "../services/apiService";

// Servicio para peticiones de "registros de seguimiento de cambios de situacion de los afiliados"
const segSituAfiliadoService = apiService('/seguimiento-situ-afiliado');

// Thunks para las operaciones CRUD
export const fetchRegistrosSegSituAfiliados = createAsyncThunk('segSituAfiliado/fetchRegistrosSegSituAfiliados', async () => {
  const response = await segSituAfiliadoService.getAll();
  return response || [];
});

export const fetchRegistrosSegSituAfiliadoById = createAsyncThunk('segSituAfiliado/fetchRegistrosSegSituAfiliadoById', async (id) => {
  const response = await segSituAfiliadoService.getById(id);
  return response;
});

// Estado inicial del slice
const initialState = {
  registros: [],
  statusSend: 'idle',
  statusGet:'idle',
  error: {
    status: null,
    messages:[]
  },
};

// Definición del slice
const segSituAfiliadoSlice = createSlice({
  name: 'segSituAfiliado',
  initialState,
  reducers: {
    resetStatusSend(state) {
        state.statusSend = "idle";
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchRegistrosSegSituAfiliados.fulfilled, (state, action) => {
        state.registros = action.payload;
        state.statusGet = 'idle';
      })
      .addCase(fetchRegistrosSegSituAfiliados.pending, (state) => {
        state.statusGet = 'loading';
      })
      .addCase(fetchRegistrosSegSituAfiliados.rejected, (state, action) => {
        state.statusGet = 'failed';
        state.error = action.error.message;
      })
      .addCase(fetchRegistrosSegSituAfiliadoById.fulfilled, (state, action) => {
        state.current = action.payload;
        state.statusGet = 'idle';
      })
  }
});

export const { resetStatusSend } = segSituAfiliadoSlice.actions;

export default segSituAfiliadoSlice.reducer;
