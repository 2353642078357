import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import apiService from "../services/apiService";

// Crear una instancia del servicio para la entidad "lugares"
const regionService = apiService('/regiones');

// Thunks para las operaciones CRUD
export const fetchRegiones = createAsyncThunk('region/fetchRegiones', async () => {
  const response = await regionService.getAll();
  return response || [];
});
export const fetchRegionesByPais = createAsyncThunk('region/fetchRegionesByPais', async () => {
  const response = await regionService.getAllBySomeId('pais', "default");
  return response || [];
});

// Estado inicial del slice
const initialState = {
  regiones: [],
  current: null,
  status: 'idle',
  error: null,
};

// Definición del slice
const regionSlice = createSlice({
  name: 'region',
  initialState,
  reducers: {
    setCurrentRegion(state, action) {
      state.current = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchRegiones.fulfilled, (state, action) => {
        state.regiones = action.payload;
      })
      .addCase(fetchRegiones.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchRegiones.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(fetchRegionesByPais.fulfilled, (state, action) => {
        state.regiones = action.payload;
      })
      .addCase(fetchRegionesByPais.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchRegionesByPais.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
  }
});

export const { setCurrentRegion } = regionSlice.actions;

export default regionSlice.reducer;
